import * as React from 'react';
import * as ReactDOM from 'react-dom';
import withStyles from 'react-jss';
import { AppMetadata, IncomingActions, IframeApps } from '../constants';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styles from './styles';
import { flushMessageQueue, setAppLoaded } from '../iframeMessageUtils';
import { setIframeLoaded } from '../../../../actions/persistentIframeActions';
import { useHistory } from 'react-router-dom';
import { isNativeApp } from 'src/utils/utils';
import { logoutUserAndClearCache } from 'src/api/utils';

function PersistentIframeInjector(props: any) {
  const { classes, iframeInfo = {}, appName, setIframeLoaded, topRenderDistance } = props;
  const { dimensions = {}, isRendered } = iframeInfo;
  const metadata = AppMetadata[appName];
  const history = useHistory();

  const onIncommingMessage = (event) => {
    if (event.origin !== metadata.origin) {
      return;
    }
    let action: any = {};
    try {
      action = JSON.parse(event.data) || {};
    } catch (e) {
      return;
    }
    if (action.type === IncomingActions.Loaded && action.applicationId === appName) {
      console.log('application loaded');
      setIframeLoaded(appName, true);
      setAppLoaded(appName);
      flushMessageQueue(appName);
    } else if (action.type === IncomingActions.ROUTING && action.applicationId === appName) {
      replaceIncomingRouteChange(action);
    } else if (action.type === IncomingActions.LOGOUT) {
      logoutUserAndClearCache();
    }
  };

  const replaceIncomingRouteChange = (incomingAction) => {
    const incomingRoute = incomingAction.pathname;
    const currentRoute = location.pathname.replace(metadata.basePath, '');
    if (incomingRoute !== currentRoute) {
      const newPath = `${metadata.basePath}${incomingRoute}`;
      incomingAction.pushToHistory ? history.push(newPath) : history.replace(newPath);
    }
  };

  React.useEffect(() => {
    window.addEventListener('message', onIncommingMessage);
  }, []);

  const calculateHeight = () => {
    if (isNativeApp() && appName === IframeApps.LIA_CO_PILOT) {
      return 'calc(100vh - 56px)';
    }
    return '100%';
  };

  return ReactDOM.createPortal(
        <div className={ classes.appModal } style={{
          display: iframeInfo.visibility ? 'block' : 'none',
          top: topRenderDistance || dimensions.top,
          left: dimensions.left,
          width: `${dimensions.width || 0}px`,
          height: `${dimensions.height || 0}px`,
        }}>
          {isRendered && <iframe
            id={metadata.iframeId}
            className={ classes.customModalIframe }
            src={metadata.url}
            style={{height: calculateHeight()}}
            width="100%"
            title={metadata.iframeId}
            allow="fullscreen"
          />}
        </div>,
        document.body,
    );
}

const mapStateToProps = (state, ownProps) => {
  const { persistentIframe } = state;
  const { appName } = ownProps;
  return {
    iframeInfo: persistentIframe[appName],
  };

};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    setIframeLoaded,
  }, dispatch);
};

export default connect(mapStateToProps,
  mapDispatchToProps)(withStyles(styles, { injectTheme: true })(PersistentIframeInjector));
