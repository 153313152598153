import React, { useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { message } from 'antd';
import GalleryModal from '@components/common/galleryModal';
import { getSignedUrlApi } from '@api/getSignedUrl';

const GalleryModalWithSignedUrl = (props: any) => {
  const { 
    imageArray = [], 
    videoArray = [], 
    toFetchSignedUrl = false, 
    placeholderText = 'View' 
  } = props;

  const [isImageLoading, setIsImageLoading] = useState(false);
  const [isGalleryModalVisible, setIsGalleryModalVisible] = useState(false);
  const [galleryModalData, setGalleryModalData] = useState({ images: [], videos: [] });

  const fetchSignedUrls = async (urls) => {
    return await Promise.all(
      urls.map(async (url) => {
        const response = await getSignedUrlApi(url);
        if (!response.isSuccess || !response?.data?.signedUrl) {
          throw new Error('Failed to fetch signed URLs');
        }
        return response.data.signedUrl;
      })
    );
  };

  const fetchSignedUrlsAndOpenModal = async () => {
    try {
      setIsImageLoading(true);

      const images = toFetchSignedUrl ? await fetchSignedUrls(imageArray) : imageArray;
      const videos = toFetchSignedUrl ? await fetchSignedUrls(videoArray) : videoArray;

      setGalleryModalData({ images, videos });
      setIsGalleryModalVisible(true);
    } catch (error) {
      message.error(error.message || 'Failed to open gallery');
    } finally {
      setIsImageLoading(false);
    }
  };

  if (!imageArray.length && !videoArray.length) {
    return <span>Not available</span>;
  }

  return (
    <>
      <span>
        <a onClick={fetchSignedUrlsAndOpenModal}>{placeholderText}</a>
        {isImageLoading && <LoadingOutlined style={{ marginLeft: 8 }} />}
      </span>
      <GalleryModal
        isVisible={isGalleryModalVisible}
        imageVideoGalleryData={galleryModalData}
        handleModalClose={() => setIsGalleryModalVisible(false)}
      />
    </>
  );
};

export default GalleryModalWithSignedUrl;
