import * as moment from 'moment';
import {
  RIDER_ROSTER_UPDATE_DATA,
  RIDER_ROSTER_UPDATE_FILTER,
  RIDER_ROSTER_RESET_PAGINATION,
  RIDER_ROSTER_ENABLE_LOADING,
  RIDER_ROSTER_DISABLE_LOADING,
  RIDER_ROSTER_PAGINATION,
  RIDER_ROSTER_UPDATE_SORT,
  RIDER_ROSTER_UPDATE_VIEW,
  RIDER_ROSTER_UPDATE_DATE,
} from '../actions/constants';

const defaultState = {
  isLoading: false,
  rosterManagementData: [],
  currentSelectedDate: moment().format('YYYY-MM-DD'),
  appliedFilters: {
    riders: [],
    shiftColors: [],
    shiftNames: [],
    shiftCodes: [],
    upstreamShiftCodes: [],
  },
  appliedSort: {
    sortBy: 'ASC',
  },
  pagination: {
    currentPageNumber: 1,
    nextOrPrev: 'first',
    limitPerPage: 10,
  },
  selectedView: 'week',
};

export default function (state: any = defaultState, action) {

  switch (action.type) {

    case RIDER_ROSTER_UPDATE_DATA: {
      return {
        ...state,
        rosterManagementData: action.data,
        pagination: {
          ...state.pagination,
          isNextPresent: action?.data?.response?.isNextPresent,
        },
      };
    }
    case RIDER_ROSTER_UPDATE_FILTER: {
      return {
        ...state,
        appliedFilters: {
          ...action.data,
        },
      };
    }
    case RIDER_ROSTER_RESET_PAGINATION: {
      return {
        ...state,
        pagination: defaultState.pagination,
      };
    }
    case RIDER_ROSTER_ENABLE_LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case RIDER_ROSTER_DISABLE_LOADING: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case RIDER_ROSTER_PAGINATION: {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.data,
        },
      };
    }
    case RIDER_ROSTER_UPDATE_SORT: {
        return {
          ...state,
          appliedSort: {
            ...state.appliedSort,
            ...action.data,
          },
        };
    }
    case RIDER_ROSTER_UPDATE_VIEW: {
        return {
          ...state,
          selectedView: action.data,
        };
    }
    case RIDER_ROSTER_UPDATE_DATE: {
        return {
          ...state,
          currentSelectedDate: action.data,
        };
    }
    default: return state;

  }
}
