export const markerColorPalette = {
  success : 'green',
  delivered : 'green',
  attempted: 'red',
};
export const getMarkerColor = (taskStatus) => {
  const color = markerColorPalette[taskStatus];
  return color ? color : 'yellow';
};
export const tripColorPalette = [
  '#13C2C2',
  '#52C41A',
  '#A0D911',
  '#00474F',
  '#135200',
  '#3F6600',
  '#FAAD14',
  '#2F54EB',
  '#1890FF',
  '#874D00',
  '#061178',
  '#003A8C',
  '#FA8C16',
  '#FA541C',
  '#F5222D',
  '#873800',
  '#871400',
  '#820014',
  '#EB2F96',
  '#5F0BD5',
  '#876800',
  '#780650',
  '#2C008F',
  '#3fec3f'
    , '#000080'
    , '#808000'
    , '#800080'
    , '#008080'
    , '#C0C0C0'
    , '#808080'
    , '#9999FF'
    , '#993366'
    , '#faad14'
    , '#39afaf'
    , '#660066'
    , '#FF8080'
    , '#0066CC'
    , '#5757b5'
    , '#000080'
    , '#FF00FF'
    , '#FFFF00'
    , '#00FFFF'
    , '#800080'
    , '#800000'
    , '#008080'
    , '#0000FF'
    , '#00CCFF'
    , '#CCFFFF'
    , '#CCFFCC'
    , '#FFFF99'
    , '#99CCFF'
    , '#FF99CC'
    , '#e0770e'
    , '#FFCC99'
    , '#3366FF'
    , '#33CCCC'
    , '#99CC00'
    , '#FFCC00'
    , '#FF9900'
    , '#FF6600'
    , '#666699'
    , '#969696'
    , '#003366'
    , '#339966'
    , '#003300'
    , '#333300'
    , '#993300'
    , '#993366'
    , '#333399'
    , '#333333',
];

export const generateDistinctColors = (n) => {
  const colors = [];
  const hueStep = 360 / n; // Divide the hue range into n steps
  const s = 80 + Math.floor(Math.random() * 20); // Saturation: Between 80% and 100%
  const l = 30 + Math.floor(Math.random() * 30); // Lightness: Between 30% and 60% for darker colors

  for (let i = 0; i < n; i++) {
    const hue = i * hueStep; // Each color gets a different hue
    colors.push(hslToRgb(hue, s, l));
  }

  return rearrangeArray(colors);
};

function rearrangeArray(arr) {
  const n = arr.length;
  if (n <= 5) {
      // If the array has 5 or fewer elements, we can't split it into 5 meaningful parts.
      return arr;
  }

  // Step 1: Divide the array into 5 parts
  const parts = [];
  const partSize = Math.floor(n / 5); // Base size of each part

  for (let i = 0; i < 5; i++) {
      if (i === 4) {
          // The last part takes all remaining elements
          parts.push(arr.slice(i * partSize));
      } else {
          parts.push(arr.slice(i * partSize, (i + 1) * partSize));
      }
  }

  // Step 2: Interleave the parts
  let result = [];
  let indices = Array(5).fill(0); // Keep track of the indices of each part

  // Interleave the parts by pulling one element from each part in round-robin order
  while (indices.some((v,i) => v < parts[i]?.length)) {
      for (let i = 0; i < 5; i++) {
          if (indices[i] < parts[i].length) {
              result.push(parts[i][indices[i]]);
              indices[i]++;
          }
      }
  }

  return result;
}

const hslToRgb = (h, s, l) => {
  s /= 100;
  l /= 100;
  const c = (1 - Math.abs(2 * l - 1)) * s;
  const x = c * (1 - Math.abs(((h / 60) % 2) - 1));
  const m = l - c / 2;

  let r, g, b;
  if (h >= 0 && h < 60) {
    r = c; g = x; b = 0;
  } else if (h >= 60 && h < 120) {
    r = x; g = c; b = 0;
  } else if (h >= 120 && h < 180) {
    r = 0; g = c; b = x;
  } else if (h >= 180 && h < 240) {
    r = 0; g = x; b = c;
  } else if (h >= 240 && h < 300) {
    r = x; g = 0; b = c;
  } else {
    r = c; g = 0; b = x;
  }

  r = Math.round((r + m) * 255);
  g = Math.round((g + m) * 255);
  b = Math.round((b + m) * 255);

  return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
};
