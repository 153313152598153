import * as React from 'react';
import withStyles from 'react-jss';
import { message, Button, Spin } from 'antd';
import * as  moment from 'moment';
import { ThemeType } from '../../../theming/jssTypes';
import FixedHeaderTable from '../FixedHeaderTable/FixedHeaderTable';
import { fetchConsignmentClaimRequests } from '../../../api/dashboard';
import { claimRequestsColumns } from './masterdata';
import ClaimSettleFormModal from './ClaimSettleFormModal';
import GalleryModal from '@components/common/galleryModal';
import { formatBooleanData } from '../../../utils/utils';

interface GalleryModalData {
    images: string[];
    videos: string[];
    pdfs: string[];
}

const styles = (theme: ThemeType): any => ({
    tabsCardsContainer: {
        backgroundColor: theme.colors.pageBg,
        borderRadius: '4px',
        padding: '0',
        overflow: 'scroll',
        height: '100%',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
});

const ClaimRequestsTable = (props: any) => {

    const {
        consignmentReferenceNumber,
        showSettleAction = false,
    } = props;
    const [loading, setLoading] = React.useState(false);
    const [claimRequests, setClaimRequests] = React.useState([]);
    const [isGalleryModalVisible, setIsGalleryModalVisible] = React.useState(false);
    const [isSettleModalVisible, setIsSettleModalVisible] = React.useState(false);
    const [settleRecord, setSettleRecord] = React.useState({});
    const [galleryModalData, setGalleryModalData] =
        React.useState<GalleryModalData>({ images: [], videos: [], pdfs: [] });

    React.useEffect(() => {
        fetchClaimRequests();
    }, []);

    const fetchClaimRequests = async () => {
        if (!consignmentReferenceNumber) {
            return;
        }
        setLoading(true);
        const response = await fetchConsignmentClaimRequests({
            consignment_reference_number: consignmentReferenceNumber,
        });
        if (response.isSuccess) {
            setClaimRequests(response.data);
        } else {
            message.error(response.errorMessage);
        }
        setLoading(false);
    };
    const renderGalleryModal = (imageArray: any[], videoArray: any[], pdfArray: any[]) => {
        if (!imageArray.length && !videoArray.length && !pdfArray.length){
            return 'Not Available';
        }
        return (
            <a onClick={() => {
                setGalleryModalData({
                    images: imageArray,
                    videos: videoArray,
                    pdfs: pdfArray,
                });
                setIsGalleryModalVisible(true);
            }}
            >
                View
            </a>
        );
    };
    const getColumnsForClaimRequests = () => {
        const columns = claimRequestsColumns?.map((col) => {
            return (
                col && {
                    dataIndex: col.id,
                    title: col.pretty_name,
                    width: 80,
                    render: (x, record, index) => {
                        switch (col.id) {
                            case 'claim_request_submitted_at':
                            case 'claim_request_last_approved_at':
                                return record[col.id] ? moment(record[col.id]).format('DD/MM/YYYY, HH:mm') : null;
                            case 'claim_request_settlement_supporting_docs_urls':
                                if (!record[col.id]) {
                                    return 'Not Available';
                                }
                                const parsedData = JSON.parse(record[col.id]);
                                return renderGalleryModal(parsedData, [], []);
                            case 'claim_request_is_settled':
                                return record[col.id] ? 'Yes' : 'No';
                            case 'claim_request_is_3pl_consignment':
                                return formatBooleanData(record[col.id]) ? 'Yes' : 'No';
                            default:
                                return x;
                        }
                    },
                }
            );
        });
        if (showSettleAction) {
            columns.push({
                title: 'Settle',
                width: 80,
                dataIndex: 'settle',
                render: (x, record) => {
                    return (
                        <Button
                            type="primary"
                            disabled={record['claim_request_status'] !== 'approved'}
                            onClick={() => {
                                setSettleRecord(record);
                                setIsSettleModalVisible(true);
                            }}
                        >
                            Settle
                        </Button>
                    );
                },
            });
        }
        return columns.filter((column) => column);
    };

    return (
        <>
            {loading && <Spin />}
            {!loading && (<FixedHeaderTable
                locale={{ emptyText: 'No data found' }}
                rowKey={(record: any) => record['id']}
                dataSource={claimRequests}
                pagination={false}
                columns={getColumnsForClaimRequests()}
            />)}
            {isGalleryModalVisible && (
                <GalleryModal
                    isVisible={isGalleryModalVisible}
                    imageVideoGalleryData={galleryModalData}
                    handleModalClose={() => { setIsGalleryModalVisible(false); }}
                />
            )}
            {isSettleModalVisible && (
                <ClaimSettleFormModal
                    isVisible={isSettleModalVisible}
                    title={'Settle Claim'}
                    handleModalClose={() => {
                        setIsSettleModalVisible(false);
                        setSettleRecord({});
                    }}
                    claimRequestData={settleRecord}
                />
            )}
        </>
    );
};

export default withStyles(styles, { injectTheme: true })(ClaimRequestsTable);
