import { applicationType } from '../globals';
import { getViewControl } from '../utils/genericConfig/utils';
import { getSortByDateFilterMapping } from '../components/common/utils';
import { getKeyArrayFromObject } from '../utils/utils';
import store from '../store/appStore';
import * as moment from 'moment';
import { getAuthenticationHeaders } from '../api/utils';
const pagination = {
  resultPerPage: 'pagination.perPageCount',
  lastSortByKey:'pagination.lastSortByKey',
  nextOrPrev: 'pagination.nextOrPrev',
  pageNumber: 'pagination.currentPageNumber',
  lastSortedColumnValue: {
    path: 'pagination',
    fn : (value) => {
      return getLastSortedColumnValue(value);
    },
  },
  lastRowId: {
    path: 'pagination',
    fn : (value) => {
      return getLastRowId(value);
    },
  },
  paginationRecordList: 'pagination.paginationRecordList',
};
const customPlanningPagination = {
  resultPerPage: 'pagination.perPageCount',
  pageNumber: 'pagination.currentPageNumber',
};

const sort = {
  sortBy:  {
    path: 'sort.sortBy',
    fn : (value) => {
      return getSortByDateFilterMapping[value] ? getSortByDateFilterMapping[value] : value;
    },
  },
  descendingOrder: 'sort.descendingOrder',
};

const globalFilters = {
  timeFilters: [{
    fromDateString: 'globalAppliedFilters.startDate',
    toDateString: 'globalAppliedFilters.endDate',
    columnName: 'globalAppliedFilters.dateFilterType',
  }],
  maxDateRangeAllowed: 'globalAppliedFilters.maxDateRangeAllowed',
  hubIdList : (iteratee) => {
    return getHubIdList(iteratee);
  },
};
const bucketFilters = {
  statusList: {
    path: 'appliedFilters.status',
    fn : (value) => {
      return getKeyArrayFromObject(value);
    },
  },
  courierPartnerList:
  {
    path: 'appliedFilters.carrier',
    fn : (value) => {
      return getKeyArrayFromObject(value);
    },
  },
  subBucket: 'appliedFilters.subBucket',
  referenceNumberListInclusive : 'appliedFilters.reference_number_search',
  bagTypeList: 'appliedFilters.bag_type',
  bagReferenceNumberList : 'appliedFilters.bag_reference_number',
  salesOrderNumberList: 'appliedFilters.so_number_search',
  destinationPincodeCityList: 'appliedFilters.destination_pincode_city',
  tagList: 'appliedFilters.tag',
  rescheduledReasons: 'appliedFilters.rescheduledReasons',
  isFalseAttempt: 'appliedFilters.falseAttempt',
  isFalseDelivery: 'appliedFilters.isFalseDelivery',
  consignmentRating:'appliedFilters.rating',
  isCod:'appliedFilters.isCod',
  undeliveredFeedbackPresent:'appliedFilters.undeliveredFeedbackPresent',
  hasRemarks: 'appliedFilters.hasRemarks',
  nextSteps: 'appliedFilters.nextSteps',
  timeSinceLastScanList: 'appliedFilters.timeSinceLastScan',
  proximityList : 'appliedFilters.proximityList',
  orderTypeList : 'appliedFilters.orderTypeList',
  deliveryTypeList: 'appliedFilters.deliveryTypeList',
  purchasingGroup : 'appliedFilters.purchasingGroup',
  originPincodeList: 'appliedFilters.originPincodeList',
  typeOfDelivery : 'appliedFilters.typeOfDelivery',
  customerIdList : {
    path : 'appliedFilters.customerCodes',
    fn : (value) => {
      return value ? value.map(x => x.key) : [];
    },
  },
  bookingServiceTypeIdList: {
    path : 'appliedFilters.serviceType',
    fn : (value) => {
      return value ? value.map(x => x.key) : [];
    },
  },
  attemptCountList: {
    path : 'appliedFilters.attempts',
    fn : (value) => {
      return value ? value : [];
    },
  },
  consignmentType : {
    path : 'appliedFilters.consignmentType',
    fn : (value) => {
      return value ? (value === 'all' ? undefined : value) : undefined;
    },
  },
  consignmentEmailCountList : {
    path : 'appliedFilters.consignmentEmailCountList',
    fn : (value) => {
      return value ? value : [];
    },
  },
  shipperNextSteps : {
    path : 'appliedFilters.shipperNextSteps',
    fn : (value) => {
      return value ? value : [];
    },
  },
  internalNDRList : {
    path : 'appliedFilters.internalNDRList',
    fn : (value) => {
      return value ? value : [];
    },
  },
  referenceNumberList : (iteratee) => {
    return getSearchObject(iteratee, 'referenceNumber');
  },
  senderPhoneNumberList : (iteratee) => {
    return getSearchObject(iteratee, 'senderPhone');
  },
  destinationPhoneNumberList : (iteratee) => {
    return getSearchObject(iteratee, 'destinationPhone');
  },
  orderNumberList : (iteratee) => {
    return getSearchObject(iteratee, 'orderNumber');
  },
  courierPartnerReferenceNumberList : (iteratee) => {
    return getSearchObject(iteratee, 'awbNumber');
  },
  customerReferenceNumberList : (iteratee) => {
    return getSearchObject(iteratee, 'customerReferenceNumber');
  },
  customerCivilIdList: (iteratee) => {
    return getSearchObject(iteratee, 'customerCivilId');
  },
  manifestNumberList: (iteratee) => {
    return getSearchObject(iteratee, 'manifestNumber');
  },
  isRto : {
    path : 'appliedFilters.movementType',
    fn : (value) => {
      return getRTOvalue(value);
    },
  },
  dayPartFilter : 'appliedFilters.dayPartFilter',
  timeSlot:'appliedFilters.timeSlot',
  nonRegularOrders: 'appliedFilters.nonRegularOrders',
  storeType: 'appliedFilters.storeType',
  employeeType: 'appliedFilters.employeeType',
  finalConsignmentStatus : 'appliedFilters.finalConsignmentStatus',
  scheduledDate : {
    path : 'appliedFilters.scheduledDate',
    fn : (value) => {
      return getScheduledDate(value);
    },
  },
  storeHubIdList : {
    path : 'appliedFilters.storeHubId',
    fn : (value) => {
      return value ? value.map(x => x.key) : [];
    },
  },
  isHandedOver3pl : 'appliedFilters.isHandedOver3pl',
  creationBeforeElevenAM: 'appliedFilters.creationBeforeElevenAM',
  creationAfterElevenAM: 'appliedFilters.creationAfterElevenAM',
  exceptionIdList: {
    path : 'appliedFilters.exceptions',
    fn : (value) => {
      return value ? value.map(x => x.key) : [];
    },
  },
  assignedRider: {
    path: 'appliedFilters.assignedRider',
    fn: (value) => {
      return value ? value.map((x) => x.key) : [];
    },
  },
  executionStatusList: 'appliedFilters.execution_status',
  isCNVDone: {
    path : 'appliedFilters.isCNVDone',
    fn : (value) => {
      return getCNVStatus(value);
    },
  },
  movementTypeList: 'appliedFilters.movementTypeList',
  legTypeList: 'appliedFilters.legTypeList',
  isPaymentDone: {
    path : 'appliedFilters.isPaymentDone',
    fn : (value) => {
      return getPaymentStatus(value);
    },
  },
  invoiceValue: {
    path : 'appliedFilters.invoice',
    fn : (value) => {
      return getInvoiceAndNoOfPieces(value);
    },
  },
  numPieces: {
    path : 'appliedFilters.no_of_pieces',
    fn : (value) => {
      return getInvoiceAndNoOfPieces(value);
    },
  },
  isAddressUpdated : 'appliedFilters.isAddressUpdated',
  destinationPincodeList: 'appliedFilters.destinationPincodeList',
  consigneeCodeList: 'appliedFilters.consigneeCodeList',
  consignorCodeList: 'appliedFilters.consignorCodeList',
  nextHubIdList: {
    path : 'appliedFilters.nextHubIdList',
    fn : (value) => {
      return value?.map(x => x.key) || [];
    },
  },
  destinationHubIdList:  {
    path : 'appliedFilters.destinationHubIdList',
    fn : (value) => {
      return value?.map(x => x.key) || [];
    },
  },
  pickupAttemptCountList: {
    path : 'appliedFilters.pickupAttempts',
    fn : (value) => {
      return value ? value : [];
    },
  },
  isPgiDone: {
    path : 'appliedFilters.isPgiDone',
    fn : (value) => {
      switch (value) {
        case 'true':
          return true;
        case 'false':
          return false;
        default:
          return undefined;
      }
    },
  },
  customerReferenceNumberListInclusive: 'appliedFilters.customer_reference_number_search',
  constraintTagsList:  {
    path : 'appliedFilters.deliveryType',
    fn : (value) => {
      return value ? value.map(x => x.key) : [];
    },
  },
  lastCallLogsStatus: 'appliedFilters.lastCallLogsStatus',
  assignedToRider: 'appliedFilters.assignedToRider',
  zoneAssigned: {
    path : 'appliedFilters.zoneAssigned',
    fn : (option) => option?.value,
  },
  destinationZoneList: {
    path: 'appliedFilters.destinationZoneList',
    fn: (value) => value ? value : [],
  },
};
const getInvoiceAndNoOfPieces = (value) => {
  switch (value) {
    case 'all':
      return null;
    default:
      return value;
  }
};
const getPaymentStatus = (value) => {
  switch (value) {
    case 'no':
      return false;
    case 'yes':
      return true;
    default:
      return undefined;
  }
};
const getCNVStatus = (value) => {
  switch (value) {
    case 'verified':
      return true;
    case 'not_verified':
      return false;
    default:
      return undefined;
  }

};
const getScheduledDate = (value) => {
  let startDate = moment().format('YYYY-MM-DD');
  let endDate = moment().format('YYYY-MM-DD');
  if (value && value.length > 1) {
    startDate = value[0].format('YYYY-MM-DD');
    endDate = value[1].format('YYYY-MM-DD');
  }
  const obj = {
    fromDateString : startDate,
    toDateString : endDate,
  };
  return value && value.length > 1 ? obj : {};
};
// Review this properly
const getSearchObject = (inp, param) => {
  if (inp.selectedRowKeys) {
    if (param === 'referenceNumber') {
      return inp.selectedRowKeys;
    }
    return undefined;
  }
  if (param === inp.globalAppliedFilters.searchType) {
    const searchValue = inp.globalAppliedFilters.searchValue;
    const searchVal = searchValue.length > 0 ? [searchValue] :[];
    return searchVal;
  }
  return [];
};

export const getRTOvalue = (value) => {
  if (value) {
    if (value.includes('Forward') && value.includes('RTO')) {
      return undefined;
    } if (value.includes('Forward')) {
      return false;
    } if (value.includes('RTO')) {
      return true;
    }
  }
  return;
};
const getLastSortedColumnValue = (pagination) => {
  switch (pagination.nextOrPrev) {
    case 'prev':
      return pagination.lastSortedColumnValuePrev;
    case 'next':
      return pagination.lastSortedColumnValueNext;
    default:
      return null;
  }
};

const getLastRowId = (pagination) => {
  switch (pagination.nextOrPrev) {
    case 'prev':
      return pagination.lastItemIdPrev;
    case 'next':
      return pagination.lastItemIdNext;
    default:
      return null;
  }
};

const getHubIdList = (iteratee) => {
  return iteratee.viewType === 'domestic' ?
         [iteratee.currHub.id] : iteratee.viewType === 'crm'  ?
        iteratee.globalAppliedFilters.hubId || [] : [iteratee.currHub.id];
};

const getTime = (value) => {
  let startTime = moment().format('HH:mm:ss');
  let endTime = moment().format('HH:mm:ss');
  if (value && value.length > 1) {
    startTime = value[0].format('HH:mm:ss');
    endTime = value[1].format('HH:mm:ss');
  }
  const obj = {
    fromTimeString : startTime,
    toTimeString : endTime,
  };
  return value && value.length > 1 ? obj : {};
};

const getFilters = (appliedFilters) => {
  const filterObject = {};
  const masterData = store?.getState()?.masterData;
  const filtersMetadata = Array.isArray(masterData?.ops_filter_metadata) ?
    masterData.ops_filter_metadata : [];
  const filterKeys = Object.keys(appliedFilters);

  filterKeys.forEach((ele) => {
    const filter = filtersMetadata.find(item => item.id === ele);
    const value = appliedFilters[ele];
    if(filter){
      if(filter.type === 'search'){
        filterObject[ele] = getKeyArrayFromObject(value);
      }
      else if(filter.type === 'date'){
        filterObject[ele] = filter.mode === 'range' ? getScheduledDate(value) :
          moment().format('YYYY-MM-DD');
      }
      else if(filter.type === 'time'){
        filterObject[ele] = filter.mode === 'range' ? getTime(value) : moment().format('HH:mm:ss');
      }
      else if(ele === 'consignmentType'){
        filterObject[ele] = value ? (value === 'all' ? undefined : value) : undefined;
      }
      else if(ele === 'isRto'){
        filterObject[ele] = getRTOvalue(value);
      }
      else{
        filterObject[ele] = value;
      }
    }
    else{
      filterObject[ele] = value;
    }
  });
  return filterObject;
};

const bucketFiltersRevamp = {
  referenceNumberList : (iteratee) => {
    return getSearchObject(iteratee, 'referenceNumber');
  },
  senderPhoneNumberList : (iteratee) => {
    return getSearchObject(iteratee, 'senderPhone');
  },
  destinationPhoneNumberList : (iteratee) => {
    return getSearchObject(iteratee, 'destinationPhone');
  },
  orderNumberList : (iteratee) => {
    return getSearchObject(iteratee, 'orderNumber');
  },
  courierPartnerReferenceNumberList : (iteratee) => {
    return getSearchObject(iteratee, 'awbNumber');
  },
  customerReferenceNumberList : (iteratee) => {
    return getSearchObject(iteratee, 'customerReferenceNumber');
  },
};

const requestModelCN = {
  bucket : {
    path : 'bucket',
    fn : value => getBucketApiValue(value),
  },
  //subBucket : 'subBucket',
  ...pagination,
  ...sort,
  ...globalFilters,
  ...bucketFilters,
};

const requestModelPlanningSummaryMetrics = {
  timeFilters:[
  {
    fromDateString: 'globalAppliedFilters.startDate' ,
    toDateString: 'globalAppliedFilters.endDate' ,
    columnName: 'globalAppliedFilters.dateFilterType' ,
  },
  ],
  descendingOrder : true,
  hubIdList: (iteratee) => {
    return getHubIdList(iteratee);
  },
  selfCountType: 'globalAppliedFilters.hubType',
  bucketIds : (iteratee) => {
    return getViewControl(iteratee.viewType).buckets;
  },
  maxDateRangeAllowed: 'globalAppliedFilters.maxDateRangeAllowed',
};

const requestModelManifestDeclarationCN = {
  bucket : {
    path : 'bucket',
    fn : value => getBucketApiValue(value),
  },
  ...customPlanningPagination,
  ...globalFilters,
  ...bucketFilters,
};

const getBucketApiValue = (bucket) => {
  return bucket;
};

const responseModelCN = {
  isNextPresent: 'is_next_present',
  lastItemIdNext: 'last_row_id_next',
  lastItemIdPrev: 'last_row_id_prev',
  lastSortByKey: 'last_sort_by_key',
  lastSortedColumnValueNext: 'last_sorted_column_value_next',
  lastSortedColumnValuePrev: 'last_sorted_column_value_prev',
  page_data: 'page_data',
  paginationRecordList: 'paginationRecordList',
};

const getBucketForSupplyRequest = (bucket) => {
  switch(bucket) {
    case 'supply_request_delivered':
      return 'DELIVERED';
    case 'supply_request_partially_delivered':
      return 'PARTIAL_DELIVERED';
    case 'supply_request_undelivered':
      return 'UNDELIVERED';
    default:
      return 'ALL';
  }
};

const getStatusForSupplyRequest = (statusList) => {
  const updatedStatusList = statusList.map((status) => {
    if(status === 'created'){
      return 'CREATED';
    }
    if(status === 'delivered'){
      return 'DELIVERED';
    }
    if(status === 'partially_delivered'){
      return 'PARTIAL_DELIVERED';
    }
    if(status === 'undelivered'){
      return 'UNDELIVERED';
    }
  });
  return updatedStatusList;
};

const requestModelSummaryMetrics = {
  startDate: 'globalAppliedFilters.startDate' ,
  endDate: 'globalAppliedFilters.endDate' ,
  dateFilterColumn: 'globalAppliedFilters.dateFilterType' ,
  hubIdList: (iteratee) => {
    return getHubIdList(iteratee);
  },
  selfCountType: 'globalAppliedFilters.hubType',
  bucketIds : (iteratee) => {
    return getViewControl(iteratee.viewType).buckets;
  },
  maxDateRangeAllowed: 'globalAppliedFilters.maxDateRangeAllowed',
};
const requestModelSummaryMetricsUserChoice = {
  ...requestModelSummaryMetrics,
  bucketIds : 'bucket',
  showCurrentBucketCount: 'showCurrentBucketCount',
  viewType: 'viewType',
  maxDateRangeAllowed: 'globalAppliedFilters.maxDateRangeAllowed',
};
const getTransactionType = (iteratee) => {
  return iteratee.appliedFilters.transactionTypeList?
         iteratee.appliedFilters.transactionTypeList:[];
};
const getRiderList = (iteratee) => {
  return iteratee.appliedFilters.workerIdList?
         iteratee.appliedFilters.workerIdList:[];
};
const getStatusList = (iteratee) => {
  return iteratee.appliedFilters.statusList?
         iteratee.appliedFilters.statusList:[];
};
const requestCodSettlementModelCN = {
  timeFilters:[
  {
    fromDateString: 'globalAppliedFilters.startDate' ,
    toDateString: 'globalAppliedFilters.endDate' ,
    columnName: 'globalAppliedFilters.dateFilterType' ,
  },
  ],
  hubIdList: (iteratee) => {
    return getHubIdList(iteratee);
  },
};
const requestCodHistoryModelCN = {
  ...requestCodSettlementModelCN,
  transactionTypeList : (iteratee) => {
    return getTransactionType(iteratee);
  },
  workerIdList : (iteratee) => {
    return getRiderList(iteratee);
  },
  statusList: (iteratee) => {
    return getStatusList(iteratee);
  },
  resultPerPage: 'pagination.perPageCount',
  currentPageNumber: 'pagination.currentPageNumber',
  nextOrPrev: 'pagination.nextOrPrev',
  paginationParams: 'pagination.paginationParams',
};
const requestModelPlanningSubBucketSummaryMetrics = {
  timeFilters:[
  {
    fromDateString: 'globalAppliedFilters.startDate' ,
    toDateString: 'globalAppliedFilters.endDate' ,
    columnName: 'globalAppliedFilters.dateFilterType' ,
  },
  ],
  descendingOrder : true,
  hubIdList: (iteratee) => {
    return getHubIdList(iteratee);
  },
  maxDateRangeAllowed: 'globalAppliedFilters.maxDateRangeAllowed',
  selfCountType: 'globalAppliedFilters.hubType',
  bucketId : 'activeBucket',
};
const requestModelSubBucket = {
  ...globalFilters,
  ...bucketFilters,
  selfCountType: 'globalAppliedFilters.hubType',
  bucketId : 'activeBucket',
  noCount: 'noCount',
};

const requestModelDownloads = {
  ...globalFilters,
  ...bucketFilters,
  sortBy:'globalAppliedFilters.dateFilterType',
  employeeCode: 'employeeCode',
  bucket : 'bucket',
  subBucket : 'subBucket',
  requiredColumns : 'requiredColumns',
  childDownloadData: 'childDownloadData',
};

const requestModelDownloadsAdditionalFilters = {
  customerIdList : {
    path : 'appliedFilters.customerIdList',
    fn : (value) => {
      return value ? value.map(x => x.key) : [];
    },
  },
};

const requestModelDownloadsLogisticsExpress = {
  ...requestModelDownloads,
  ...requestModelDownloadsAdditionalFilters,
};

const requestModelSupportPortal = {
  bucket : {
    path : 'bucket',
    fn : value => getBucketApiValue(value),
  },
  timeFilters: [],
  subBucket : 'subBucket',
  ...sort,
  ...bucketFilters,
};

const requestModelRetailStoreCN = {
  ...requestModelCN,
  storeHubIdList: (iteratee) => {
    return getHubIdList(iteratee);
  },
  hubIdList: undefined,
};

const requestModelSummaryMetricsRetailStore = {
  ...requestModelSummaryMetrics,
  storeHubIdList: (iteratee) => {
    return getHubIdList(iteratee);
  },
  hubIdList: undefined,
};

const requestModelRetailStoreDownloads = {
  ...requestModelDownloads,
  storeHubIdList: (iteratee) => {
    return getHubIdList(iteratee);
  },
  hubIdList: undefined,
};

const responseModelShelf = {
  isNextPresent: 'is_next_present',
  lastItemIdNext: 'last_shelf_id_next',
  lastItemIdPrev: 'last_shelf_id_prev',
  lastSortedColumnValueNext: 'last_sorted_column_value_next',
  lastSortedColumnValuePrev: 'last_sorted_column_value_prev',
  page_data: 'page_data',
};
const requestModelShelf = {
  next_or_prev: 'pagination.nextOrPrev',
  result_per_page: 'pagination.perPageCount',
  last_shelf_id:  {
    path: 'pagination',
    fn : (value) => {
      return getLastRowId(value);
    },
  },
  last_sorted_column_value: {
    path: 'pagination',
    fn : (value) => {
      return getLastSortedColumnValue(value);
    },
  },
  sortBy:'globalAppliedFilters.dateFilterType',
  descendingOrder: 'sort.descendingOrder',
  hub_id: (iteratee) => {
    return [iteratee.currHub.id];
  },
  shelf_number: 'globalAppliedFilters.shelfNumber',
  from_date: 'globalAppliedFilters.startDate',
  to_date:'globalAppliedFilters.endDate',
  time_filter: 'globalAppliedFilters.dateFilterType',
};

const responseModelCrate = {
  isNextPresent: 'is_next_present',
  page_data: 'page_data',
};
const requestModelCrate = {
  next_or_prev: 'pagination.nextOrPrev',
  page_size: 'pagination.perPageCount',
  page_number: 'pagination.currentPageNumber',
  sortBy:'globalAppliedFilters.dateFilterType',
  descendingOrder: 'sort.descendingOrder',
  hub_ids: (iteratee) => {
    return [iteratee.currHub.id];
  },
  crate_number: 'appliedFilters.crateNumber',
  from_date: 'globalAppliedFilters.startDate',
  to_date:'globalAppliedFilters.endDate',
};

const responseModelSupplyRequest = {
  isNextPresent: 'is_next_present',
  page_data: 'page_data',
};

const requestModelSupplyRequest = {
  pageNumber: 'pagination.currentPageNumber',
  resultPerPage: 'pagination.perPageCount',
  descendingOrder: 'sort.descendingOrder',
  customerCodeList: {
    path: 'appliedFilters.customerCode',
    fn : (value) => {
      return value ? [value] : [];
    },
  },
  statusList: {
    path: 'appliedFilters.status',
    fn : (value) => {
      return value ? getStatusForSupplyRequest(value) : [];
    },
  },
  timeFilters: [{
    fromDateString: 'globalAppliedFilters.startDate',
    toDateString: 'globalAppliedFilters.endDate',
    columnName: 'globalAppliedFilters.dateFilterType',
  }],
  bucket: {
    path: 'bucket',
    fn: (value) => {
      return getBucketForSupplyRequest(value);
    },
  },
};

const requestModelAddressRegister = {
  pageNumber: 'pagination.currentPageNumber',
  resultPerPage: 'pagination.perPageCount',
  status: 'globalAppliedFilters.verification_status',
  addressId: 'globalAppliedFilters.addressId',
  city: 'globalAppliedFilters.city',
};

const responseModelAddressRegister = {
  isSuccess: 'isSuccess',
  data:{
    isNextPresent: 'data.is_next_present',
    page_data: 'data.page_data',
  },
};

const requestModelCustomerManage = {
  page: 'pagination.currentPageNumber',
  resultPerPage: 'pagination.perPageCount',
  search: {
    path: 'globalAppliedFilters.customer_code',
    fn: (value) => {
      return value ?? '';
    }
  },
  is_active_customer: {
    path: 'globalAppliedFilters.is_active_customer',
    fn: (value) => {
      return value ?? '';
    },
  },
  customer_category : {
    path: 'globalAppliedFilters.customer_category',
    fn: (value) => {
      return value ?? '';
    }
  },
  is_draft: {
    path: 'globalAppliedFilters.is_draft',
    fn: (value) => {
      return value ?? '';
    }
  },
};

const responseModelCustomerManage = {
  isSuccess: 'isSuccess',
  data:{
    isNextPresent: 'data.is_next_present',
    page_data: 'data.data',
  },
};

const requestModelSupplementaryServiceChange = {
  searchValue: {
    path: 'globalAppliedFilters.supplementary_service_code',
    fn: (value) => {
      return value ?? '';
    },
  },
  isActive: {
    path: 'globalAppliedFilters.supplementary_service_status',
    fn: (value) => {
      return value ?? '';
    },
  },
};

const responseModelSupplementaryServiceChange = {
  isSuccess: 'isSuccess',
  data:{
    isNextPresent: false,
    page_data: 'data',
  },
};

const requestModelItemUnitManage = {
  search_value: {
    path: 'globalAppliedFilters.item_unit_code',
    fn: (value) => {
      return value ?? '';
    },
  },
  is_active: {
    path: 'globalAppliedFilters.item_unit_status',
    fn: (value) => {
      return value ?? '';
    },
  },
};

const responseModelItemUnitManage = {
  isSuccess: 'isSuccess',
  data:{
    isNextPresent: false,
    page_data: 'data',
  },
};

const responseModelSingleItemUnitManage = {
  isSuccess: 'isSuccess',
  data:{
    failures: 'data.failure_list',

  },
};

const requestModelTripColumns = {
  bucket : (iteratee) => {
    return iteratee.bucket;
  },
  ops_trip_column_list : (iteratee) => {
    return iteratee.ops_consignment_column_list;
  },
};

const requestModelCRMCNSearch = {
  bucket : {
    path : 'bucket',
    fn : value => getBucketApiValue(value),
  },
  subBucket : 'subBucket',
  ...pagination,
  ...sort,
  ...bucketFilters,
  hubIdList : (iteratee) => {
    return getHubIdList(iteratee);
  },
};

const requestModelCNRevamp = {
  bucket : {
    path : 'bucket',
    fn : value => getBucketApiValue(value),
  },

  subBucket : 'subBucket',
  ...pagination,
  ...sort,
  ...globalFilters,
  ...bucketFiltersRevamp,
  appliedFilters: (iteratee) => {
    return {
      ...getFilters(iteratee.appliedFilters),
    };
  },
};

const requestModelApprovalMetrics = {
  time_filter: [{
    from_date: 'globalAppliedFilters.startDate',
    to_date: 'globalAppliedFilters.endDate',
    column_name: 'globalAppliedFilters.dateFilterType',
  }],
  hub_id_list : (iteratee) => {
    return getHubIdList(iteratee);
  },
  type: 'globalAppliedFilters.request_type',
  object_type: 'globalAppliedFilters.waybill_type',
};

const requestModelApprovalRequests = {
  ...requestModelApprovalMetrics,
  bucket : 'bucket',
  result_per_page: 'pagination.perPageCount',
  last_sorted_column_value: {
    path: 'pagination',
    fn : (value) => {
      return getLastSortedColumnValue(value);
    },
  },
  page_number: 'pagination.currentPageNumber',
};

const responseModelApprovalRequests = {
  isNextPresent: 'pagination_result.is_next_present',
  lastItemIdNext: 'pagination_result.last_row_id_next',
  lastItemIdPrev: 'pagination_result.last_row_id_prev',
  lastSortedColumnValueNext: 'pagination_result.last_sorted_column_value_next',
  lastSortedColumnValuePrev: 'pagination_result.last_sorted_column_value_prev',
  page_data: 'page_data',
};

const requestModelCallsOverview = {
  timeFilters: [{
    fromDateString: 'globalAppliedFilters.startDate',
    toDateString: 'globalAppliedFilters.endDate',
    columnName: 'globalAppliedFilters.dateFilterType',
  }],
  employee_code_string: 'appliedFilters.employee_code_string',
  result_per_page: 'pagination.perPageCount',
  page_number: 'pagination.currentPageNumber',
};

const requestModelMyCalls = {
  ...pagination,
  ...sort,
  bucket : {
    path : 'bucket',
    fn : value => getBucketApiValue(value),
  },
  timeFilters: [{
    fromDateString: 'globalAppliedFilters.startDate',
    toDateString: 'globalAppliedFilters.endDate',
    columnName: 'globalAppliedFilters.dateFilterType',
  }],
  appliedFilters: (iteratee) => {
    return {
      ...getFilters(iteratee.appliedFilters),
    };
  },
};

const requestModelSetupMaster = {
  limit: 'pagination.perPageCount',
  page: 'pagination.currentPageNumber',
  keyword: {
    path: 'globalAppliedFilters.keyword',
    fn: (value) => {
      return value ?? '';
    },
  },
  is_active: {
    path: 'globalAppliedFilters.is_active',
    fn: (value) => {
      return value ?? '';
    },
  },
  type: 'type',
};

const responseModelSetupMaster = {
  isNextPresent: 'isNextPresent',
  page_data: 'data',
  lastSortedColumnValueNext: 'lastPrimarySortFieldNext',
  lastSortedColumnValuePrev: 'lastPrimarySortFieldValuePrev',
  lastItemIdNext: 'lastIdNext',
  lastItemIdPrev: 'lastIdPrev',
};

const responseSetupMaster = {
  isNextPresent: 'response.paginationResult.is_next_present',
  lastItemIdNext: 'response.paginationResult.last_row_id_next',
  lastItemIdPrev: 'response.paginationResult.last_row_id_prev',
  isSuccess: 'isSuccess',
  data:{
    page_data: 'data',
  },
};

const responseCurrencyConversionMaster = {
  isSuccess: 'isSuccess',
  data:{
    isNextPresent: 'response.paginationResult.is_next_present',
    lastItemIdNext: 'response.paginationResult.last_row_id_next',
    lastItemIdPrev: 'response.paginationResult.last_row_id_prev',
    lastSortByKey:  'response.paginationResult.last_sort_by_key',
    page_data: 'data',
  },
};

const requestModelConsolidatedPlanningColumns = {
  bucket : (iteratee) => {
    return iteratee.bucket;
  },
  sub_bucket: (iteratee) => {
    return iteratee.sub_bucket;
  },
  consolidated_planning_column_list : (iteratee) => {
    return iteratee.ops_consolidated_planning_column_list;
  },
};

const requestModelConsolidatedPlanningFilter = {
  bucket : (iteratee) => {
    return iteratee.bucket;
  },
  consolidated_filter_list: (iteratee) => {
    return iteratee.ops_filter_list;
  },
};

const requestModelRateMaster = {
  nextOrPrev: 'pagination.nextOrPrev',
  chargeId: 'appliedFilters.chargeId',
  metadataType: 'appliedFilters.metadataType',
  lastId: 'pagination.lastId',
  lastPrimarySortFieldValue: 'pagination.lastPrimarySortFieldValue',
  pageSize: 'pagination.perPageCount',
};

const requestModelVehicleCompartmentFetch = {
  filters: {
    vehicle_compartment_code: {
      path: 'globalAppliedFilters.vehicle_compartment_code',
      fn: (value) => {
        return value ?? '';
      },
    },
    is_active: {
      path: 'globalAppliedFilters.vehicle_compartment_status',
      fn: (value) => {
        return value === false ? false : true;
      },
    },
  },
  pagination: {
    resultPerPage: 'pagination.perPageCount',
    pageNumber: 'pagination.currentPageNumber',
  },
};

const responseModelVehicleCompartmentFetch = {
  isSuccess: 'isSuccess',
  data:{
    isNextPresent: 'data.isNextPresent',
    page_data: 'data.page_data',
  },
};


const responseModelSingleVehicleCompartmentManage = {
  isSuccess: 'isSuccess',
  data:{
    failures: 'data.failure_list',
  },
  errorMessage: 'errorMessage',
};

export {
  requestModelCN,
  responseModelCN,
  requestModelSupplementaryServiceChange,
  responseModelSupplementaryServiceChange,
  requestModelSummaryMetrics,
  requestModelSubBucket,
  requestModelDownloads,
  requestModelDownloadsLogisticsExpress,
  requestModelRetailStoreCN,
  requestModelSummaryMetricsRetailStore,
  requestModelRetailStoreDownloads,
  requestModelSupportPortal,
  requestModelShelf,
  responseModelShelf,
  requestModelCrate,
  responseModelCrate,
  requestModelSupplyRequest,
  responseModelSupplyRequest,
  requestModelTripColumns,
  requestModelCRMCNSearch,
  requestModelCNRevamp,
  requestModelApprovalRequests,
  responseModelApprovalRequests,
  requestModelApprovalMetrics,
  requestCodSettlementModelCN,
  requestCodHistoryModelCN,
  requestModelManifestDeclarationCN,
  requestModelPlanningSummaryMetrics,
  requestModelCallsOverview,
  requestModelMyCalls,
  requestModelSummaryMetricsUserChoice,
  requestModelSetupMaster,
  responseModelSetupMaster,
  requestModelPlanningSubBucketSummaryMetrics,
  requestModelConsolidatedPlanningColumns,
  requestModelConsolidatedPlanningFilter,
  requestModelRateMaster,
  responseSetupMaster,
  responseCurrencyConversionMaster,
  requestModelAddressRegister,
  responseModelAddressRegister,
  requestModelCustomerManage,
  responseModelCustomerManage,
  requestModelItemUnitManage,
  responseModelItemUnitManage,
  responseModelSingleItemUnitManage,
  requestModelVehicleCompartmentFetch,
  responseModelVehicleCompartmentFetch,
  responseModelSingleVehicleCompartmentManage,
};
