import * as React from 'react';
import withStyles from 'react-jss';
import { connect } from 'react-redux';
import { CssType,
  ThemeType} from '../../../../theming/jssTypes';
import { Modal, message, Select, Button, DatePicker, Checkbox, Row, Col, InputNumber, TimePicker } from 'antd';
import { CalendarOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { createRoute, createRouteAll, getVehicleMakeDataForHub } from '../../../../api/retailDashboard';
import { getRetailTripManagerRoute, getAutoAllocateRequestsPageRoute } from '../../../../routing/utils';
import { withRouter } from 'react-router';
import { generateTimeSlotsOptionsFromList } from '../../../../utils/retailTimeslotsOptions';
import { getKeyArrayFromObject, downloadFileData } from '../../../../utils/utils';
import { getViewBuckets }from '../../../../utils/genericConfig/utils';
import * as moment from 'moment';
import FailureArrayHandler from '../../../common/FailureArrayHandler';
import { checkForRescheduledCNs } from '../../../../api/trips';
import ConfirmationModal from '../../../common/ConfirmationModal';
import { createSecondaryTrips } from '../../../../api/trips';
import { Link } from 'react-router-dom';
import { renderCustomFieldInput } from 'src/components/common/utils';

const styles = (theme: ThemeType): CssType => ({
  createRouteModal: {
    marginTop: 5,
    '& input': {
      width: '200px',
      borderRadius: '4px 0 0 4px',
      lineHeight: '32px',
    },
  },
  noWidthInput: {
    width: 'auto',
    margin: '0 !important',
    '& .ant-input': {
      width: '0px',
      padding: '0',
      borderLeft: 'none',
    },
  },
  selectStyle: {
    width: '100%',
    minWidth: '200px',
  },
  selectContainer: {
    marginBottom: '10px',
  },
  infoText: {
    paddingTop: '12px',
  },
});

const inputTypeEnum = {
  vehicleInput: 'vehicleInput',
  rosterInput: 'rosterInput',
  onDutyInput: 'onDutyInput',
  bothRosterOnDutyInput: 'bothRosterOnDutyInput',
};

class CreateRouteModal extends React.Component<any, any> {

  state: any = {
    isLoading: false,
    startTime: '',
    endTime: '',
    prettyName: '',
    failureArrayModalVisible: false,
    failureArray: [],
    isRescheduledCNConfirmationgModalVisible: false,
    rescheduledCNArray: [],
    tripStartTime: '',
    enableVehicleInput: false,
    vehicleMakeDetails: [{
      vehicleMakeCode: null,
      quantity: null,
    }],
    vehicleMakeMapping: {},
    loadingVehicleMakes: true,
    errors: {},
    enableRosterInput: false,
    enableOnDutyInput: false,
    enableBothRosterOnDutyInput: false,
    disableMapping: {
      vehicleInput: false,
      rosterInput: false,
      onDutyInput: false,
      bothRosterOnDutyInput: false,
    },
    tripDurationHours: '',
    tripDurationMinutes: '',
    customFields: {},
  };
  failureColumns = [
    {
      title: 'Consignment Number',
      dataIndex: 'reference_number',
    },
    {
      title: 'Reason',
      dataIndex: 'reason',
    },
  ];

  async componentDidMount() {
    const { currHub } = this.props;
    if (currHub?.id) {
      const res = await getVehicleMakeDataForHub(currHub?.id);
      const newState: any = {
        loadingVehicleMakes: false,
      };
      if (res.isSuccess) {
        newState.vehicleMakeMapping = res.data;
      } else {
        message.error(res.errorMessage ?? 'Error in fetching vehicle make data');
      }
      this.setState(newState);
    }
  }

  handleModalClose = () => {
    this.props.handleModalClose(false);
  };
  getScheduledDate = (value) => {
    let startDate = moment().format('YYYY-MM-DD');
    let endDate = moment().format('YYYY-MM-DD');
    if (value && value.length > 1) {
      startDate = value[0].format('YYYY-MM-DD');
      endDate = value[1].format('YYYY-MM-DD');
    }
    const obj = {
      fromDateString : startDate,
      toDateString : endDate,
    };
    return value && value.length > 1 ? obj : {};
  };

  disabledDate = (current) => {
    return current && current <= moment().subtract(1, 'days');
  };

  getPaddedString = (finalStringLength, actualString) => {
    actualString = String(actualString).trim();
    if (actualString.length >= finalStringLength) {
      return actualString;
    }
    return actualString.padStart(finalStringLength, '0');
  };

  getTripDuration = () => {
    const { tripDurationHours, tripDurationMinutes } = this.state;
    if(!tripDurationHours && !tripDurationMinutes){
      return '';
    }
    return this.getPaddedString(2, tripDurationHours) + ':' + this.getPaddedString(2, tripDurationMinutes);
  };

  getAPIRequestBody = () => {
    const {
      bucketFilters,
      isFiltered,
      action,
      dateFilter,
      showTripStartTimeForOptimisation,
      showTripStartTime,
      selectedHub,
      filters,
      trips,
      rosterEnabled,
    } = this.props;
    const { tripStartTime, prettyName, startTime, endTime,
      enableVehicleInput, vehicleMakeDetails, customFields } = this.state;
    const defaultStartTime = tripStartTime ? Date.parse(tripStartTime) : Date.now();

    const commonParams = {
      source: 'dashboard_create_route',
      hubId: selectedHub.id,
      fromDate: this.props.filters.startDate,
      toDate :  this.props.filters.endDate,
      dateField: filters.dateFilterType,
      routeOptimizationTimeSlots : {
        pretty_name: prettyName,
        start_time: startTime,
        end_time: endTime,
      },
      applyFilters: isFiltered ? true : undefined,
      ...(showTripStartTimeForOptimisation &&
        showTripStartTime && { defaultStartTime, duration: this.getTripDuration() }),
      custom_fields: customFields,
    };
    const filterParams = {
      ...bucketFilters,
      statusList: isFiltered && bucketFilters.statusList || undefined,
      attemptCountList: isFiltered && bucketFilters.attemptCountList || undefined,
      bookingServiceTypeIdList: isFiltered &&
                      bucketFilters.bookingServiceTypeIdList || undefined,
      customerIdList: isFiltered &&
                      getKeyArrayFromObject(bucketFilters.customerIdList) || undefined,
      consignmentType: isFiltered && bucketFilters.consignmentType || undefined,
      orderTypeList: isFiltered && bucketFilters.orderTypeList || undefined,
      proximityList: isFiltered && bucketFilters.proximityList || undefined,
      purchasingGroup : isFiltered && bucketFilters.purchasingGroup || undefined,
      originPincodeList : isFiltered && bucketFilters.originPincodeList || undefined,
      movementTypeList : isFiltered && bucketFilters.movementTypeList || undefined,
      destinationPincodeList : isFiltered && bucketFilters.destinationPincodeList || undefined,
      referenceNumberListInclusive :
      isFiltered && bucketFilters.reference_number_search || undefined,
      scheduledDate :  isFiltered ?
                       this.getScheduledDate(bucketFilters.scheduledDate) : undefined,
      nextHubIdList: isFiltered && getKeyArrayFromObject(bucketFilters.nextHubIdList) || undefined,
      assignedRider: isFiltered &&
          getKeyArrayFromObject(bucketFilters.assignedRider) || undefined,
      storeHubIdList: isFiltered &&
          getKeyArrayFromObject(bucketFilters.storeHubIdList) || undefined,
      exceptionIdList: isFiltered &&
          getKeyArrayFromObject(bucketFilters.exceptionIdList) || undefined,
      destinationHubIdList: isFiltered &&
          getKeyArrayFromObject(bucketFilters.destinationHubIdList) || undefined,
      kiranaHubIdList: isFiltered &&
          getKeyArrayFromObject(bucketFilters.kiranaHubIdList) || undefined,
    };

    let body;
    if (this.props.isAll) {
      body = action === 'create_secondary_trips' ? {
        endPointType: 'all',
        ...commonParams,
        ...filterParams,
      } : {
        ...commonParams,
        ...filterParams,
      };
      if(this.props.tripIsConsolidatedPlanningType)
    {
      body ={
        ...body,
        planningType : this.props.viewType,
      };
    }
    } else {
      body = action === 'create_secondary_trips' ? {
        endPointType: 'selected',
        tripReferenceNumberList: trips,
        ...commonParams,
      } : {
        // consignmentCapacity,
        consignments : this.props.consignments,
        hubId: this.props.selectedHub.id,
        routeOptimizationTimeSlots : {
          pretty_name : this.state.prettyName,
          start_time : this.state.startTime,
          end_time : this.state.endTime,
        },
        scheduledDate : dateFilter.dateFilterType === 'scheduled_at' ?
        { fromDateString: dateFilter.start , toDateString: dateFilter.end } : undefined,
        ...(showTripStartTimeForOptimisation &&
          showTripStartTime && { defaultStartTime, duration: this.getTripDuration() }),
        custom_fields: customFields,
      };
    if(this.props.tripIsConsolidatedPlanningType)
    {
      body ={
        ...body,
        taskReferenceNumberList : this.props.tasks,
        planningType : this.props.viewType,
      };
    }
    }

    if (enableVehicleInput) {
      const filledVehicleMakeDetails = vehicleMakeDetails.filter(
        v => v.vehicleMakeCode && v.quantity && v.quantity > 0);
      if (filledVehicleMakeDetails && filledVehicleMakeDetails.length) {
        body.inputDataOfVehicleMake = filledVehicleMakeDetails.reduce((acc, it) => {
          acc[it.vehicleMakeCode] = it.quantity;
          return acc;
        }, {});
      }
    }

    body.useOnDutyRiders = this.state.enableOnDutyInput;
    if(rosterEnabled){
      body.useRosterEnabledRiders = this.state.enableRosterInput;
      if(this.state.enableBothRosterOnDutyInput){
        body.useOnDutyRiders = true;
        body.useRosterEnabledRiders = true;
      }
    }

    body.changeVehicle = true;
    return body;
  };

  showSuccessMessage = (successMessage, link) => {
    return (
      message.success({
        content: (
          <div>
            {successMessage}{' '}
            <a href={`/ops${link}`} target="_blank">
              View
            </a>
            <CloseOutlined
              style={{
                color: '#333333',
                marginLeft: '0.6rem',
                marginRight: '0',
              }}
              onClick={() => message.destroy()}
            />
          </div>
        ),
        className: 'custom-antd-success-message',
        duration: 7,
      })
    )
  }

  createRouteApiCall = async () => {
    const { viewType, bucket, action, isAll, isAsync } = this.props;

    let actionConfig : any = {};
    if (action) {
      const bucketConfig = getViewBuckets(viewType)[bucket];
      actionConfig = bucketConfig.actionsApiCalls ?
                     bucketConfig.actionsApiCalls[action] : {};
    }

    let response;
    const body = this.getAPIRequestBody();
    if (action === 'create_secondary_trips') {
      response = actionConfig?.createSecondaryTrips ?
        await actionConfig.createSecondaryTrips(body, isAsync) :
        await createSecondaryTrips(body, isAsync);
    } else if (isAll) {
      response = actionConfig && actionConfig.createRouteForAll ?
        await actionConfig.createRouteForAll(body, this.props.isAsync) :
        await createRouteAll(body, this.props.isAsync);
    } else {
      response = actionConfig && actionConfig.createRouteForSelected ?
        await actionConfig.createRouteForSelected(body, this.props.isAsync) :
        await createRoute(body, this.props.isAsync);
    }

    if (response.isSuccess) {
      if (!this.props.isAsync) {
        message.success('Trips Created');
        downloadFileData(response.data, 'consignments');
        if (this.props.shouldRedirectToNewTab) {
          this.showSuccessMessage('Route created successfully', getRetailTripManagerRoute());
        } else {
          this.props.history.push(getRetailTripManagerRoute(), {
            activeBucket:'created',
          });
        }
      } else {
        if (this.props.shouldRedirectToNewTab) {
          this.showSuccessMessage('Auto allocation request created', getAutoAllocateRequestsPageRoute());
        } else {
          message.success('Auto allocation request created');
          this.props.history.push(getAutoAllocateRequestsPageRoute());
        }
      }
      this.props.handleModalClose(true);
    } else if(response?.failures?.length){
      this.setState({failureArray: response.failures, failureArrayModalVisible: true});
    } else{
      message.error(response.errorMessage);
      this.props.handleModalClose(false);
    }

    this.setState({
      confirmLoading: false,
    });
  };

  handleRescheduledCNConfirmation = async() => {
    this.setState({
      confirmLoading: true,
      isRescheduledCNConfirmationgModalVisible: false,
    });

    this.createRouteApiCall();
  };

  checkForRescheduledCNApiCall = async() => {
    const body = this.getAPIRequestBody();
    const response = await checkForRescheduledCNs(body);

    if (response.isSuccess) {
      const data = response.data;
      if (data && data.consignments && data.consignments.length > 0) {
        const rescheduledConsignments = data.consignments.map(rCN => {
          rCN.message = 'Consignment is scheduled for ' + moment(rCN.rescheduled_date).format('DD/MM/YYYY');
          return rCN;
        });

        this.setState({
          isRescheduledCNConfirmationgModalVisible : true,
          rescheduledCNArray: rescheduledConsignments,
          confirmLoading: false,
        });
      } else {
        this.createRouteApiCall();
      }
    } else {
      message.error(response.errorMessage);
      this.props.handleModalClose(false);
      this.setState({
        confirmLoading: false,
      });
    }
  };

  handleCreateRouteSubmit = async() => {
    const { checkForRescheduledCNs } = this.props;

    // validation for vehicle make data
    const { enableVehicleInput } = this.state;

    if (enableVehicleInput) {
      const errors = this.validateAllVehicleMakeData();
      if (Object.keys(errors).length > 0) {
        return;
      }
    }

    this.setState({
      confirmLoading: true,
    });

    const body = this.getAPIRequestBody();
    if (checkForRescheduledCNs) {
      this.checkForRescheduledCNApiCall();
    } else {
      this.createRouteApiCall();
    }
  };
  handleRescheduledCNConfirmationgModalClose = () => {
    this.setState({
      isRescheduledCNConfirmationgModalVisible: false,
    });
  };
  handleConsignmentCapacityChange = (e) => {
    this.setState({ consignmentCapacity: Number(e.target.value) });
  };
  onTimeSlotChange = (val) => {
    const timeSlot = val.split('_');
    if (timeSlot.length && timeSlot.length > 2) {
      this.setState({
        startTime: timeSlot[0],
        endTime: timeSlot[1],
        prettyName: timeSlot[2],
      });
    }
  };

  renderFailureArray = () => {
    const { failureArrayModalVisible, failureArray } = this.state;

    return failureArrayModalVisible ?
      <FailureArrayHandler isVisible={failureArrayModalVisible}
       failureColumns={this.failureColumns}
       failureArray={failureArray}
       onModalClose = {() => this.setState({failureArrayModalVisible: false})} />
      : null;
  };

  renderTimeSlots = () => {
    const { timeSlots, classes } = this.props;
    return timeSlots?.length > 0 && (
      <div className={classes.selectContainer}>
        <span>CN Time Slot</span>
        <Select
          allowClear
          className={classes.selectStyle}
          placeholder="Select Time Slot"
          onChange={this.onTimeSlotChange}
          suffixIcon={<CalendarOutlined />}
        >
          {generateTimeSlotsOptionsFromList(timeSlots)}
        </Select>
      </div>
    );
  };

  disableCheckBoxInput = (action,value) => {
    if(value === true){
      //disable other action entries
      switch(action){
        case inputTypeEnum.vehicleInput:
          this.setState({
            disableMapping : {
              vehicleInput: false,
              rosterInput: true,
              onDutyInput: true,
              bothRosterOnDutyInput: true,
            },
            enableRosterInput: false,
            enableOnDutyInput: false,
            enableBothRosterOnDutyInput: false,
          });
          break;
        case inputTypeEnum.rosterInput:
          this.setState({
            disableMapping : {
              vehicleInput: true,
              rosterInput: false,
              onDutyInput: true,
              bothRosterOnDutyInput: true,
            },
            enableVehicleInput: false,
            enableOnDutyInput: false,
            enableBothRosterOnDutyInput: false,
          });
          break;
        case inputTypeEnum.onDutyInput:
          this.setState({
            disableMapping : {
              vehicleInput: true,
              rosterInput: true,
              onDutyInput: false,
              bothRosterOnDutyInput: true,
            },
            enableVehicleInput: false,
            enableRosterInput: false,
            enableBothRosterOnDutyInput: false,
          });
          break;
        case inputTypeEnum.bothRosterOnDutyInput:
          this.setState({
            disableMapping : {
              vehicleInput: true,
              rosterInput: true,
              onDutyInput: true,
              bothRosterOnDutyInput: false,
            },
            enableVehicleInput: false,
            enableRosterInput: false,
            enableOnDutyInput: false,
          });
          break;
      }
    } else {
      //enable all action entries to reselect
      this.setState({
        disableMapping : {
          vehicleInput: false,
          rosterInput: false,
          onDutyInput: false,
          bothRosterOnDutyInput: false,
        },
      });
    }
  };

  onEnableVehicleInputChange = (e) => {
    const valueToSet = e.target?.checked ?? false;
    this.setState({
      enableVehicleInput: valueToSet,
    });
    this.disableCheckBoxInput(inputTypeEnum.vehicleInput,valueToSet);
  };

  onEnableRosterInputChange = (e) => {
    const valueToSet = e.target?.checked ?? false;
    this.setState({
      enableRosterInput: valueToSet,
    });
    this.disableCheckBoxInput(inputTypeEnum.rosterInput, valueToSet);
  };

  onEnableOnDutyInputChange = (e) => {
    const valueToSet = e.target?.checked ?? false;
    this.setState({
      enableOnDutyInput: valueToSet,
    });
    this.disableCheckBoxInput(inputTypeEnum.onDutyInput, valueToSet);
  };

  onEnableBothRosterOnDutyInputChange = (e) => {
    const valueToSet = e.target?.checked ?? false;
    this.setState({
      enableBothRosterOnDutyInput: valueToSet,
    });
    this.disableCheckBoxInput(inputTypeEnum.bothRosterOnDutyInput, valueToSet);
  };

  renderEnableOnDutyRidersInput = () => {
    const { classes } = this.props;
    return (
      <div className={classes.selectContainer} style={{ display: 'flex' }}>
        <Checkbox
          value={this.state.enableOnDutyInput}
          onChange={this.onEnableOnDutyInputChange}
          disabled={this.state.disableMapping.onDutyInput}
        />
        <span style={{ paddingLeft: '15px' }}>Consider On Duty Riders Only</span>
      </div>
    );
  };

  renderEnableRosterRidersInput = () => {
    const { rosterEnabled, classes } = this.props;
    return rosterEnabled && (
      <div className={classes.selectContainer} style={{ display: 'flex' }}>
        <Checkbox
          value={this.state.enableRosterInput}
          onChange={this.onEnableRosterInputChange}
          disabled={this.state.disableMapping.rosterInput}
        />
        <span style={{ paddingLeft: '15px' }}>Consider Roster Riders Only</span>
      </div>
    );
  };

  renderEnableBothOnDutyRosterRidersInput = () => {
    const { rosterEnabled, classes } = this.props;
    return rosterEnabled && (
      <div className={classes.selectContainer} style={{ display: 'flex' }}>
        <Checkbox
          value={this.state.enableBothRosterOnDutyInput}
          onChange={this.onEnableBothRosterOnDutyInputChange}
          disabled={this.state.disableMapping.bothRosterOnDutyInput}
        />
        <span style={{ paddingLeft: '15px' }}>Consider Both On Duty and Roster Riders</span>
      </div>
    );
  };

  renderEnableVehicleInput = () => {
    const { allowVehicleInputCreateRoute, classes } = this.props;
    return allowVehicleInputCreateRoute && (
      <div className={classes.selectContainer} style={{ display: 'flex' }}>
        <Checkbox
          value={this.state.enableVehicleInput}
          onChange={this.onEnableVehicleInputChange}
          disabled={this.state.disableMapping.vehicleInput}
        />
        <span style={{ paddingLeft: '15px' }}>Enable Vehicle Input</span>
      </div>
    );
  };

  validateAllVehicleMakeData = () => {
    const { vehicleMakeDetails, vehicleMakeMapping } = this.state;
    const errors = {};
    const existingMakeMap = {};

    vehicleMakeDetails.forEach((details, idx) => {
      const { vehicleMakeCode, quantity } = details;
      if (!vehicleMakeCode) {
        errors[idx] = 'Vehicle make code is mandatory';
      } else if (existingMakeMap[vehicleMakeCode]) {
        errors[idx] = 'Duplicate vehicle make code';
      } else {
        existingMakeMap[vehicleMakeCode] = true;
      }

      const permissibleQuantity = vehicleMakeMapping[vehicleMakeCode] ?? 0;

      if (!quantity && !errors[idx]) {
        errors[idx] = 'Quantity is mandatory';
      } else if (quantity && !errors[idx] && quantity > permissibleQuantity) {
        errors[idx] = 'Quantity exceeds limit';
      }
    });

    this.setState({
      errors,
    });

    return errors;
  };

  validateVehicleMakeData = (idx, fieldType) => {
    const { vehicleMakeDetails, vehicleMakeMapping, errors } = this.state;
    const newErrors = {...errors};
    const existingMakeMap = vehicleMakeDetails.reduce((acc, it, index) => {
      if (!acc[it.vehicleMakeCode] && acc[it.vehicleMakeCode] !== 0) {
        acc[it.vehicleMakeCode] = index;
      }
      return acc;
    }, {});

    const { vehicleMakeCode, quantity } = vehicleMakeDetails[idx];

    delete newErrors[idx];

    if (fieldType === 'vehicleMakeCode') {
      if (!vehicleMakeCode) {
        newErrors[idx] = 'Vehicle make code is mandatory';
      } else if ((existingMakeMap[vehicleMakeCode] || existingMakeMap[vehicleMakeCode] === 0)
      && existingMakeMap[vehicleMakeCode] !== idx) {
        newErrors[idx] = 'Duplicate vehicle make code';
      }
    } else if (!vehicleMakeCode) {
      newErrors[idx] = 'Vehicle make code is mandatory';
    } else {
        const permissibleQuantity = vehicleMakeMapping[vehicleMakeCode] ?? 0;

        if (!quantity && !newErrors[idx]) {
          newErrors[idx] = 'Quantity is mandatory';
        } else if (quantity && !newErrors[idx] && quantity > permissibleQuantity) {
          newErrors[idx] = 'Quantity exceeds limit';
        }
    }

    this.setState({
      errors: newErrors,
    });
  };

  onVehicleMakeChange = (value, idx) => {
    const { vehicleMakeDetails } = this.state;
    const newVehicleMakeDetails = [...vehicleMakeDetails];
    newVehicleMakeDetails[idx].vehicleMakeCode = value;
    newVehicleMakeDetails[idx].quantity = null;
    this.setState({
      vehicleMakeDetails: newVehicleMakeDetails,
    }, () => {
      this.validateVehicleMakeData(idx, 'vehicleMakeCode');
    });
  };

  onQuantityChange = (value, idx) => {
    const { vehicleMakeDetails } = this.state;
    const newVehicleMakeDetails = [...vehicleMakeDetails];
    newVehicleMakeDetails[idx].quantity = value;
    this.setState({
      vehicleMakeDetails: newVehicleMakeDetails,
    }, () => {
      this.validateVehicleMakeData(idx, 'quantity');
    });
  };

  handleVehicleMakeDelete = (e, idx) => {
    const { vehicleMakeDetails } = this.state;
    this.setState({
      vehicleMakeDetails: vehicleMakeDetails.filter((d, index) => index !== idx),
    });
  };

  handleAddMoreVehicleMake = () => {
    const { vehicleMakeDetails } = this.state;
    this.setState({
      vehicleMakeDetails: [...vehicleMakeDetails, {
        vehicleMakeCode: null,
        quantity: null,
      }],
    });
  };

  renderVehicleMakeDetails = () => {
    const { classes } = this.props;
    const { enableVehicleInput, vehicleMakeDetails, vehicleMakeMapping, errors } = this.state;
    if (enableVehicleInput) {
      return <>
        <div style={{ fontWeight: 'bold' }}>
          Vehicle Make Details
        </div>
        <Row style={{ fontWeight: 'bold', marginTop: '8px', fontSize: 'smaller' }}>
          <Col span={10}>Vehicle Make<span style={{ color: 'red' }}>*</span></Col>
          <Col span={8}>Quantity</Col>
        </Row>
        {
          vehicleMakeDetails.map((details, idx) => {
            return <>
              <Row style={{ marginTop: '8px' }}>
                <Col span={10} style={{ padding: '2px' }}>
                  <Select
                    allowClear
                    className={classes.selectStyle}
                    placeholder="Select Vehicle Make"
                    value={details.vehicleMakeCode}
                    onChange={(value) => this.onVehicleMakeChange(value, idx)}
                  >
                    {Object.keys(vehicleMakeMapping).map(
                      code => <Select.Option key={code} value={code}>{code}</Select.Option>)}
                  </Select>
                </Col>
                <Col span={4} style={{ padding: '2px' }}>
                  <InputNumber
                    size="middle"
                    min={0}
                    step={1}
                    value={details.quantity}
                    onChange={(value) => this.onQuantityChange(value, idx)} />
                </Col>
                <Col span={10} style={{ padding: '3px' }}>
                  {
                    details.vehicleMakeCode ? <span
                    style={{ marginRight: '5px', color: '#666666', fontSize: '11px', fontStyle: 'italic' }}>
                      Max Available: {vehicleMakeMapping[details.vehicleMakeCode]}
                    </span> : <></>
                  }
                  {
                    idx > 0 ? <span>
                      <Button type="text" icon={<CloseOutlined style={{ color: 'red' }}/>}
                        onClick={(e) => this.handleVehicleMakeDelete(e, idx)} />
                    </span> : <></>
                  }
                </Col>
              </Row>
              {
                errors[idx] ?
                <Row>
                  <Col span={24} style={{ padding: '2px', color: 'red', fontSize: '11px', fontStyle: 'italic' }}>
                    {errors[idx]}
                  </Col>
                </Row> : <></>
              }
            </>;
          })
        }
        {
          (vehicleMakeDetails?.length ?? 0) < (Object.keys(vehicleMakeMapping)?.length ?? 0) ?
          <div style={{ marginTop: '5px' }}>
            <Button type="link" icon={<PlusOutlined />} onClick={this.handleAddMoreVehicleMake}>
              Add More
            </Button>
          </div> : <></>
        }
      </>;
    }
    return <></>;
  };

  shouldRenderInfoText(durationHours, durationMinutes, tripStartTime) {
    let isDurationHoursValid = false;
    let isDurationMinutesValid = false;
    let isTripStartTimeValid = false;

    if (String(durationHours) === '0' || durationHours) {
      isDurationHoursValid = true;
    }

    if (String(durationMinutes) === '0' || durationMinutes) {
      isDurationMinutesValid = true;
    }

    if (tripStartTime) {
      isTripStartTimeValid = true;
    }

    return isDurationHoursValid && isDurationMinutesValid && isTripStartTimeValid;
  }

  handleInputChange = (key, value) => {
    this.setState((prevState) => ({
      customFields: {
        ...prevState.customFields,
        [key]: value,
      },
    }));
  };

  renderCustomFields = () => {
    const { opsFullTripColumnList, opsDashboardTripCreationModalFields } = this.props;
    const { customFields } = this.state;
    const customFieldList = opsDashboardTripCreationModalFields?.length && opsFullTripColumnList
      ?.filter((item) => opsDashboardTripCreationModalFields.includes(item.column_id));
    if (Array.isArray(customFieldList) && customFieldList.length) {
      return customFieldList?.map((field) => {
        const { column_id, pretty_name, type } = field;
        return (
          <Row align="middle" style={{ paddingTop: '10px' }} key={column_id}>
            <Col span={6}>
              {pretty_name}:
            </Col>
            <Col span={10}>
              {renderCustomFieldInput(column_id, pretty_name, type, customFields[column_id], this.handleInputChange)}
            </Col>
          </Row>
        );
      });
    }
  };

  render() {
    const { isVisible, consignments, tasks, action, trips, classes,
            tripIsConsolidatedPlanningType, showTripStartTimeForOptimisation,
            showTripStartTime } = this.props;
    const { confirmLoading,
      isRescheduledCNConfirmationgModalVisible, rescheduledCNArray, tripStartTime, enableVehicleInput } = this.state;
    let count = consignments?.length ? consignments.length : 0;
    let type = 'consignments';
    if(tripIsConsolidatedPlanningType)
    {
      const taskCount =  tasks?tasks.length:0;
      count += taskCount;
      type = 'tasks';
    }
    const defaultStartTime = tripStartTime ? Date.parse(tripStartTime) : Date.now();
    const defaultStartTimeHours = (new Date(defaultStartTime)).getHours();
    const defaultStartTimeMinutes = (new Date(defaultStartTime)).getMinutes();
    const selectionType = action === 'create_secondary_trips' ? 'trip' : type;
    const selectedCount = action === 'create_secondary_trips' ? trips?.length : count;
    return (
      <Modal
        width={600}
        confirmLoading={confirmLoading}
        title={<b>Create Route</b>}
        maskClosable={false}
        footer={[
          <Button disabled = {confirmLoading}
          onClick={() => this.props.handleModalClose(false)}>Cancel</Button>,
          <Button type="primary"
            onClick={this.handleCreateRouteSubmit} loading={confirmLoading}>Create</Button>]}
        visible={isVisible}
        onCancel={() => this.props.handleModalClose(false)}
        destroyOnClose
      >
        <div className={classes.createRouteModal}>
        {this.props.isAll ? <p>All {selectionType} are selected for optimisation. Continue? </p> :
        <p>{selectedCount.length}
          {selectedCount} {selectionType} are
          selected for route optimisation. Continue ?</p>}
          <Row>
            <Col span={12}>
              {this.renderEnableVehicleInput()}
            </Col>
            <Col span={12}>
              {this.renderEnableRosterRidersInput()}
            </Col>
            <Col span={12}>
              {this.renderEnableOnDutyRidersInput()}
            </Col>
            <Col span={12}>
              {this.renderEnableBothOnDutyRosterRidersInput()}
            </Col>
          </Row>
          {this.renderTimeSlots()}
          {showTripStartTimeForOptimisation && showTripStartTime ? (
            <>
              <div className={classes.selectContainer}>
                <span>Trip Start Time</span>
                <DatePicker
                  className={classes.selectStyle}
                  placeholder="Select Start Date"
                  showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                  disabledDate={this.disabledDate}
                  onChange={(value) => this.setState({ tripStartTime: value })}
                  size="small"
                />
              </div>
              {enableVehicleInput ? (
                <div className={classes.selectContainer}>
                <span>Trip Duration</span>
                <br />
                <InputNumber
                  size="middle"
                  min={0}
                  max={167}
                  step={1}
                  placeholder={'Hours'}
                  style={{ marginRight: '8px' }}
                  value={this.state.tripDurationHours}
                  onChange={(value) => this.setState({ tripDurationHours: value })}
                />
                <InputNumber
                  size="middle"
                  min={0}
                  max={59}
                  step={1}
                  placeholder={'Minutes'}
                  value={this.state.tripDurationMinutes}
                  onChange={(value) => this.setState({ tripDurationMinutes: value })}
                />
                <br/>
                {
                  this.shouldRenderInfoText(this.state.tripDurationHours, this.state.tripDurationMinutes, this.state.tripStartTime)
                  ? (
                    <p className={classes.infoText}>Vehicle will be available for {this.state.tripDurationHours} Hours and {this.state.tripDurationMinutes} minutes starting from {this.getPaddedString(2, defaultStartTimeHours)}:{this.getPaddedString(2, defaultStartTimeMinutes)} today. If you wish to change, modify the duration and time slot start</p>
                  ) : null
                }
              </div>
              ) : null}
            </>
          ) : null}
          {this.renderVehicleMakeDetails()}
          {this.renderCustomFields()}
        </div>
        {this.renderFailureArray()}
        { isRescheduledCNConfirmationgModalVisible ?
          <ConfirmationModal
            title={'Confirmation'}
            dataArray={rescheduledCNArray}
            isVisible={isRescheduledCNConfirmationgModalVisible}
            onModalClose={this.handleRescheduledCNConfirmationgModalClose}
            showFooterSection={true}
            footerText={'Do you want to continue?'}
            primaryButtonText={'Yes'}
            onClickPrimaryButton={this.handleRescheduledCNConfirmation}
            secondaryButtonText={'No'}
            onClickSecondaryButton={this.handleRescheduledCNConfirmationgModalClose}
            >
          </ConfirmationModal> : null
        }
      </Modal>
    );
  }
}

const mapStateToProps = ({ masterData, cachedData }, ownProps) => {
  const { viewType } = ownProps;
  return {
    showTripStartTimeForOptimisation:
      masterData?.ops_dashboard_config?.parts_to_show?.etaConfig
        ?.input_start_time_for_optimisation || false,
    allowVehicleInputCreateRoute: masterData.ops_dashboard_config?.
    parts_to_show?.flm_trip_config?.allow_vehicle_input_create_route,
    rosterEnabled: masterData?.show_roster_management_ondemand || false,
    currHub: cachedData.currHub,
    opsDashboardTripCreationModalFields: masterData.ops_dashboard_trip_creation_modal_fields,
    opsFullTripColumnList: masterData.ops_full_trip_column_list || [],
  };
};

const CreateRouteModalStyled: any = withStyles(styles, { injectTheme: true })(CreateRouteModal);
const CreateRouteModalWithRouter = withRouter(CreateRouteModalStyled) as  React.ComponentType<any>;
export default connect(
  mapStateToProps,
)(CreateRouteModalWithRouter);
