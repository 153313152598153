import apiProvider from './provider';
import {
  getAuthenticationHeaders,
  getErrorMessage,
  getErrorFailures,
  generateQueryString,
  getErrorMessageForBlob,
} from './utils';
import { API_BASE_URL } from '../globals';
import {
  GET_ALL_TRIPS_DATA, ASSIGN_RIDER, FETCH_ONE_TRIP,
  REORDER_CN, DELETE_CN, ADD_CN, MOVE_CN, GET_FREE_WORKERS, GET_TRIPS,
  GET_TRACKING_DATA, GET_TRACKING_HISTORY_DATA, GET_ALL_WORKERS,
  START_TRIP, END_TRIP, DELETE_TRIP, UNFREEZE_TRIP, TRIP_SUMMARY_METRICS_DATA,
  BULK_DELETE_TRIPS, BULK_DELETE_TRIP_CONSIGNMENT, GET_PLANNED_TRIP_TRACKING_DATA,
  GET_TRACKING_HISTORY_TRIP_LIST, OPTIMISE_ROUTE, BULK_MANUAL_TRIP,
  DOWNLOAD_RETAIL_TRIP_DATA, FREEZE_TRIP,
  DOWNLOAD_RETAIL_TRIP_CN_DATA,
  GET_OPTIMIZER_REQUESTS,
  RETRY_OPTIMIZER_REQUESTS,
  BULK_ASSIGN_TRIPS,
  RETAIL_REPORT_DOWNLOAD,
  GET_FREE_VEHICLES,
  ASSIGN_VEHICLE,VERIFY_MERCHANT_HOURS,
  GET_TRIP_RECONCILIATION_DETAILS, RECONCILE_TRIP_CONSIGNMENTS,
  RECONCILE_COD, SCHEDULE_RIDER, SCHEDULE_VEHICLE,
  CREATE_DOWNLOAD_REQUEST_TRIPS_V2, REORDER_SEQUENCE, DELETE_SEQUENCE,
  VALIDATE_CONSIGNMENTS_FLM, FLM_TRIP_CLOSURE_DOWNLOAD, GET_ALL_PICKUP_DETAILS,
  CREATE_SEPARATE_TRIP_MANAGER_DOWNLOADS_REQUESTS, GET_CUSTOM_TRIP_REQUESTS,
  CHECK_FOR_RESCHEDULED_CNs, CREATE_SECONDARY_TRIPS, UPDATE_TRIP_DETAILS,
  BULK_UPDATE_TRIP_DETAILS,
  ADD_PICKUP_REQUEST, DELETE_PICKUP_REQUEST, CALCULATE_SIMULATED_ETA,
  REORDER_TASK, DELETE_PICKUP_TASK, ADD_PICKUP_TASK, GET_WORKER_LOCATION,
  GET_TRIP_DETAILS_DEFAULT_METADATA, UPDATE_TRIP_DETAILS_DEFAULT_METADATA,
  GET_VEHICLE_MAKE_LIST, GET_TRACKING_DIRECTIONS, GET_TRACKING_DIRECTIONS_BY_TASKS,
  GET_VEHICLE_VENDOR_LIST, OPTIMISE_TRIP_ROUTE, GET_ORDER_TRACKING_DIRECTIONS,
  TRIP_UPDATE_VEHICLE_MAKE, RESEND_RIDER_CONSENT,
  GET_TRIP_SIMULATED_METRICS,
  GENERATE_EWB_FLM_TRIP_CN,
  FLM_TRIP_CN_WITH_EWB_STATUS,
  UPDATE_TRIP_DISTANCE,
  DOWNLOAD_TRIP_LOGS_TRACKING_DATA,
  CREATE_CUSTOM_TRIP_PENALTIES_FLM,
  CREATE_CUSTOM_TRIP_PENALTIES_FLM_BULK,
  CREATE_CUSTOM_TRIP_PENALTIES_IST,
  GET_SAMPLE_EXCEL_ADD_TRIP_PENALTIES,
  UPDATE_CUSTOM_TRIP_CHARGES,
  DOWNLOAD_FLM_TRIP_VERIFICATION_DETAILS,
  FETCH_FLESPI_DATA_FOR_TRIP,
  GET_AVAILABLE_WORKERS,
  GET_FREE_HELPER_WORKERS, ASSIGN_HELPER,
  GET_RIDER_TRIP_DATA,
  GET_RIDER_SHIFT_DATA,
  GET_TASK_TYPE, GET_CONSIGNMENT_IOT_READINGS,
  GET_ALL_VEHICLES,
} from './routes';
import { requestSchema, responseSchema,
   requestSchemaSummaryMetrics } from '../model/retailTripView';
import { morphism } from 'morphism';
import qs from 'qs';
import { GET, POST } from './apiHandler';

export const getAllTripsData = async (
  body,
) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${GET_ALL_TRIPS_DATA}`, morphism(requestSchema, body),
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: morphism(responseSchema, response.data.data),
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const getFullTripList = async (hubId, tripId) => {
  try {
    const response = await apiProvider.get(
      `${API_BASE_URL}${GET_TRIPS}?hubId=${hubId}&tripId=${tripId}`,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const getFullHelperList = async (body) => {
  return GET(`${API_BASE_URL}${GET_FREE_HELPER_WORKERS}`, body);
};

export const assignHelper = async (body) => {
  return POST(`${API_BASE_URL}${ASSIGN_HELPER}`, body);
};

export const getFullWorkerList = async (body) => {
  try {
    const response = await apiProvider.get(
      `${API_BASE_URL}${GET_FREE_WORKERS}`,
      {
        params: {...body},
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const getAvailableWorkerList = async (body) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${GET_AVAILABLE_WORKERS}`,
      body,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const getFullVehiclesList = async (body) => {
  try {
    const response = await apiProvider.get(
      `${API_BASE_URL}${GET_FREE_VEHICLES}`,
      {
        params: {...body},
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const getAllVehicleList = async (hubId, queryStr = '') => {
  return GET(`${API_BASE_URL}${GET_ALL_VEHICLES}`, { hubId, query: queryStr });
};

export const getAllWorkerList = async (hubId, queryStr = '') => {
  try {
    const response = await apiProvider.get(
      `${API_BASE_URL}${GET_ALL_WORKERS}?hubId=${hubId}&query=${queryStr}`,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const assignRider = async (body) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${ASSIGN_RIDER}`,
      body,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const assignVehicle = async (body) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${ASSIGN_VEHICLE}`,
      body,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const deleteCN = async (body) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${DELETE_CN}`,
      body,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const addCN = async (body) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${ADD_CN}`,
      body,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    const data = response.data.data;
    if (data.failureArray) {
      data.failureArray = data.failureArray.map(ele =>
        ({ message: ele.error, reference_number: ele.referenceNumber }),
      );
    }
    return {
      data: response.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};
export const verifyValidCNBasedOnMerchantHours = async (body) => {
  return POST(`${API_BASE_URL}${VERIFY_MERCHANT_HOURS}`, body);
};

export const moveCN = async (body) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${MOVE_CN}`,
      body,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const reorderCN = async (body) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${REORDER_CN}`,
      body,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const fetchOne = async (searchKey, searchBy = 'id') => {
  try {
    const response = await apiProvider.get(
      `${API_BASE_URL}${FETCH_ONE_TRIP}searchBy=${searchBy}&searchKey=${searchKey}`,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};
export const getTrackingData = async (hubId) => {
  try {
    const response = await apiProvider.get(
      `${API_BASE_URL}${GET_TRACKING_DATA}?hubId=${hubId}`,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const getPlannedTripTrackingData = async (body) => {
  try {
    const response = await apiProvider.get(
      `${API_BASE_URL}${GET_PLANNED_TRIP_TRACKING_DATA}`,
      {
        params: {
          hubId: body.hubId || body.currHub?.id,
          bucket: body.bucket,
          fromDate: body.fromDate || body.globalAppliedFilters?.startDate,
          toDate: body.toDate || body.globalAppliedFilters?.endDate,
          dateField: body.dateField || body.globalAppliedFilters?.dateFilterType,
          tripType: body.tripType || body.appliedFilters?.tripType,
          route: body.route,
          tripReferenceNumber: body.tripReferenceNumber,
          planningId : body.planningId || body.appliedFilters?.planningId,
          statusList: body.statusList ?? body.appliedFilters?.statusList,
          vehicleType: body.vehicleType ?? body.appliedFilters?.vehicleType,
          workerId: body.workerId?.key ?? body.appliedFilters?.workerId?.key,
        },

        paramsSerializer(params) {
          return qs.stringify(params, { arrayFormat: 'repeat' });
        },
        headers: await getAuthenticationHeaders(),
      },
    );
    let data = response.data;
    // The check means that the request came from generic consignment reducer
    // To maintain the format of the same, we transform response here.
    if (body.currHub) {
      data = {
        page_data: response.data?.data?.tripData ?? [],
      };
    }
    return {
      data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const getTrackingHistoryTripList = async (params) => {
  try {
    const response = await apiProvider.get(
      `${API_BASE_URL}${GET_TRACKING_HISTORY_TRIP_LIST}?${generateQueryString(params)}`,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};
export const getTrackingHistoryData = async (params) => {
  try {
    const response: any = await apiProvider.get(
      `${API_BASE_URL}${GET_TRACKING_HISTORY_DATA}?${generateQueryString(params)}`,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const startTrip = async (body) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${START_TRIP}`,
      body,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    const errorFailures = getErrorFailures(err);
    return {
      errorMessage,
      errorFailures,
      isSuccess: false,
    };
  }
};

export const endTrip = async (body) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${END_TRIP}`,
      body,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const deleteTrip = async (body) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${DELETE_TRIP}`,
      body,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const freezeTrip = async (body) => {
  return POST( `${API_BASE_URL}${FREEZE_TRIP}`, body);
};

export const optimiseRoute = async (params) => {
  return POST( `${API_BASE_URL}${OPTIMISE_ROUTE}`, params);
};

export const optimiseTripRoute = async (params) => {
  return POST( `${API_BASE_URL}${OPTIMISE_TRIP_ROUTE}`, params);
};

export const unfreezeTrip = async (params) => {
  try {
  const response = await POST( `${API_BASE_URL}${UNFREEZE_TRIP}`, params);
    return {
      data: response.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const getSummaryMetricsData = async (body) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${TRIP_SUMMARY_METRICS_DATA}`, morphism(requestSchemaSummaryMetrics, body), {
        headers: await getAuthenticationHeaders() });
    return {
      isSuccess: true,
      data: response.data.data,
    };
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};
export const bulkDeleteTrips = async(body) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${BULK_DELETE_TRIPS}`,
      body,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    const data = response.data.data;
    if (data.failures) {
      data['failureArray'] = data.failures.map(ele =>
        ({ message: ele.message, reference_number: ele.trip_reference_number }),
      );
    }
    return {
      data: response.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const bulkManualTrip = async(body) => {
  return POST(`${API_BASE_URL}${BULK_MANUAL_TRIP}`, body);
};

export const bulkDeleteTripConsignment  = async(body) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${BULK_DELETE_TRIP_CONSIGNMENT}`,
      body,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    const data = response.data.data;
    if (data.failures) {
      data['failureArray'] = data.failures.map(ele =>
        ({ message: ele.message, reference_number: ele.reference_number }),
      );
    }
    return {
      data: response.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const downloadTripsData = async(body) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${DOWNLOAD_RETAIL_TRIP_DATA}`,
      body,
      {
        headers: await getAuthenticationHeaders(),
        responseType:'blob',
      },
    );
    return {
      fileName : response.headers['Content-Disposition'],
      data: response.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = await getErrorMessageForBlob(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const downloadTripsCNData = async(body) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${DOWNLOAD_RETAIL_TRIP_CN_DATA}`,
      body,
      {
        headers: await getAuthenticationHeaders(),
        responseType:'blob',
      },
    );
    return {
      fileName : response.headers['Content-Disposition'],
      data: response.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = await getErrorMessageForBlob(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const getAllOptimizerRequests = async(date, hubIds) => {
  try {
    const response = await apiProvider.get(
      `${API_BASE_URL}${GET_OPTIMIZER_REQUESTS}`,
      {
        params: {
          date,
          hub_ids: hubIds,
        },
        paramsSerializer(params) {
          return qs.stringify(params, { arrayFormat: 'repeat' });
        },
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const retryAutoAllocate = async(id) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${RETRY_OPTIMIZER_REQUESTS}/${id}/retry`, {},
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const retailReportDownload = async(body) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${RETAIL_REPORT_DOWNLOAD}`,
      body,
      {
        headers: await getAuthenticationHeaders(),
        responseType:'blob',
      },
    );
    return {
      fileName : response.headers['Content-Disposition'],
      data: response.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = await getErrorMessageForBlob(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const getAllOptimizerRequestsPlanningType = async(date, hubIds, planningType?:string) => {
  try {
    const response = await apiProvider.get(
      `${API_BASE_URL}${GET_OPTIMIZER_REQUESTS}`,
      {
        params: {
          date,
          hub_ids: hubIds,
          planning_type: planningType,
        },
        paramsSerializer(params) {
          return qs.stringify(params, { arrayFormat: 'repeat' });
        },
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};
export const bulkAssignTrips = async(body) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${BULK_ASSIGN_TRIPS}`,
      body,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    const data = response.data.data;
    if (data.failures) {
      data['failureArray'] = data.failures.map(ele =>
        ({ message: ele.message, reference_number: ele.trip_reference_number }),
      );
    }
    return {
      data: response.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};
export const fetchReconciliationDetails = async(params) => {
  return POST( `${API_BASE_URL}${GET_TRIP_RECONCILIATION_DETAILS}`, params);
};

export const reconcileConsignments = async(body) => {
  return POST(`${API_BASE_URL}${RECONCILE_TRIP_CONSIGNMENTS}`, body);
};

export const reconcileCOD = async(body) => {
  return POST(`${API_BASE_URL}${RECONCILE_COD}`, body );
};

export const scheduleRider = async(body) => {
  return POST( `${API_BASE_URL}${SCHEDULE_RIDER}`, body);
};

export const scheduleVehicle = async(body) => {
  return POST( `${API_BASE_URL}${SCHEDULE_VEHICLE}`, body);
};
export const createDownloadRequestTrips = async(body) => {
  return POST( `${API_BASE_URL}${CREATE_DOWNLOAD_REQUEST_TRIPS_V2}`, body);
};

export const validateConsignmentsFLM = async(body) => {
  return POST(`${API_BASE_URL}${VALIDATE_CONSIGNMENTS_FLM}`, body);
};
export const createSeparateDownloadRequestsTrip = async(body) => {
  return POST(`${API_BASE_URL}${CREATE_SEPARATE_TRIP_MANAGER_DOWNLOADS_REQUESTS}`, body);
};

export const checkForRescheduledCNs = async(body) => {
  return POST(`${API_BASE_URL}${CHECK_FOR_RESCHEDULED_CNs}`, body);
};
export const createSecondaryTrips = async(body, isAsync) => {
  let response;
  if (isAsync) {
    response = await POST(`${API_BASE_URL}${CREATE_SECONDARY_TRIPS}`, body);
  }
  else {
    response = await POST(`${API_BASE_URL}${CREATE_SECONDARY_TRIPS}`, body,
    await getAuthenticationHeaders(), true);
  }
  return response;
};
export const updateTripDetails = async(body) => {
  return POST(`${API_BASE_URL}${UPDATE_TRIP_DETAILS}`, body);
};

export const bulkUpdateTripDetails = async(body) => {
  return POST(`${API_BASE_URL}${BULK_UPDATE_TRIP_DETAILS}`, body);
};

export const getCustomTripRequests = async(params) => {
  return GET(`${API_BASE_URL}${GET_CUSTOM_TRIP_REQUESTS}`, params);
};
export const downloadFLMTripClosureSheet = async ( trip_id ) => {
  return GET( `${API_BASE_URL}${FLM_TRIP_CLOSURE_DOWNLOAD}`, { trip_id }, 'blob');
};
export const fetchPickupDetails = async(reference_number) => {
  return GET(`${API_BASE_URL}${GET_ALL_PICKUP_DETAILS}?reference_number=${reference_number}`,{});
};
export const addPickup = async(body) => {
  return POST(`${API_BASE_URL}${ADD_PICKUP_REQUEST}`,body);
};
export const deletePickup = async(body) => {
  return POST(`${API_BASE_URL}${DELETE_PICKUP_REQUEST}`,body);
};
export const calculateSimulatedEta = async(body) => {
  return POST(`${API_BASE_URL}${CALCULATE_SIMULATED_ETA}`, body);
};
export const reorderSequence = async (body) => {
  return POST(`${API_BASE_URL}${REORDER_SEQUENCE}`, body);
};
export const deleteSequence = async (body) => {
  return POST(`${API_BASE_URL}${DELETE_SEQUENCE}`, body);
};
export const addPickupTask = async (body) => {
  return POST(`${API_BASE_URL}${ADD_PICKUP_TASK}`, body);
};
export const reorderTask = async (body) => {
  return POST(`${API_BASE_URL}${REORDER_TASK}`, body);
};
export const deletePickupTask = async (body) => {
  return POST(`${API_BASE_URL}${DELETE_PICKUP_TASK}`, body);
};
export const getWorkerLocation = async (params) => {
  return GET(`${API_BASE_URL}${GET_WORKER_LOCATION}`, params);
};
export const fetchTripDetailsDefaultMetadata = async () => {
  return GET(`${API_BASE_URL}${GET_TRIP_DETAILS_DEFAULT_METADATA}`);
};
export const updateTripDetailsMetadata = async (body) => {
  return POST(`${API_BASE_URL}${UPDATE_TRIP_DETAILS_DEFAULT_METADATA}`, body);
};

export const getFullVehicleMakeList = async (params) => {
    return GET(`${API_BASE_URL}${GET_VEHICLE_MAKE_LIST}`,params);
};

export const getTripConsignmentEWBList = async (params) => {
  return GET(`${API_BASE_URL}${FLM_TRIP_CN_WITH_EWB_STATUS}`, params);
};
export const modifyVehicleMake = async (params) => {
    return POST(`${API_BASE_URL}${TRIP_UPDATE_VEHICLE_MAKE}`, params);
};

export const getFullVehicleVendorList = async (params) => {
    return GET(`${API_BASE_URL}${GET_VEHICLE_VENDOR_LIST}`,params);
};

export const downloadTripTrackingData = async(body) => {
  return POST(`${API_BASE_URL}${DOWNLOAD_TRIP_LOGS_TRACKING_DATA}`, body);
};

export const getTrackingDirections = async (params) => {
  return GET(`${API_BASE_URL}${GET_TRACKING_DIRECTIONS}`, params);
};

export const getTrackingDirectionsByTasks = async (params) => {
  return POST(`${API_BASE_URL}${GET_TRACKING_DIRECTIONS_BY_TASKS}`, params);
};

export const getTripSimulatedMetrics = async (params) => {
  return POST(`${API_BASE_URL}${GET_TRIP_SIMULATED_METRICS}`, params);
};

export const getOrderTrackingDirections = async (body) => {
  return POST(`${API_BASE_URL}${GET_ORDER_TRACKING_DIRECTIONS}`, body);
};

export const generateEWBForFlmTrip = async (params) => {
  return POST(`${API_BASE_URL}${GENERATE_EWB_FLM_TRIP_CN}`, params);
};
export const resendRiderConsent = async (body) => {
  return POST(`${API_BASE_URL}${RESEND_RIDER_CONSENT}`, body);
};

export const downloadSampleExcelForAddTripPenalties = async() => {
  return GET(`${API_BASE_URL}${GET_SAMPLE_EXCEL_ADD_TRIP_PENALTIES}`);
};

export const createTripPenaltiesFlm = async (body) => {
  return POST(`${API_BASE_URL}${CREATE_CUSTOM_TRIP_PENALTIES_FLM}`, body);
};

export const createTripPenaltiesFlmBulk = async (body) => {
  return POST(`${API_BASE_URL}${CREATE_CUSTOM_TRIP_PENALTIES_FLM_BULK}`, body);
};

export const createTripPenaltiesIst = async (body) => {
  return POST(`${API_BASE_URL}${CREATE_CUSTOM_TRIP_PENALTIES_IST}`, body);
};

export const updateTripCharges = async (body) => {
  return POST(`${API_BASE_URL}${UPDATE_CUSTOM_TRIP_CHARGES}`, body);
};

export const updateDistance = async (body) => {
  return POST(`${API_BASE_URL}${UPDATE_TRIP_DISTANCE}`, body);
};

export const downloadTripVerificationDetails = async (body) => {
  return POST(`${API_BASE_URL}${DOWNLOAD_FLM_TRIP_VERIFICATION_DETAILS}`, body, getAuthenticationHeaders() , true);
};

export const fetchFlespiDataForTrip = async(body) => {
  return POST(
    `${API_BASE_URL}${FETCH_FLESPI_DATA_FOR_TRIP}`,
    body,
    await getAuthenticationHeaders(),
    true,
  );
};

export const getRiderTripData = async (body) => {
  // Mocking this API to use API in component itself
  const finalResult = {
    isSuccess: true,
    data: {
      page_data: [],
    },
  };
  return finalResult;
};

export const getRiderTripDataAsync = async (requestParams) => {
  return GET(`${API_BASE_URL}${GET_RIDER_TRIP_DATA}`, requestParams);
};

export const getRiderShiftData = async (requestParams) => {
  return POST(`${API_BASE_URL}${GET_RIDER_SHIFT_DATA}`, requestParams);
};

export const getTaskDetails = async (requestParams) => {
  return POST(`${API_BASE_URL}${GET_TASK_TYPE}`, requestParams);
};

export const fetchConsignmentIotReadings = async (body) => {
  return POST(`${API_BASE_URL}${GET_CONSIGNMENT_IOT_READINGS}`, body);
};
