import {
    fetchTMSTransporters,
} from '../../../api/TMSDashboard/genericTMSView';
export const filterKeys = [
    'hub_code',
    'consignment_reference_number',
    'piece_list',
    'courier_account',
    'claim_type',
    'claim_value',
    'remarks',
];
export const filterKeysSettle = [
    'settlement_reference_number',
    'settlement_remarks',
];

export const viewDetailsFields = [
    {
        key: 'claim_request_claim_reference_number',
        label: 'Claim Reference Number',
    },
    {
        key: 'claim_request_hub_code',
        label: 'Hub Code',
    },
    {
        key: 'claim_request_defaulter_name',
        label: 'Defaulter Name',
    },
    {
        key: 'claim_request_is_settled',
        label: 'Is Settled',
    },
    {
        key: 'claim_request_consignment_reference_number',
        label: 'Consignment Reference Number',
    },
    {
        key: 'claim_request_piece_list',
        label: 'Piece List',
    },
    {
        key: 'claim_request_claim_type',
        label: 'Claim Type',
    },
    {
        key: 'claim_request_claim_value',
        label: 'Claim Value',
    },
    {
        key: 'claim_request_supporting_docs_url',
        label: 'Supporting Documents',
    },
    {
        key: 'claim_request_requester_remarks',
        label: 'Requester Remarks',
    },
];
export const claimRequestsColumnsInApprovalRequesterPage = [
    {
        column_id: 'claim_request_claim_reference_number',
        pretty_name: 'Claim Reference Number',
    },
    {
        column_id: 'claim_request_consignment_reference_number',
        pretty_name: 'Consignment Reference Number',
    },
    {
        column_id: 'claim_request_pending_on',
        pretty_name: 'Pending On',
    },
    {
        column_id: 'claim_request_pending_on_approvers',
        pretty_name: 'Approvers',
    },
    {
        column_id: 'claim_request_submitted_by',
        pretty_name: 'Raised By',
    },
    {
        column_id: 'claim_request_submitted_at',
        pretty_name: 'Raised At',
    },
    {
        column_id: 'viewDetails',
        pretty_name: 'View Details',
    },
];
export const claimRequestsFailureColumnInApprovalRequesterPage = [
    {
        title: 'Approval Request Reference Number',
        dataIndex: 'reference_number',
    },
    {
        title: 'Error',
        dataIndex: 'error',
    },
];
export const claimRequestsColumns = [
    {
        id: 'claim_request_claim_reference_number',
        pretty_name: 'Claim Reference Number',
    },
    {
        id: 'claim_request_hub_code',
        pretty_name: 'Hub Code',
    },
    {
        id: 'claim_request_submitted_at',
        pretty_name: 'Submitted At',
    },
    {
        id: 'claim_request_submitted_by',
        pretty_name: 'Submitted By',
    },
    {
        id: 'claim_request_is_settled',
        pretty_name: 'Is Settled',
    },
    {
        id: 'claim_request_is_3pl_consignment',
        pretty_name: 'Is 3PL Consignment',
    },
    {
        id: 'claim_request_defaulter_name',
        pretty_name: 'Defaulter Name',
    },
    {
        id: 'claim_request_defaulter_vendor_name',
        pretty_name: 'Defaulter Vendor Name',
    },
    {
        id: 'claim_request_piece_list',
        pretty_name: 'Piece List',
    },
    {
        id: 'claim_request_status',
        pretty_name: 'Status',
    },
    {
        id: 'claim_request_claim_value',
        pretty_name: 'Claim Value',
    },
    {
        id: 'claim_request_claim_type',
        pretty_name: 'Claim Type',
    },
    {
        id: 'claim_request_last_approved_at',
        pretty_name: 'Last Approved At',
    },
    {
        id: 'claim_request_last_approved_by',
        pretty_name: 'Last Approved By',
    },
    {
        id: 'claim_request_settlement_reference_number',
        pretty_name: 'Settlement Reference Number',
    },
    {
        id: 'claim_request_settlement_supporting_docs_urls',
        pretty_name: 'Settlement Supporting Docs',
    },
    {
        id: 'claim_request_settled_by',
        pretty_name: 'Settled By',
    },
];
export const claimRegistrationFields = (
    separateChildCns = false,
    claimTypeValues = [],
    thirdPartyPage = false,
) => [
        {
            id: 'hub_code',
            label: 'Hub Code',
            disabled: true,
            type: 'input',
            subtype: 'string',
            isVisible: true,
            autopopulate: true,
            required: true,
            message: 'Please enter Hub Code',
        },
        {
            id: 'consignment_reference_number',
            label: 'Consignment Reference Number',
            disabled: true,
            subtype: 'string',
            isVisible: true,
            type: 'input',
            autopopulate: true,
            required: true,
            message: 'Please enter Consignment Reference Number',
        },
        {
            id: 'piece_list',
            type: 'select',
            subtype: 'multiple',
            isVisible: separateChildCns,
            label: 'Select Piece',
            required: true,
            message: 'Please select Pieces',
        },
        {
            id: 'vendor_code',
            type: 'select',
            subtype: 'single',
            isVisible: !thirdPartyPage,
            label: 'Defaulter Name',
            options: [],
            required: true,
            message: 'Please select Defaulter Name',
        },
        {
            id: 'courier_account',
            type: 'input',
            subtype: 'string',
            disabled: true,
            isVisible: thirdPartyPage,
            label: 'Defaulter Name',
            required: true,
            autopopulate: true,
            message: 'Please enter Defaulter Name',
        },
        {
            id: 'claim_type',
            type: 'select',
            subtype: 'single',
            options: claimTypeValues,
            isVisible: true,
            required: true,
            label: 'Type of Claim',
            message: 'Please select Type of Claim',
        },
        {
            id: 'claim_value',
            type: 'input',
            isVisible: true,
            label: 'Claim Value',
            subtype: 'number',
            required: true,
            message: 'Please enter Claim Value',
        },
        {
            id: 'remarks',
            type: 'input',
            subtype: 'text',
            isVisible: true,
            label: 'Remarks',
            required: true,
            message: 'Please enter Remarks',
        },
        {
            id: 'attachment',
            type: 'upload_image',
            isVisible: true,
            label: 'Attachment',
            required: true,
            message: 'Please upload Attachment',
        },
    ];
export const claimSettleFields = [
    {
        id: 'settlement_reference_number',
        label: 'Settlement Reference Number',
        type: 'input',
        subtype: 'string',
        isVisible: true,
        required: true,
        message: 'Please enter Settlement Reference Number',
    },
    {
        id: 'settlement_remarks',
        label: 'Settlement Remarks',
        subtype: 'text',
        isVisible: true,
        type: 'input',
        required: true,
        message: 'Please enter Settlement Remarks',
    },
    {
        id: 'attachment',
        type: 'upload_image',
        isVisible: true,
        label: 'Settlement Supporting Docs',
        required: true,
        message: 'Please upload Settlement Supporting Docs',
    },
];

export const formItemLayout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 8 },
};
export const uploadProps = {
    accept: '.png,',
    name: 'file',
    multiple: false,
    showUploadList: { showPreviewIcon: false, showRemoveIcon: true },
};

export const handleTransporterSearch = async (queryString) => {
    if (!queryString.trim()) {
        return [];
    }
    if (queryString.length >= 0) {
        const response = await fetchTMSTransporters(queryString);
        if (!response.isSuccess) {
            return [];
        }
        if (!response.data) {
            return [];
        }
        const searchedTransporters = (response.data).map((transporter) => {
            return {
                id: transporter.id,
                name: transporter.name,
                code: transporter.code,
            };
        }) || [];
        return searchedTransporters;
    }
};
