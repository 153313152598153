import {
    fetchAllVehicleCompartments,
    addMultipleVehicleCompartments,
    updateMultipleVehicleCompartments,
    generateSampleVehicleCompartmentExcelForBulkAction,
    addSingleVehicleCompartment,
    updateSingleVehicleCompartment,
    downloadVehicleCompartments,
} from '@api/vehicleCompartmentMaster';

import {
    VEHICLE_COMPARTMENT_MASTER_PARAMS,
} from '@src/components/pages/OpsDashboard/Setup/TransportModule/VehicleCompartment/VehicleCompartmentMaster.constants';

const viewControl = {
    pageTitle: 'Vehicle Compartment Master',
    buckets: ['vehicleCompartmentMaster'],
    globalActions: [],
    downloadWithGlobalAppliedFilters: true,
    downloadOptions : [
        {
            key: 'download',
            title: 'Download Data',
            action: 'download',
        },
    ],
    addOptions: [
        {
            key: 'add_single',
            title: 'Single',
            action: 'add_single',
            type: 'single',
        },
        {
            key: 'add',
            title: 'Bulk',
            action: 'add_bulk',
            type: 'bulk',
        },
    ],
    fetchData: fetchAllVehicleCompartments,
    downloadData: downloadVehicleCompartments,
    add_bulk: {
        downloadSampleCsvFromApiCall: true,
        downloadSampleCsv: generateSampleVehicleCompartmentExcelForBulkAction,
        title: 'Add Vehicle Compartments',
        onBulkUpload: addMultipleVehicleCompartments,
    },
    update: {
        downloadSampleCsvFromApiCall: true,
        downloadSampleCsv: generateSampleVehicleCompartmentExcelForBulkAction,
        title: 'Update Vehicle Compartments',
        onBulkUpload: updateMultipleVehicleCompartments,
    },
    params: VEHICLE_COMPARTMENT_MASTER_PARAMS,
    modalHeading: 'Vehicle Compartment Master',
    warningMsg: 'No Compartments to upload',
    successMsg: 'Vehicle Compartments Uploaded Successfully',
    updateMsg: 'Vehicle Compartments Updated Successfully',
    failureArrayColumns: [
        {
            key: 'code',
            dataIndex: 'code',
            title: 'Compartment Code',
        },
        {
            key: 'error',
            dataIndex: 'error',
            title: 'Error',
        },
    ],
    bulkUploadMapping: null,
    sectionWiseForm: true,
    sectionLayout: 'horizontal',
    formItemColSpan: 16,
    firstRowAsHeader: true,
    formItemLayout: {
        labelCol: { span: 10 },
        wrapperCol: { span: 14 },
    },
    onSingleAdd: addSingleVehicleCompartment,
    onSingleUpdate: updateSingleVehicleCompartment,
    id: 'code',
    useDrawer: true,
    drawerWidth: '40%',
};

const buckets = {
    vehicleCompartmentMaster: {
        name: 'Vehicle Compartment Master',
        filters: [],
        actions: [],
    },
};

const bucketColor = {};

export default { viewControl, buckets, bucketColor };
