import { morphism } from 'morphism';
import { API_BASE_URL } from '@src/globals';
import { POST, GET } from '@api/apiHandler';
import {
    FETCH_VEHICLE_COMPARTMENT,
    BULK_CREATE_VEHILCE_COMPARTMENT,
    BULK_UPDATE_VEHILCE_COMPARTMENT,
    GENERATE_OBJECT_BUILDER_EXCEL,
    DOWNLOAD_VEHICLE_COMPARTMENT,
} from '@api/routes';
import { getAuthenticationHeaders } from './utils';
import {
    VEHICLE_COMPARTMENT_MASTER_OBJECT_TYPE,
    VEHICLE_COMPARTMENT_MASTER_PARAMS,
} from 'src/components/pages/OpsDashboard/Setup/TransportModule/VehicleCompartment/VehicleCompartmentMaster.constants';

import {
    requestModelVehicleCompartmentFetch,
    responseModelVehicleCompartmentFetch,
    responseModelSingleVehicleCompartmentManage,
} from '@model/consignmentView';

export const fetchAllVehicleCompartments = async(params = {}) => {
    const body = {
      ...morphism(requestModelVehicleCompartmentFetch, params),
    };

    const response = await POST(`${API_BASE_URL}${FETCH_VEHICLE_COMPARTMENT}`, body);

    return {
      ...morphism(responseModelVehicleCompartmentFetch, response),
    };
};

export const addMultipleVehicleCompartments = async(body) => {
    const response = await POST(`${API_BASE_URL}${BULK_CREATE_VEHILCE_COMPARTMENT}`,body);
    return {
        ...morphism(responseModelSingleVehicleCompartmentManage, response),
    };
};

export const updateMultipleVehicleCompartments = async(body) => {
    const response = await POST(`${API_BASE_URL}${BULK_UPDATE_VEHILCE_COMPARTMENT}`,body);
    return {
        ...morphism(responseModelSingleVehicleCompartmentManage, response),
    };
};

export const generateSampleVehicleCompartmentExcelForBulkAction = async () => {
    const body = {
        objectType: VEHICLE_COMPARTMENT_MASTER_OBJECT_TYPE,
    };
    return POST(`${API_BASE_URL}${GENERATE_OBJECT_BUILDER_EXCEL}`,body, await getAuthenticationHeaders() , true);
};

export const addSingleVehicleCompartment = async (body) => {
    const parsedInput = {
        [VEHICLE_COMPARTMENT_MASTER_PARAMS]: [body],
    };
    const response = await POST(`${API_BASE_URL}${BULK_CREATE_VEHILCE_COMPARTMENT}`,parsedInput);
    return {
        ...morphism(responseModelSingleVehicleCompartmentManage, response),
    };
};

export const updateSingleVehicleCompartment = async (body) => {
    const parsedInput = {
        [VEHICLE_COMPARTMENT_MASTER_PARAMS]: [body],
    };
    const response = await POST(`${API_BASE_URL}${BULK_UPDATE_VEHILCE_COMPARTMENT}`,parsedInput);
    return {
        ...morphism(responseModelSingleVehicleCompartmentManage, response),
    };
};

export const downloadVehicleCompartments = async (body) => {
    return GET(`${API_BASE_URL}${DOWNLOAD_VEHICLE_COMPARTMENT}`,body);
};
