import apiProvider from '../provider';
import {
  getAuthenticationHeaders,
  getErrorMessage,
  getErrorFailures,
  getErrorMessageForBlob,
} from '../utils';
import { API_BASE_URL } from '../../globals';
import {
  FETCH_TMS_MASTERS, ASSIGN_DOCK_TMS,
  FETCH_TMS_ROUTES_ORIGIN_HUB,
  FETCH_TMS_VEHICLES, DOCK_INSCAN_TMS, DOCK_OUTSCAN_TMS, GATE_IN_TMS, GATE_OUT_TMS,
  SEARCH_VEHICLES_TMS, IST_RECEIVE_TMS,
  IST_DEPART_TMS, IST_CLOSE_TMS, FETCH_TRANSPORTERS_TMS, GET_CHART_DATA_TMS,
  APPLY_EXCEPTION_CODE_VEHICLE_TMS, SEARCH_EXCEPTION_CODES_VEHICLE, SEARCH_EXCEPTION_CODES_BAG,
  PRINT_MANIFEST_TMS, PRINT_TRIP_MANIFEST, REASSIGN_VEHICLE_TMS, FETCH_VEHICLE_MAKE_LIST ,
  UPDATE_SELECTIVE_FIELDS, MARK_VEHICLE_BREAKDOWN, DOWNLOAD_EXCEPTION_MASTER,
  BULK_UPDATE_IST_DETAILS, FETCH_IST_CONSIGNMENT_EXCEPTIONS,
  FETCH_TRIP_CONSIGNMENT_EXCEPTIONS, PRINT_MANIFEST_ASYNC_TMS,
} from '../routes';
import {requestOverviewSchema} from '../../model/tmsView';
import { morphism } from 'morphism';
import { POST, GET } from '../apiHandler';
export const fetchTMSMasters = async (hubId) => {
  try {
    const response = await apiProvider.get(
      `${API_BASE_URL}${FETCH_TMS_MASTERS}`,
      {
        params: {
          hub_id: hubId,
        },
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const fetchRoutesOriginHub = async (body) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${FETCH_TMS_ROUTES_ORIGIN_HUB}`,
      body,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const fetchTMSVehicleByHub = async (hubId, action) => {
  try {
    const response = await apiProvider.get(
      `${API_BASE_URL}${FETCH_TMS_VEHICLES}`,
      {
        params: {
          action,
          hub_id: hubId,
        },
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};
export const onDockAssign = async(body) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${ASSIGN_DOCK_TMS}`,
      body,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};
export const onDockInscan = async(body) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${DOCK_INSCAN_TMS}`,
      body,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const onDockOutscan = async(body) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${DOCK_OUTSCAN_TMS}`,
      body,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const onGateIn = async(body) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${GATE_IN_TMS}`,
      body,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};
export const onGateOut = async(body) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${GATE_OUT_TMS}`,
      body,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const tmsVehicleSearch = async (queryString, action, hubId) => {
  try {
    const response = await apiProvider.get(
      `${API_BASE_URL}${SEARCH_VEHICLES_TMS}`,
      {
        params: {
          action,
          vehicle_number: queryString,
          hub_id: hubId,
        },
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const onIstReceive =  async(body) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${IST_RECEIVE_TMS}`,
      body,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const onIstDepart =  async(body) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${IST_DEPART_TMS}`,
      body,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    const errorFailures = getErrorFailures(err);
    return {
      errorMessage,
      errorFailures,
      isSuccess: false,
    };
  }
};

export const onIstClose = async(body) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${IST_CLOSE_TMS}`,
      body,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const fetchTMSTransporters = async(queryString?: string) => {
  try {
    const response = await apiProvider.get(
      `${API_BASE_URL}${FETCH_TRANSPORTERS_TMS}`,
      {
        params: {
          transporter_name: queryString,
        },
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};
export const fetchChartsDataTMS = async (
  body,
) => {
  try {
    const response =  await apiProvider.post(
      `${API_BASE_URL}${GET_CHART_DATA_TMS}`, { ...morphism(requestOverviewSchema, body) },
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};
export const onApplyExceptionVehicle = async(body) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${APPLY_EXCEPTION_CODE_VEHICLE_TMS}`,
      body,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};
export const tmsVehicleExceptionSearch = async (queryString) => {
  try {
    const response = await apiProvider.get(
      `${API_BASE_URL}${SEARCH_EXCEPTION_CODES_VEHICLE}`,
      {
        params: {
          exception_code: queryString,
        },
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};
export const tmsBagExceptionSearch = async (queryString) => {
  try {
    const response = await apiProvider.get(
      `${API_BASE_URL}${SEARCH_EXCEPTION_CODES_BAG}`,
      {
        params: {
          exception_code: queryString,
        },
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};
export const printManifestAsyncTMS = async (body) => {
  return POST(`${API_BASE_URL}${PRINT_MANIFEST_ASYNC_TMS}`,body);
};

export const printManifestTMS = async(vehicleId) => {
  try {
    const response = await apiProvider.get(
      `${API_BASE_URL}${PRINT_MANIFEST_TMS}`,
      {
        params: {
          vehicle_id: vehicleId,
        },
        headers: await getAuthenticationHeaders(),
        responseType:'blob',
      },
    );
    return {
      fileName : response.headers['Content-Disposition'],
      data: response.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = await getErrorMessageForBlob(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};
export const printTripManifestTMS = async(body) => {
  return POST(`${API_BASE_URL}${PRINT_TRIP_MANIFEST}`, body, undefined, true);
};
export const reAssignVehicleTMS = async(body) => {
  return POST(`${API_BASE_URL}${REASSIGN_VEHICLE_TMS}`, body);
};
export const fetchVehicleMakeList = async(params) => {
  return GET(`${API_BASE_URL}${FETCH_VEHICLE_MAKE_LIST}`, params);
};
export const updateSelectiveFields = async(body) => {
  return POST(`${API_BASE_URL}${UPDATE_SELECTIVE_FIELDS}`, body);
};

export const bulkUpdateIstDetails = async(body) => {
  return POST(`${API_BASE_URL}${BULK_UPDATE_IST_DETAILS}`, body);
};

export const fetchIstConsignmentExceptions = async(params) => {
  return GET(`${API_BASE_URL}${FETCH_IST_CONSIGNMENT_EXCEPTIONS}`, params);
};

export const fetchTripConsignmentExceptions = async(params) => {
  return GET(`${API_BASE_URL}${FETCH_TRIP_CONSIGNMENT_EXCEPTIONS}`, params);
};

export const markVehicleBreakdown = async (body) => {
  return POST(`${API_BASE_URL}${MARK_VEHICLE_BREAKDOWN}`, body);
};

export const downloadExceptionMaster = async(params) => {
  return GET(`${API_BASE_URL}${DOWNLOAD_EXCEPTION_MASTER}/${params.type}/exceptions.csv`, params);
};
