import apiProvider from './provider';
import { getErrorMessage } from './utils';
import { API_BASE_URL } from '../globals';
import {
  GET_BANK_DEPOSIT_HUB_BALANCE,
  GET_BANK_DEPOSIT_REPORT,
  CREATE_BANK_TRANSACTION,
  GET_BANK_DEPOSIT_S3_URL,
  GET_REMITTANCE_DATA,
  GET_REMITTANCE_DATA_V1,
  GET_CONSIGNMENTS_FOR_REMITTANCE,
  LOG_REMITTANCE,
  SETTLE_REMITTANCE,
  DOWNLOAD_REMITTANCE_SAMPLE_CSV,
  DOWNLOAD_REMITTANCE_CN_DATA,
  FETCH_REMITTANCE_DETAILS_DATA,
  FETCH_BM_DASHBOARD_DATA,
  DOWNLOAD_EMPLOYEE_LEVEL_REPORT,
  DOWNLOAD_CN_LEVEL_REPORT,
  GET_BALANCE_FOR_CMS,
  CREATE_CMS_TRANSACTION,
  GET_BANK_DEPOSIT_REPORT_CSV,
  UPDATE_BANK_TRANSANCTION,
  GET_EMPLOYEE_IDS,
  DOWNLOAD_HUB_UNSETTLED_CN_REPORT,
  GET_SAMPLE_CN_REFERENCE_NUMBER_LIST_CSV,
} from './routes';
import { GET, POST } from './apiHandler';
import { morphism } from 'morphism';
import {
  requestModelBankHistory,
  requestModelRemittance,
  requestModelBmDashboard,
  requestModelCarrierHistory,
} from '../model/genericTransactionView';

export const getHubBalance = async (body) => {
  return POST(`${API_BASE_URL}${GET_BANK_DEPOSIT_HUB_BALANCE}`, body);
};

export const getBalanceForCMS = async (params) => {
  return GET(`${API_BASE_URL}${GET_BALANCE_FOR_CMS}`, params);
};

export const getDepositReport = async (body) => {
  const params = {
    ...morphism(requestModelBankHistory, body),
  };
  return POST(`${API_BASE_URL}${GET_BANK_DEPOSIT_REPORT}`, params);
};

export const downloadDepositReportCSV = async (body) => {
  const params = {
    ...morphism(requestModelBankHistory, body),
  };
  return POST(`${API_BASE_URL}${GET_BANK_DEPOSIT_REPORT_CSV}`, params);
};

export const createTransaction = async (body) => {
  return POST(`${API_BASE_URL}${CREATE_BANK_TRANSACTION}`, body);
};

export const createCmsTransaction = async (body) => {
  return POST(`${API_BASE_URL}${CREATE_CMS_TRANSACTION}`, body);
};

export const getTransactionUrl = async (params) => {
  return GET(`${API_BASE_URL}${GET_BANK_DEPOSIT_S3_URL}`, params);
};

export const putImageS3 = async (url, file, content_type) => {
  try {
    const response = await apiProvider.put(url, file, {
      headers: {
        'Content-Type': content_type,
      },
    });
    return {
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const getRemittanceData = async (body) => {
  const params = {
    ...morphism(requestModelRemittance, body),
  };
  return POST(`${API_BASE_URL}${GET_REMITTANCE_DATA_V1}`, params);
};

export const getRemittanceLogsData = async (body) => {
  return POST(`${API_BASE_URL}${GET_REMITTANCE_DATA}`, body);
};

export const getConsignmentsForRemittance = async (params) => {
  return POST(`${API_BASE_URL}${GET_CONSIGNMENTS_FOR_REMITTANCE}`, params);
};

export const logRemittance = async (body) => {
  return POST(`${API_BASE_URL}${LOG_REMITTANCE}`, body);
};

export const settleRemittance = async (body) => {
  return POST(`${API_BASE_URL}${SETTLE_REMITTANCE}`, body);
};

export const getSampleCSVRemittance = async () => {
  return GET(`${API_BASE_URL}${DOWNLOAD_REMITTANCE_SAMPLE_CSV}`);
};

export const getRemittanceCnData = async (params) => {
  return GET(`${API_BASE_URL}${DOWNLOAD_REMITTANCE_CN_DATA}`, params);
};

export const fetchRemittanceDetailsData = async (params) => {
  return GET(`${API_BASE_URL}${FETCH_REMITTANCE_DETAILS_DATA}`, params);
};

export const getBMDashboardData = async (body) => {
  const params = {
    ...morphism(requestModelBmDashboard, body),
  };
  return POST(`${API_BASE_URL}${FETCH_BM_DASHBOARD_DATA}`, params);
};
export const downloadEmployeeLevelReport = async (params) => {
  return GET(`${API_BASE_URL}${DOWNLOAD_EMPLOYEE_LEVEL_REPORT}`, params);
};

export const downloadCNLevelReport = async (params) => {
  return GET(`${API_BASE_URL}${DOWNLOAD_CN_LEVEL_REPORT}`, params);
};

export const getCarrierReport = async (body) => {
  const params = {
    ...morphism(requestModelCarrierHistory, body),
  };
  return POST(`${API_BASE_URL}${GET_BANK_DEPOSIT_REPORT}`, params);
};

export const updateTransaction = async (body) => {
  return POST(`${API_BASE_URL}${UPDATE_BANK_TRANSANCTION}`, body);
};

export const getEmployeeIds = (queryString) => {
    return GET(`${API_BASE_URL}${GET_EMPLOYEE_IDS}?q=${queryString}`);
};

export const getHubUnsettledCNReport = async (params) => {
  return GET(`${API_BASE_URL}${DOWNLOAD_HUB_UNSETTLED_CN_REPORT}`, params);
};

export const getCNReferenceNumberSampleCsv = async () => {
  return GET(`${API_BASE_URL}${GET_SAMPLE_CN_REFERENCE_NUMBER_LIST_CSV}`);
};
