import React from 'react';
import { CssType, ThemeType} from '../../theming/jssTypes';
import { Tabs } from 'antd';
import withStyles, { WithStylesProps } from 'react-jss';
import { EllipsisOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const { TabPane } = Tabs;

const styles = (theme: ThemeType): CssType => ({
  tabsContainer: {
    height: '48px',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    backgroundColor: `${theme.colors.surfaceBg} !important`,
    margin: '8px',
    '& .ant-tabs-nav': {
      height: '100%',
    },
    '& .ant-tabs-nav::before': {
      borderBottom: 'none',
    },
    '& .ant-tabs-bar': {
      margin: '0px',
    },
    '& .ant-tabs-tab:first-child': {
      marginLeft: '3px !important',
    },
    '& .ant-tabs-tab': {
      maxWidth:'200px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      padding: '0 !important',
      fontSize: theme.sizes.bodyText,
      lineHeight: '14px',
      fontWeight: '400',
      background: `${theme.colors.surfaceBg} !important`,
      marginLeft: '0px !important',
      marginTop: '4px !important',
      borderTopLeftRadius: '4px !important',
      borderTopRightRadius: '4px !important',
      border: '0 !important',
      '& .ant-tabs-tab-btn': {
        display:'flex',
        justifyContent:'space-between',
        alignItems: 'center',
        flexDirection: 'row',
        color: theme.colors.textOnLightBg,
        padding: '0 24px',
      },
      '& $metricValue': {
        color: `${theme.colors.textOnDarkBg} !important`,
        backgroundColor: `${theme.colors.tooltipFill}99`, // Adding '99' for 60% transparency
      },
    },
    '& .ant-tabs-tab:hover': {
      backgroundColor: `${theme.colors.HoverOnWhiteBg} !important`,
      '& .ant-tabs-tab-btn': {
        color: `${theme.colors.primaryColor} !important`,
      },

      '& $metricValue': {
        color: `${theme.colors.textOnDarkBg} !important`,
        backgroundColor: `${theme.colors.primaryColor}B2 !important`,
      },
    },
    '& .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab' : {
      marginRight: 0,
    },
    '& .ant-tabs-content': {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      height: '0',
    },
    '& .ant-tabs-tabpane-inactive': {
      borderBottom: 'none',
    },
    '& .ant-tabs-tab-active': {
      backgroundColor: `${theme.colors.surfaceBg} !important`,
      borderBottom: `4px solid ${theme.colors.secondaryColor} !important`,

      '& .ant-tabs-tab-btn': {
        color: `${theme.colors.primaryColor} !important`,
        fontWeight: '600',
      },

      '& $metricValue': {
        color: `${theme.colors.textOnDarkBg} !important`,
        backgroundColor: `${theme.colors.primaryColor} !important`,
      },
    },
    '& .ant-tabs-tab-active:hover': {
      backgroundColor: `${theme.colors.HoverOnWhiteBg} !important`,

      '& $metricValue': {
        color: `${theme.colors.textOnDarkBg} !important`,
        backgroundColor: `${theme.colors.primaryColor} !important`,
      },
    },
  },
  tabPane : {
    border: 'none !important',
    height: '0',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
  },
  metricValue: {
    fontSize: theme.sizes.bodyText,
    lineHeight: '12px',
    fontWeight: '600',
    color: theme.colors.textOnLightBg,
    backgroundColor: theme.colors.surfaceBg,
    padding: '4px 8px',
    borderRadius: '11px',
    marginLeft: '8px',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    flex: 1,
  },
  tabDataContainer: {
    height: 'calc(100% - 48px)',
    display: 'flex',
    flexDirection: 'column',
  },
  setFontSizeBodyText: {
    fontSize: theme.sizes.bodyText,
  }
});

export interface Tab {
  header: {
    title: string;
    metric?: string;
    customMetricNode?: React.ReactNode;
    icon?: React.ReactNode;
  };
  key: string;
  Component: React.ReactNode;
}

interface GenericTabsProps extends WithStylesProps<ReturnType<typeof styles>> {
  tabs: Tab[];
  defaultActiveKey: string | null;
  onChange: ((key: string) => void) | null;
  [key: string]: any;
  activeTab: any;
}

type Props = GenericTabsProps & RouteComponentProps;

const GenericTabsWithSummaryMetric: React.FunctionComponent<Props> = ({
  tabs = [],
  defaultActiveKey,
  onChange,
  classes,
  destroyInactiveTabPane,
  activeTab: activeTabFromProps,
  match,
  staticContext,
  mode,
  type,
  ...props
}) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = React.useState<string | null>(
    activeTabFromProps || defaultActiveKey || tabs[0]?.key);

  const renderMetric = (metric?: string) => {
    const numericMetric = metric ? parseInt(metric, 10) : undefined;
    const finalMetric = numericMetric && numericMetric > 9999 ? '9999+' : metric;
    return metric ? <span
      className={classNames(classes.metricValue)}
    >
      {t(finalMetric)}
    </span> : <></>;
  };

  React.useEffect(() => {
    const newTab = activeTabFromProps || defaultActiveKey || tabs[0]?.key;
    if (newTab !== activeTab) {
      setActiveTab(newTab);
    }
  }, [activeTabFromProps]);

  const renderTabHeader = (header: Tab['header']) => {
    const { title, customMetricNode, metric, icon } = header;
    return (
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}>
        {icon ? <span style={{ marginRight: '10px' }}>{icon}</span> : <></>}
        {t(title)}
        {customMetricNode || renderMetric(metric)}
      </div>
    );
  };

  const renderActiveComponent = () => {
    const pathname = match?.path ?? window.location.pathname;
    const activeTabData = tabs.find(tab => tab.key === activeTab);
    const id = `__tab-generic-shipsy-${pathname}:${activeTab}`;
    return <div
      className={classNames(classes.tabDataContainer, 'shipsy-generic-tabs-with-sm-cls')}
      id={id}>
      {activeTabData?.Component ?? <></>}
    </div>;
    return <></>;
  };

  const handleChange = (key) => {
    setActiveTab(key);
    if (onChange) {
      onChange(key);
    }
  };

  return (
    <div className={classes.container}>
    <Tabs
      activeKey={activeTab}
      moreIcon={<EllipsisOutlined className={classes.setFontSizeBodyText}/>}
      type="card"
      onChange={handleChange}
      className={classes.tabsContainer}
      tabBarStyle={{ paddingRight: '5px'}}
      {...props}
    >
      {tabs.map(({ header, key }, index) => {
        return (
          <TabPane
            className={classNames(classes.tabPane)}
            tab={renderTabHeader(header)} key={key || index + 1}
            disabled={tabs[index]?.disabled}
            >
            <></>
          </TabPane>
        );
      })}
    </Tabs>
    {renderActiveComponent()}
    </div>
  );
};

export default withRouter(
  withStyles(styles, { injectTheme: true })(GenericTabsWithSummaryMetric),
);
