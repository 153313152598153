import {
    fetchAllAddressRegisterList,
    downloadAddressRegister,
    downloadSampleCsvForCreate,
    downloadSampleCsvForUpdate,
    uploadCsvForCreate,
    uploadCsvForUpdate,
    createAddressRegister,
    updateAddressRegister,
    getAddressDetail,
} from '@api/addressRegister';

import {
    csvKeyMapping,
    formFieldList,
} from '@components/pages/OpsDashboard/Setup/AddressRegisterModule/utils';

const getSingleUploadFields = (masterdata) => {
    const masterFieldsList = masterdata?.location_master_config?.fields_list || [];
    const fieldsListToShow = [];
    for (const section of formFieldList) {
        const obj = {};
        obj['id'] = section.id;
        obj['name'] = section.name;
        obj['fields_list'] = [];
        const fieldList = section?.fields_list || [];
        for (const field of fieldList) {
            const foundField = masterFieldsList.find((masterField) => masterField.key === field.id);
            if (foundField) {
                field['isMandatory'] = !foundField.is_optional;
                obj['fields_list'].push(field);
            }
        }
        fieldsListToShow.push(obj);
    }
    return fieldsListToShow;
};

const getKeys = (masterdata) => {
    const masterFieldsList = masterdata?.location_master_config?.fields_list || [];
    return masterFieldsList?.map((field) => field.key);
}

const viewControl = {
    pageTitle: 'Setup',
    buckets: ['addressRegister'],
    globalActions: [],
    filters: [
        'address_id', 'verification_status', 'city',
    ],
    actions: [
        {
            key: 'download',
            name: 'Download',
        },
        {
            key: 'update',
            name: 'Bulk Update',
        },
        {
            key: 'addOptions',
            name: 'Add New',
        },
        {
            key: 'pagination',
            name: 'Pagination',
        },
        {
            key: 'perPageCountChange',
            name: 'Pagination Count',
        },
        {
            key: 'reload',
            name: 'Reload',
        },
    ],
    addOptions: [
        {
            key: 'add_single',
            title: 'Single',
            action: 'add_single',
            type: 'single',
        },
        {
            key: 'add_bulk',
            title: 'Bulk',
            action: 'add_bulk',
            type: 'bulk',
        },
    ],
    fetchData: fetchAllAddressRegisterList,
    downloadData: downloadAddressRegister,
    add_bulk: {
        sampleUrl: undefined,
        downloadSampleCsvFromApiCall: true,
        downloadSampleCsv: downloadSampleCsvForCreate,
        title: 'Add Address',
        onBulkUpload: uploadCsvForCreate,
    },
    update: {
        sampleUrl: undefined,
        downloadSampleCsvFromApiCall: true,
        downloadSampleCsv: downloadSampleCsvForUpdate,
        title: 'Update Address',
        onBulkUpload: uploadCsvForUpdate,
    },
    params: 'address_array',
    modalHeading: 'Upload Address',
    warningMsg: 'No Address to upload',
    successMsg: 'Uploaded Successfully',
    updateMsg: 'Updated Successfully',
    failureArrayColumns: [
        {
            key: 'row_num',
            dataIndex: 'row_num',
            title: 'S No',
        },
        {
            key: 'message',
            dataIndex: 'message',
            title: 'Reason',
        },
    ],
    bulkUploadMapping: csvKeyMapping,
    sectionWiseForm: true,
    sectionLayout: 'horizontal',
    singleUploadSectionWiseField: getSingleUploadFields,
    s3FolderDocumentTypeMapping: {
        location_image: 'location_image',
    },
    onSingleAdd: createAddressRegister,
    onSingleUpdate: updateAddressRegister,
    id: 'address_id',
    getAddressDetail,
    getFormKeys: getKeys,
};

const buckets = {
    addressRegister: {
        name: 'Address Register',
        filters: [],
        actions: [],
    },
};

const bucketColor = {};

export default { viewControl, buckets, bucketColor };
