export const VEHICLE_COMPARTMENT_MASTER_OBJECT_TYPE = 'VEHICLE_COMPARTMENT';

export const VEHICLE_COMPARTMENT_MASTER_ACTION_MAP = {
    enable: 'enable',
    disable: 'disable',
};

export const VEHICLE_COMPARTMENT_MASTER_PARAMS = 'compartment_array';

export const DEFAULT_ACTIONS = [
    {
        key: 'pagination',
        name: 'Pagination',
    },
    {
        key: 'perPageCountChange',
        name: 'Pagination Count',
    },
    {
        key: 'reload',
        name: 'Reload',
    },
];
