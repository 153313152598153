import { Input, message, Modal, Upload, Button } from 'antd';
import { putImageS3 } from '@api/TMSDashboard/Setup';
import { UploadChangeParam } from 'antd/lib/upload';
import * as React from 'react';
import { connect } from 'react-redux';
import { generateMultipleS3ImageUrl, settleClaimRequest } from '../../../api/dashboard';
import withStyles from 'react-jss';
import {
    claimSettleFields,
    formItemLayout,
    uploadProps,
    filterKeysSettle,
} from './masterdata';
import { Form } from '@ant-design/compatible';
import {
    CssType,
    ThemeType,
} from '../../../theming/jssTypes';

const FormItem = Form.Item;

const styles = (theme: ThemeType): CssType => ({
    hintHeader: {
        fontSize: '12px',
        fontWeight: 600,
        color: '#262626',
    },
    formItemLayout: {
        '& .ant-form-item-label': {
            lineHeight: 1,
        },
        margin: 0,
    },
});

const ClaimSettleFormModal = (props: any) => {
    const {
        title,
        handleModalClose,
        isVisible,
        form,
        claimRequestData = {},
    } = props;
    const { getFieldDecorator, getFieldsValue } = form;
    const [fileList, setFileList] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    const handleSubmit = async () => {
        setLoading(true);
        const fieldValues = getFieldsValue();
        const body = {
            settlement_supporting_docs_urls: [],
            id: claimRequestData.id,
        };
        filterKeysSettle.forEach((key) => {
            if (fieldValues[key]) {
                body[key] = fieldValues[key];
            }
        });
        if (fileList?.length) {
            try {
                const response = await generateMultipleS3ImageUrl({
                    urlCount: fileList.length,
                    fileType: fileList[0].type,
                });
                if (!response || response.errorMessage || !response.data ||
                    !Array.isArray(response.data) || !response.data.length) {
                    message.error('Could Not Process Request. Please try again later!');
                    return;
                }
                const urlList = response.data;
                const imageUploads = fileList?.map(async (image, index) => {
                    const signedImageUrl = urlList[index].imageSignedUrl;
                    const file = new Blob([image.originFileObj as BlobPart], {
                        type: image.type,
                    });
                    await putImageS3(signedImageUrl, file, image.type);
                });
                await Promise.all(imageUploads);
                body.settlement_supporting_docs_urls = urlList.map((url) => url.imageUrl);
            }
            catch (err) {
                message.error('Could Not Process Request. Please try again later!');
                return;
            }
        }
        const response = await settleClaimRequest({
            request_list: [body],
            type: 'claim',
            action_type: 'settle',
            object_type: 'consignment',
        });
        if (!response || response.errorMessage) {
            message.error('Could Not Process Request. Please try again later!');
            return;
        }
        message.success('Claim Request Settled Successfully');
        handleModalClose(false);
        setLoading(false);
    };

    const handleFileChange = (info: UploadChangeParam) => {
        setFileList(info.fileList);
    };

    const generateFields = (elem) => {
        switch (elem.type) {
            case 'input':
                switch (elem.subtype) {
                    case 'text':
                        return <Input.TextArea
                            rows={2}
                            disabled={elem.disabled}
                        />;
                    default:
                        return <Input disabled={elem.disabled} />;
                }
            case 'upload_image':
                return (
                    <Upload
                        fileList={fileList}
                        onChange={handleFileChange}
                        {...uploadProps}
                    >
                        <Button>
                            Upload
                        </Button>
                    </Upload>
                );
            default:
                return '';
        }
    };

    return (
        <Modal
            width={600}
            title={title}
            confirmLoading={loading}
            visible={isVisible}
            onOk={handleSubmit}
            okText={'Submit'}
            onCancel={() => handleModalClose(false)}
        >
            <Form
                layout="vertical"
            >
                {claimSettleFields.map((elem) => (
                    elem.isVisible ? (
                        <FormItem
                            label={elem.label}
                            {...formItemLayout}
                        >
                            {getFieldDecorator(elem.id, {
                                rules: [{
                                    required: elem.required,
                                    message: elem.message,
                                }],
                            })(generateFields(elem))}
                        </FormItem>
                    ) : null))}
            </Form>
        </Modal>
    );
};
const mapStateToProps = ({ masterData }, ownProps) => {
    return {};
};
export default connect(mapStateToProps)(withStyles(styles,
    { injectTheme: true })(Form.create()(ClaimSettleFormModal) as React.ComponentType<any>));
