import * as React from 'react';
import withStyles from 'react-jss';

import GalleryModalWithSignedUrl from '@components/common/GalleryModalWithSignedUrl';
import HeaderCard from './HeaderCard';
import { Table, message, Spin, Empty } from 'antd';
import { useTranslation } from 'react-i18next';
import { CssType, ThemeType } from '@theming/jssTypes';

import { fetchConsignmentQc } from '@src/api/dashboard';

const styles = (theme: ThemeType): CssType => ({
  qcTabsContainer: {
    overflowY: 'auto',
    height: '100%',
    padding: '0px',
  },
  qcObjectContainer: {
    marginBottom: '16px',
  },
  qcTable: {
    borderRadius: '4px',
    marginTop: '0.5rem',
    marginLeft: '1rem',
    marginRight: '1rem',
    '& .ant-table-thead > tr > th': {
      backgroundColor: theme.colors.tableHeader,
      color: theme.colors.primaryText,
      fontWeight: '600',
      fontSize: theme.sizes.bodyText,
      paddingBottom: '10px',
      paddingTop: '10px',
    },
  },
  qcTableCell: {
    fontSize: theme.sizes.bodyText,
    fontWeight: '500',
    color: theme.colors.primaryText,
    paddingBottom: '0px',
    paddingTop: '1px',
  },
  centeredContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
});

const QcDetails = (props: any) => {
  const { classes, referenceNumber, piecesReferenceNumber = [], qcHeaders = [], qcTableColumns = [], piecesToRecon = false } = props;

  const { t } = useTranslation();

  const [qcData, setQcData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    fetchQcData(referenceNumber);
  }, [referenceNumber]);

  const fetchQcData = async (referenceNumber) => {
    if (!referenceNumber) {
      setQcData([]);
      return;
    }
    setIsLoading(true);
    try {
      const response = await fetchConsignmentQc({ referenceNumber, piecesReferenceNumber, piecesToRecon });
      if (response?.isSuccess && response?.data) {
        setQcData(response.data);
      } else {
        message.error(response?.errorMessage || t('Could not fetch QC data'));
      }
    } catch (err) {
      message.error(t('An error occurred while fetching QC data'));
    } finally {
      setIsLoading(false);
    }
  };

  const getColumnsForTable = (columnsToShow) => {
    const widthMap = {
      sequence_number: '10%',
    };
    const columns = columnsToShow?.map((col) => {
      return (
        col && {
          dataIndex: col.id,
          title: t(col.pretty_name),
          width: widthMap[col.id] ?? 80,
          render: (x, record, index) => {
            switch (col.key) {
              case 's_no':
                return <div>{index + 1}</div>;
              case 'qc_images_captured_by_the_rider':
                const imagesCaptured = record?.images_captured_by_the_rider || [];
                if (!imagesCaptured.length) return '';
                return (
                  <GalleryModalWithSignedUrl
                    imageArray={imagesCaptured}
                    toFetchSignedUrl={true}
                  />
                );
              case 'qc_reference_images':
                const referenceImages = record?.reference_images || [];
                if (!referenceImages.length) return '';
                return (
                  <GalleryModalWithSignedUrl
                    imageArray={referenceImages}
                    toFetchSignedUrl={false}
                  />
                );
              default:
                return t(x);
            }
          },
        }
      );
    });
    return columns.filter((column) => column);
  };

  return (
    <>
      {isLoading ? (
        <div className={classes.centeredContainer}>
          <Spin tip="Loading..." />
        </div>
      ) : !qcData || qcData.length === 0 ? (
        <div className={classes.centeredContainer}>
          <Empty description="No data found" />
        </div>
      ) : (
        <div className={classes.qcTabsContainer}>
          {qcData.map((qcObject, index) => (
            <div className={classes.qcObjectContainer} key={index}>
              <HeaderCard
                columns={qcHeaders.filter((c) => qcObject[c.id] != null)}
                data={qcObject}
              />
              <Table
                className={classes.qcTable}
                rowClassName={() => classes.qcTableCell}
                locale={{ emptyText: t('No data found') }}
                dataSource={qcObject.qc_details}
                pagination={false}
                columns={getColumnsForTable(qcTableColumns)}
                scroll={{ x: 'max-content' }}
                tableLayout="auto"
              />
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default withStyles(styles, { injectTheme: true })(QcDetails);
