import domesticCNViewControls from './domesticCN';
import crmCNViewControls from './crmCN';
import retailTripsViewControls from './retailTrips';
import retailUpCountryCN from './retailUpCountryCN';
import retailPlanningCN from './retailPlanningCN';
import retailTripsPrimary from './retailTripsPrimary';
import retailTripsSecondary from './retailTripsSecondary';
import ondemandViewControls from './ondemand';
import summaryMetricsViewControls from './summaryMetricsCN';
import retailStoreCN from './retailStoreCN';
import supportPortalViewControls from './supportPortalCN';
import thirdPartyViewControls from './thirdPartyCN';
import { ValletTransactionsViewControls } from 'src/components/pages/ValletTransactions/utils';
import tmsVehicleControls from './tmsVehicleControls';
import tmsVehicleAttendanceControls from './tmsVehicleAttendanceControls';
import tmsISTControls from './tmsISTControls';
import tmsBagControls from './tmsBagControls';
import tmsSetupRouteControls from './tmsSetupRouteControls';
import tmsSetupConnectionControls from './tmsSetupConnectionControls';
import tmsSetupExceptionControls from './tmsSetupExceptionControls';
import tmsSetupBaggingControls from './tmsSetupBaggingControls';
import setupBookingMastersControls from './setupBookingMastersControls';
import setupRoutingControls from './setupRoutingControls';
import shelfControls from './shelfControls';
import crateControls from './crateControls';
import logisticsExpressCN from './logisticsExpressCN';
import consolidatedPlanningCN from './consolidatedPlanningCN';
import tmsSetupGateControls from './tmsSetupGateControls';
import tmsSetupDockControls from './tmsSetupDockControls';
import riderReconControls from './riderReconControls';
import approvalRequestsControls from './approvalRequestsControls';
import codSettlementControls from './codSettlementControls';
import ManifestDeclarationCN from './ManifestDeclarationCN';
import callsOverview from './callsOverview';
import myCalls from './myCalls';
import tmsSetupVehicleDocumentsControls from './tmsSetupVehicleDocumentsControls';
import reasonCategoryMasterControls from './reasonCategoryMasterControls';
import addAdhocChargesControls from './addAdhocChargesControls';
import ltlNDRMasterControls from './ltlNDRMasterControls';
import packagingTypeMasterControls from './packagingTypeMasterControls';
import partialDeliveryReasonControls from './partialDeliveryReasonControls';
import lateDeliveryReasonControls from './lateDeliveryReasonControls';
import CarrierChangeReasonMasterData from './CarrierChangeReasonMasterControls';
import setupCarrierMastersRevamp from './setupCarrierMastersRevamp';
import serviceTaskMappingControls from './serviceTaskMappingControls';
import tmsEwbThresholdMasterControls from './tmsEwbThresholdMasterControls';
import tripContractsMasterControls from './tripContractsMasterControls';
import appUserApprovalControls from './appUserApprovalControls';
import currencyConversionControls from './currencyConversionControls';
import supplyRequestControls from './supplyRequestControls';
import {
  MAP_EDIT_VIEW_TYPE,
  MapEditViewControls,
} from 'src/components/pages/retail/trip/TripManagerMapEdit/TripManagerMapEditUtils';
import addressRegisterControls from './addressRegisterControls';
import customerManageControls from './customerManageControls';
import supplementaryServicesControls from './supplementaryServicesControls';
import itemUnitMasterControls from './itemUnitMasterControls';
import vehicleCompartmentMasterControls from './vehicleCompartmentMasterControls';
import store from '@src/store/appStore';

const viewControlResolver = {
  retailTripsPrimary,
  retailTripsSecondary,
  crm: crmCNViewControls,
  logistics: crmCNViewControls,
  retailPlanning: retailPlanningCN,
  retailUpcountry: retailUpCountryCN,
  retailStore: retailStoreCN,
  domestic: domesticCNViewControls,
  retailTrips: retailTripsViewControls,
  ondemand: ondemandViewControls,
  on_demand_cod: codSettlementControls,
  metrics: summaryMetricsViewControls,
  supportPortal: supportPortalViewControls,
  thirdParty: thirdPartyViewControls,
  tms_vehicle: tmsVehicleControls,
  tms_vehicle_attendance: tmsVehicleAttendanceControls,
  tms_ist: tmsISTControls,
  tms_bag: tmsBagControls,
  tms_setup_route: tmsSetupRouteControls,
  tms_setup_connection: tmsSetupConnectionControls,
  tms_setup_exception: tmsSetupExceptionControls,
  tms_setup_bagging: tmsSetupBaggingControls,
  setup_booking_masters: setupBookingMastersControls,
  setup_courier_account_integrations: setupCarrierMastersRevamp,
  setup_routing: setupRoutingControls,
  shelf: shelfControls,
  crate: crateControls,
  logistics_express: logisticsExpressCN,
  manifest_declaration: ManifestDeclarationCN,
  consolidated_planning: consolidatedPlanningCN,
  tms_setup_gate: tmsSetupGateControls,
  tms_setup_dock: tmsSetupDockControls,
  tms_setup_vehicle_documents: tmsSetupVehicleDocumentsControls,
  rider_recon: riderReconControls,
  approval_requests: approvalRequestsControls,
  calls_overview: callsOverview,
  my_calls: myCalls,
  reasonCategoryMaster: reasonCategoryMasterControls,
  addAdhocCharges: addAdhocChargesControls,
  ltlNDRMaster: ltlNDRMasterControls,
  packagingTypeMaster: packagingTypeMasterControls,
  partialDeliveryReasons: partialDeliveryReasonControls,
  lateDeliveryReasons: lateDeliveryReasonControls,
  courierPartnerChangeReasons: CarrierChangeReasonMasterData,
  serviceTaskMapping: serviceTaskMappingControls,
  tms_setup_ewb_threshold_master: tmsEwbThresholdMasterControls,
  tripContracts: tripContractsMasterControls,
  appUserApproval: appUserApprovalControls,
  currencyConversion: currencyConversionControls,
  supply_request: supplyRequestControls,
  [MAP_EDIT_VIEW_TYPE]: MapEditViewControls,
  addressRegister: addressRegisterControls,
  customerManage: customerManageControls,
  supplementaryServices: supplementaryServicesControls,
  itemUnitMaster: itemUnitMasterControls,
  valletTransactions: ValletTransactionsViewControls,
  vehicleCompartmentMaster: vehicleCompartmentMasterControls,
};

export function getViewBuckets(viewType) {
  return viewControlResolver[viewType].buckets || [];
}

export function getViewControl(viewType) {
  return viewControlResolver[viewType].viewControl;
}

/**
 * Retrieves the view control with master data filter based on the given view type.
 * if bucketToShow is not present in the master data, then it will return the view control as it is.
 * @param {string} viewType - The type of the view.
 * @returns {object} - The view control object with the filtered buckets.
 */
export function getViewControlWithMasterDataFilter(viewType) {
  const viewControl = viewControlResolver[viewType].viewControl;
  const masterData = store?.getState().masterData || {};
  const bucketToShow = masterData?.view_config?.[viewType]?.bucket_to_show || [];
  if(bucketToShow.length && viewControl?.buckets) {
    viewControl.buckets = viewControl.buckets.filter(bucket => bucketToShow.includes(bucket));
  }
  return viewControl;
}

export function getViewBucketsWithFilter(viewType) {
  const buckets = viewControlResolver[viewType].buckets || [];
  const masterData = store?.getState().masterData || {};
  const bucketToShow = masterData?.view_config?.[viewType]?.bucket_to_show || [];
  if(!bucketToShow.length) {
    return buckets;
  }
  const filteredBuckets = {};
  for (const bucket of Object.keys(buckets)) {
    if (bucketToShow.includes(bucket)) {
      filteredBuckets[bucket] = buckets[bucket];
    }
  }
  return filteredBuckets;
}

export function getViewBucketColor(viewType) {
  return viewControlResolver[viewType].bucketColor;
}

export function getDefaultSubBuckets(viewType, masterData = {}) {
  if(typeof getViewControl(viewType).getSubBuckets === 'function') {
    const subBuckets = getViewControl(viewType).getSubBuckets(masterData) || [];
    return subBuckets[0]?.id;
  }
  return null;
}

export const enhanceGeolocationFeatureBuckets = () => {
  return [
    'All', 'Unplanned', 'Inscan at Hub', 'Inbound',
  ];
};

export default viewControlResolver;
