import {
  SHOW_DEV_TOOLS,
  HIDE_RETAIL_STORE_HUB_CHANGES,
  HIDE_RETAIL_CUSTOMER_SUPPORT_PORTAL,
  applicationType,
} from 'src/globals';
import { ANALYTICS_BASE_PATH } from '../../pages/queryBuilder/routePath';
import { API_PLAYGROUND_BASE_PATH } from '../persistentIframeRenderer/constants';
import {
  getPISSummaryRoute,
  getManagePISRoute,
} from '../../pages/PayinSlip/routes';
import {
  getFoodDashboardDispatchScreen,
  getFoodLiveTrackingMap,
  getFoodDashboardOrderManagement,
  getFoodDashboardRiderManagement,
  getFoodDashboardRosterManagement,
  getFoodDashboardCODCashSettlement,
  getFoodDashboardAnalytics,
  getFoodDashboardReporting,
  getOpsDashboardBaseRoute,
  getBankDepositRoute,
  getBankDepositReconcileRoute,
  getBankTransactionsHistoryRoute,
  getCashRemittanceRoute,
  getThirdPartyViewRoute,
  getTmsDashboardBaseRoute,
  getShelfBaseRoute,
  getCrateBaseRoute,
  getExpressDashboardBaseRoute,
  getFoodDashboardStoreConfiguration,
  getV1PagesBaseRoute,
  getCRMInOpsRoute,
  getPrintLabelRequestsRoute,
  getBranchManagerDashboardRoute,
  getCustomTripRequestsRoute,
  getBranchLeadRoleRoute,
  getCarrierReconciliationRoute,
  getCarrierReconcileRoute,
  getCarrierTransactionsHistoryRoute,
  getFoodDashboardConfigurations,
  getGenericConfigurationRoute,
  getFoodDashboardNotifications,
  getBulkRequestsPageRoute,
  getCallsOverviewRoute,
  getMyCallsRoute,
  getCmsSlipUploadRoute,
  getCmsDepositRoute,
  getPayInSlipBaseRoute,
  getLabelSetupRoute,
  getLabelPreviewRoute,
  getLabelCreateRoute,
  getFoodDashboardPayout,
  getConsignmentUploadRequestRoute,
  getActivityLogRoute,
  getActivityLogRouteOps,
  getCodSettlementRoute,
  getRoutingPlaygroundRoute,
  getTerritoryOptimizationRoute,
  getGeocodingPlaygroundRoute,
  getLiveDashboardRoute,
} from '../../../routing/utils';

const applicationTypeOps = applicationType === 'ops';
const applicationTypeCRM = applicationType === 'crm';

const permissionIdToPermissionMap = {
  // routes from Router.tsx
  rootRoute: (masterData) => masterData.show_v1_hybrid_view,
  consignments: (masterData) =>
    (!masterData.show_ltl_options && masterData.viewType !== 'tms') ||
    !masterData.show_v1_hybrid_view,
  retailConsolidatedPlanning: (masterData) =>
    masterData.show_consolidated_planning,
  retailLiveDashboard: (masterData) =>
    masterData.show_live_dashboard,
  pickupDetailsWithReferenceNumber: (masterData) =>
    masterData.show_consolidated_planning,
  analytics: (masterData) => masterData?.show_query_builder_crm || false,
  retailOverview: (masterData) =>
    masterData.ops_dashboard_config?.parts_to_show
      ?.express_analytics_overview || false,
  bankDeposit: (masterData) => masterData.show_bank_deposit_crm,
  bankTransactionsHistory: (masterData) => masterData.show_bank_deposit_crm,
  remittance: (masterData) => masterData.use_remittance_module_v1 ||
    masterData.use_remittance_module_v2,
  crates: (masterData) => masterData.use_crate || false,
  thirdPartyRoute: (masterData) => masterData.show_third_party_options,
  crmViewCrm: (masterData) =>
    (masterData.show_logistics_express_options ||
      masterData.show_retail_options ||
      masterData.show_ltl_options) &&
    masterData.ops_dashboard_config.parts_to_show.show_crm,
  counterBookingV2: (masterData) =>
    masterData.single_consignment_booking_config?.show_on_ops_v2,
  retailCustomTripRequests: (masterData) =>
    masterData.ops_dashboard_config?.parts_to_show
      ?.enable_manual_trip_creation ||
    masterData.ops_dashboard_config?.parts_to_show?.courier_partner_recon_config
      ?.enable_courier_partner_recon,
  carrierReconciliation: (masterData) =>
    masterData.ops_dashboard_config?.parts_to_show?.courier_partner_recon_config
      ?.enable_courier_partner_recon,
  carrierReconciliationTransactions: (masterData) =>
    masterData.ops_dashboard_config?.parts_to_show?.courier_partner_recon_config
      ?.enable_courier_partner_recon,
  crmCallsOverview: (masterData) =>
    masterData.ops_dashboard_config?.parts_to_show?.show_call_summary_page,
  crmMyCalls: (masterData) =>
    masterData.ops_dashboard_config?.parts_to_show?.show_my_calls_page,
  activityLog: (masterData) => masterData.show_password_policy,
  activityLogV1: (masterData) => masterData.show_password_policy,

  // Ondemand
  odDispatchScreen: (masterData) => masterData.viewType === 'ondemand',
  odOrderManagement: (masterData) => masterData.viewType === 'ondemand',
  odRiderManagement: (masterData) => masterData.viewType === 'ondemand',
  odRosterManagement: (masterData) =>
    masterData.show_roster_management_ondemand || false,
  odReporting: (masterData) => masterData.viewType === 'ondemand',
  odCODSettlement: (masterData) =>
    masterData.show_worker_credit_module && masterData.viewType === 'ondemand',
  odNotifications: (masterData) =>
    masterData.ops_dashboard_config.parts_to_show.show_ondemand_helpdesk &&
    masterData.viewType === 'ondemand',
  odAnalytics: (masterData) => masterData.viewType === 'ondemand',
  odStoreConfiguration: (masterData) =>
    masterData.ondemand_store_configuration?.length &&
    (masterData.show_store_config || masterData.allow_update_store_config) &&
    masterData.viewType === 'ondemand',
  odConfigurations: (masterData) =>
    masterData.show_allocation_setup && masterData.viewType === 'ondemand',
  odDispatchScreenBulkRequests: (masterData) =>
    masterData.viewType === 'ondemand',
  odPayout: (masterData) => masterData.rider_payout_read || false,

  // manage routes
  opsV1Manage: (masterData) =>
    masterData.ops_dashboard_config.parts_to_show.manage,
  pickupTask: (masterData) =>
    masterData.ops_dashboard_config.parts_to_show.manage_pickup_tasks,
  miscHubOpsCmsDeposit: (masterData) =>
    masterData.ops_dashboard_config.parts_to_show.show_dashboard_cms_deposit,
  miscHubOpsSlipUpload: (masterData) =>
    masterData.ops_dashboard_config.parts_to_show.show_dashboard_cms_deposit,
  miscHubOpsCod: (masterData) =>
    masterData.ops_dashboard_config.parts_to_show.show_dashboard_cod_settlement,
  miscHubOpsRecon: (masterData) =>
    masterData.ops_dashboard_config.parts_to_show.show_rider_recon,
  miscHubOpsReconForm: (masterData) =>
    masterData.ops_dashboard_config.parts_to_show.show_rider_recon,
  miscHubOpsTransactionHistory: (masterData) =>
    masterData.ops_dashboard_config.parts_to_show
      .show_rider_transaction_history,
  coordinatorPortal: (masterData) =>
    masterData.ops_dashboard_config.parts_to_show.show_coordinator_portal,
  admPortal: (masterData) =>
    masterData.ops_dashboard_config.parts_to_show.show_adm_coordinator_portal,
  shelf: (masterData) =>
    masterData.ops_dashboard_config.parts_to_show.show_shelf_data,
  manifest: (masterData) =>
    masterData.customs_manifest_config?.show_customs_tab,
  miscHubOpsBankDeposit: (masterData) =>
    masterData.ops_dashboard_config.parts_to_show.show_bank_deposit_opsv2 &&
    !masterData.ops_dashboard_config.parts_to_show.show_dashboard_cms_deposit,
  miscHubOpsBankDepositReconcile: (masterData) =>
    masterData.ops_dashboard_config.parts_to_show.show_bank_deposit_opsv2,
  miscHubOpsBankTransactionHistory: (masterData) =>
    masterData.ops_dashboard_config.parts_to_show.show_bank_deposit_opsv2,
  bikerManagement: (masterData) =>
    masterData.ops_dashboard_config.parts_to_show.show_attendance_module,
  counterBooking: (masterData) =>
    masterData.single_consignment_booking_config?.show_on_ops_dashboard,
  miscHubOpsBranchManagerDashboard: (masterData) =>
    masterData.ops_dashboard_config.parts_to_show
      .show_bm_dashboard_cash_settlement,
  miscHubOpsReconFormRevamp: (masterData) =>
    masterData.ops_dashboard_config.parts_to_show.show_rider_recon,
  queryBuilder: (masterData) => checkPermission(masterData, 'analytics'),
  miscHubOpsBranchLeadRole: (masterData) =>
    masterData.ops_dashboard_config.parts_to_show.show_branch_lead_screen,
  aprrovalRequests: (masterData) =>
    masterData.ops_dashboard_config?.parts_to_show?.request_approval_config
      ?.enable_request_approval,

  // setup routes
  opsV1Setup: (masterData) =>
    masterData.ops_dashboard_config.parts_to_show.setup,
  hubSetup: (masterData) =>
    masterData.ops_dashboard_config.parts_to_show.setup_hubs,
  setupEmployees: (masterData) =>
    masterData.ops_dashboard_config.parts_to_show.setup_employees,
  appUsers: (masterData) =>
    masterData.ops_dashboard_config.parts_to_show.setup_app_users,
  customerAllocationRules: (masterData) =>
    masterData.ops_dashboard_config.parts_to_show.setup_client_allocation_rules,
  customerManagement: (masterData) =>
    masterData.ops_dashboard_config.parts_to_show.setup_client_management,
  myDTDCConfig: (masterData) =>
    masterData.ops_dashboard_config.parts_to_show.show_mydtdc_banners,
  customerManage: (masterData) =>
    masterData.ops_dashboard_config.parts_to_show.setup_client_management,
  walletSettings: (masterData) => true,
  globalSeries: (masterData) =>
  masterData.ops_dashboard_config.parts_to_show.setup_global_virtual_series,
  inventoryRequests: (masterData) =>
  masterData.ops_dashboard_config.parts_to_show.setup_client_management,
  customerConfiguration: (masterData) =>
  masterData.ops_dashboard_config.parts_to_show.setup_customer_portal_config,
  customerProfile: (masterData) =>
  masterData.ops_dashboard_config.parts_to_show.enable_customer_config,
  appFlowConfig: (masterData) =>
    masterData.ops_dashboard_config?.parts_to_show?.setup_appflow_config,
  serviceTaskMapping: (masterData) =>
    masterData.ops_dashboard_config?.parts_to_show?.show_service_task_mapping,
  twoFactorAuth: (masterData) =>
    masterData.ops_dashboard_config?.parts_to_show?.show_two_factor_auth_screen,
  rescheduledReasons: (masterData) =>
    masterData.ops_dashboard_config?.parts_to_show?.setup_reschedule_reason,
  serviceType: (masterData) =>
    masterData.ops_dashboard_config?.parts_to_show?.setup_service_type,
  configObject: (masterData) =>
    masterData.ops_dashboard_config?.parts_to_show?.setup_appflow_config,
  receiversRelation: (masterData) =>
    masterData.ops_dashboard_config?.parts_to_show?.setup_receiver_relation,
  undeliveredReasons: (masterData) =>
    masterData.ops_dashboard_config?.parts_to_show?.setup_reason_master,
  kyc: (masterData) =>
    masterData.ops_dashboard_config?.parts_to_show?.setup_kyc,
  packagingTypeMaster: (masterData) =>
    masterData.ops_dashboard_config?.parts_to_show?.show_ltl_packaging_type_master,
  partialDeliveryReasons: (masterData) =>
    masterData.ops_dashboard_config?.parts_to_show
      ?.show_ltl_partial_delivery_reason,
  lateDeliveryReasons: (masterData) =>
    masterData.ops_dashboard_config?.parts_to_show
      ?.show_ltl_late_delivery_reason,
  bookingMasters: (masterData) =>
    masterData.ops_dashboard_config.parts_to_show.setup_booking_master ||
    masterData.ops_dashboard_config.parts_to_show.setup_booking_master_edit,
  admVehicles: (masterData) =>
    masterData.ops_dashboard_config.parts_to_show.setup_adm_vehicles,
  headerMapping: (masterData) =>
    masterData.ops_dashboard_config.parts_to_show.setup_header_mapping,
  thirdParty: (masterData) =>
    masterData.ops_dashboard_config.parts_to_show.setup_third_party_vendor,
  rateMasters: (masterData) =>
    masterData.ops_dashboard_config.parts_to_show.setup_rate_master,
  currencyConversion: (masterData) =>
    masterData.ops_dashboard_config.parts_to_show.setup_permission_obj?.currency
      ?.conversion?.read,
  carrierPartner: (masterData) =>
    masterData.ops_dashboard_config.parts_to_show.setup_carrier_partner ||
    masterData.setup_to_show_in_opsv2?.carrier_partner,
  carrierPartnerCarriers: (masterData) =>
    masterData.ops_dashboard_config.parts_to_show.setup_permission_obj
      ?.carrier_master?.setup?.read,
  carrierPartnerRules: (masterData) =>
    masterData.ops_dashboard_config.parts_to_show.show_courier_allocation_v2,
  carrierPartnerRulesOld: (masterData) =>
    !masterData.ops_dashboard_config.parts_to_show.show_courier_allocation_v2,
  courierFieldMaster: (masterData) =>
    masterData.ops_dashboard_config.parts_to_show.setup_permission_obj
      ?.carrier_master?.courier_field_master?.read,
  courierTracking: (masterData) =>
    masterData.ops_dashboard_config.parts_to_show
      .show_courier_tracking_integration_parts,
  carrierAllocationDownloads: (masterData) =>
    masterData.ops_dashboard_config.config
      .async_courier_account_allocation_rules_download,
  carrierPartnerConfig: (masterData) =>
    masterData.ops_dashboard_config.parts_to_show.show_courier_allocation_v2,
  carrierPartnerDataIngestion: (masterData) =>
    masterData.ops_dashboard_config.parts_to_show.show_courier_allocation_v2,
  transport: (masterData) =>
    masterData.ops_dashboard_config.parts_to_show.setup_transport_module ||
    masterData.ops_dashboard_config.parts_to_show.setup_vehicle_module,
  transportVendor: (masterData) =>
    masterData.ops_dashboard_config.parts_to_show.setup_permission_obj
      ?.transport?.vendor?.read,
  transportVehicleMake: (masterData) =>
    masterData.ops_dashboard_config.parts_to_show.setup_permission_obj
      ?.transport?.vehicle_make?.read,
  transportVehicleCategory: (masterData) =>
    masterData.ops_dashboard_config.parts_to_show.setup_permission_obj
      ?.transport?.vehicle_category?.read,
  transportOptimizerTags: (masterData) =>
    masterData.ops_dashboard_config.parts_to_show.setup_permission_obj
      ?.transport?.optimizer_tag?.read,
  transportVehicleDocuments: (masterData) =>
    masterData.ops_dashboard_config.parts_to_show
      .setup_vehicle_documents_module || true,
  transportVehicle: (masterData) =>
    masterData.ops_dashboard_config.parts_to_show.setup_permission_obj
      ?.transport?.vehicle?.read,
  transportRoute: (masterData) =>
    masterData.ops_dashboard_config.parts_to_show.setup_permission_obj
      ?.transport?.route?.read,
  transportRouteDetails: (masterData) =>
    masterData.ops_dashboard_config.parts_to_show.setup_transport_module,
  transportConnection: (masterData) =>
    masterData.ops_dashboard_config.parts_to_show.setup_permission_obj
      ?.transport?.connection?.read,
  transportException: (masterData) =>
    masterData.ops_dashboard_config.parts_to_show.setup_permission_obj
      ?.transport?.exception?.read,
  transportRouting: (masterData) =>
    masterData.ops_dashboard_config.parts_to_show.setup_permission_obj
      ?.transport?.routing?.read,
  transportBagging: (masterData) =>
    masterData.ops_dashboard_config.parts_to_show.setup_permission_obj
      ?.transport?.bagging?.read,
  transportDocks: (masterData) =>
    masterData.ops_dashboard_config.parts_to_show.setup_permission_obj
      ?.transport?.dock?.read,
  transportGates: (masterData) =>
    masterData.ops_dashboard_config.parts_to_show.setup_permission_obj
      ?.transport?.gate?.read,
  shelfManagement: (masterData) =>
    masterData.ops_dashboard_config.parts_to_show.show_shelf_data,
  setupPromocode: (masterData) =>
    masterData.ops_dashboard_config.parts_to_show.show_promo_campaign,
  addressHierarchy: (masterData) =>
    masterData.ops_dashboard_config.parts_to_show.setup_address_config,
  pincode: (masterData) =>
    masterData.ops_dashboard_config.parts_to_show.setup_pincode_master,
  expressBookingMasters: (masterData) =>
    masterData.ops_dashboard_config.parts_to_show.show_booking_masters_v2,
  tat: (masterData) =>
    masterData.ops_dashboard_config?.parts_to_show?.setup_permission_obj
      ?.booking_master_v2?.tat?.read,
  serviceability: (masterData) =>
    masterData.ops_dashboard_config?.parts_to_show?.setup_permission_obj
      ?.booking_master_v2?.serviceability?.read,
  destinationType: (masterData) =>
    masterData.ops_dashboard_config?.parts_to_show?.setup_permission_obj
      ?.booking_master_v2?.destination?.read,
  holidayv2: (masterData) =>
    masterData.ops_dashboard_config?.parts_to_show?.setup_permission_obj
      ?.booking_master_v2?.holidayv2?.read,
  polygonMaster: (masterData) =>
  masterData.ops_dashboard_config?.parts_to_show?.setup_permission_obj
    ?.booking_master_v2?.polygon_master?.read,
  passwordPolicy: (masterData) => masterData.show_password_policy,
  smsManagement: (masterData) =>
    masterData.ops_dashboard_config.parts_to_show.show_sms_integration_parts,
  validationBuilder: (masterData) =>
    masterData.ops_dashboard_config.parts_to_show.show_validation_builder,
  configurations: (masterData) => masterData.show_allocation_setup,
  labelSetup: (masterData) =>
    masterData.ops_dashboard_config.parts_to_show.show_code_free_label_setup,
  transportConsignmentRoute: (masterData) =>
    masterData.ops_dashboard_config.parts_to_show.setup_permission_obj
      ?.transport?.consignment_route?.read,
  routingPlayground: (masterData) =>
    masterData.ops_dashboard_config?.parts_to_show
      ?.show_routing_playground_parts,
  territoryOptimization: (masterData) =>
    masterData.ops_dashboard_config?.parts_to_show
      ?.show_territory_optimisation_parts,
  reasonCategoryMaster: (masterData) =>
    masterData.ops_dashboard_config?.parts_to_show?.setup_reason_master,
  ltlNDRMaster: (masterData) =>
    masterData.ops_dashboard_config?.parts_to_show?.use_ltl_ndr,
  itemUnitMaster: (masterData) => masterData?.ops_dashboard_config?.
                        parts_to_show?.setup_item_unit_master?.read,
  vehicleCompartmentMaster: (masterData) => masterData?.ops_dashboard_config?.
                        parts_to_show?.setup_vehicle_compartment_master?.read,
  seriesConsumptionRules: (masterData) =>
    masterData.ops_dashboard_config.parts_to_show &&
    masterData.ops_dashboard_config.parts_to_show
      .show_series_consumption_rules_module,
  multiLegCourierHubAllocation: (masterData) =>
    masterData.ops_dashboard_config.parts_to_show &&
    masterData.ops_dashboard_config.parts_to_show.show_multi_leg_hub_allocation,

  // sidemenu
  opsV1ConsignmentListRoute: (masterData) => masterData.show_v1_hybrid_view,
  consignmentsRoute: (masterData) => !masterData.show_v1_hybrid_view,
  track: (masterData) => masterData.show_v1_hybrid_view,
  consignmentUploadRequestRoute: (masterData) =>
    masterData.ops_dashboard_config?.parts_to_show
      ?.enable_async_consignment_softdata_upload,
  printLabelRequestsRoute: (masterData) =>
    !masterData.show_logistics_hybrid_express_view,

  mediumsSidemenuRider: (masterData) =>
    masterData.show_logistics_hybrid_express_view,
  mediumsSidemenuAttendance: (masterData) =>
    masterData.show_logistics_hybrid_express_view,
  mediumsSidemenuTracking: (masterData) =>
    masterData.show_logistics_hybrid_express_view,
  mediumsSidemenuKMReading: (masterData) =>
    masterData.show_logistics_hybrid_express_view,
  mediumsSidemenuVehicleAttendance: (masterData) =>
    masterData.ops_dashboard_config.parts_to_show.show_vehicle_attendance,

  analyticsSidemenuOverview: (masterData) =>
    masterData.ops_dashboard_config?.parts_to_show?.express_analytics_overview,
  analyticsSidemenuAnalyze: (masterData) =>
    masterData.ops_dashboard_config?.parts_to_show?.analyze_application,
  analyticsSidemenuQueryBuilder: (masterData) =>
    checkPermission(masterData, 'analytics'),

  hubOpsSidemenuCarrierReconciliation: (masterData) =>
    masterData.ops_dashboard_config?.parts_to_show?.courier_partner_recon_config
      ?.enable_courier_partner_recon,
  hubOpsSidemenuCarrierTransactions: (masterData) =>
    masterData.ops_dashboard_config?.parts_to_show?.courier_partner_recon_config
      ?.enable_courier_partner_recon,

  FLMSubmenuPickups: (masterData) =>
    masterData.show_logistics_hybrid_express_view,
  FLMSubmenuDelivery: (masterData) =>
    masterData.show_logistics_hybrid_express_view,
  FLMSubmenuTripManager: (masterData) =>
    masterData.show_trip_manager_hybrid_view ||
    !masterData.show_logistics_hybrid_express_view,
  FLMSubmenuConsolidatedPlanning: (masterData) =>
    masterData.show_consolidated_planning,
  FLMSubmenuLiveDashboard: (masterData) =>
    masterData.show_live_dashboard,
  FLMSubmenuTracking: (masterData) =>
    masterData.show_trip_manager_hybrid_view ||
    !masterData.show_logistics_hybrid_express_view,
  FLMSubmenuOptimizationRequests: (masterData) =>
    masterData.show_trip_manager_hybrid_view ||
    !masterData.show_logistics_hybrid_express_view,
  FLMSubmenuCustomTripCreationRequests: (masterData) =>
    masterData.ops_dashboard_config?.parts_to_show
      ?.enable_manual_trip_creation ||
    masterData.ops_dashboard_config?.parts_to_show?.courier_partner_recon_config
      ?.enable_courier_partner_recon,
  FLMSubmenuCrates: (masterData) => masterData.use_crate || false,

  CRMSubmenuCallsOverview: (masterData) =>
    checkPermission(masterData, 'crmCallsOverview'),
  CRMSubmenuMyCalls: (masterData) => checkPermission(masterData, 'crmMyCalls'),

  middleMileMainMenu: (masterData) =>
    masterData.ops_dashboard_config.parts_to_show.show_line_haul,
  hubOpsMainMenu: (masterData) =>
    masterData.show_logistics_hybrid_express_view ||
    masterData.custom_hybrid_express_view?.hub_ops?.length,
  payInSlipMainMenu: (masterData) =>
    masterData?.pis_config_master?.enable_module,
  thirdPartyMainMenu: (masterData) => masterData.show_third_party_options,
  analyticsMainMenu: (masterData) =>
    masterData.ops_dashboard_config?.parts_to_show
      ?.express_analytics_overview ||
    masterData.ops_dashboard_config?.parts_to_show?.analyze_application,
  counterBookingMenu: (masterData) =>
    masterData.single_consignment_booking_config?.show_on_ops_v2,
  crmMainMenu: (masterData) =>
    masterData.ops_dashboard_config.parts_to_show.show_crm,
  setupMainMenu: (masterData) =>
    masterData.ops_dashboard_config.parts_to_show.setup,
  showDevTools: (masterData) => SHOW_DEV_TOOLS,

  // SideMenu.tsx
  showSideMenuV2: (masterData) =>
    (masterData.show_logistics_express_options ||
      masterData.show_logistics_hybrid_express_view ||
      masterData.ops_dashboard_config?.parts_to_show
        ?.ops_sidemenu_list_enable) &&
    applicationTypeOps,
  consignmentSideMenu: (masterData) =>
    !masterData.show_ltl_options && masterData.viewType !== 'tms',
  tripManagerSideMenu: (masterData) =>
    applicationTypeOps &&
    (masterData.show_retail_options ||
      masterData.show_logistics_express_options) &&
    masterData.viewType !== 'tms',
  tmsOverviewSidemenu: (masterData) =>
    applicationTypeOps &&
    (masterData.viewType === 'tms' ||
      masterData.show_logistics_express_options),
  tmsVehicleSidemenu: (masterData) =>
    applicationTypeOps &&
    (masterData.viewType === 'tms' ||
      masterData.show_logistics_express_options),
  tmsISTSidemenu: (masterData) =>
    applicationTypeOps &&
    (masterData.viewType === 'tms' ||
      masterData.show_logistics_express_options),
  tmsBagSidemenu: (masterData) =>
    applicationTypeOps &&
    (masterData.viewType === 'tms' ||
      masterData.show_logistics_express_options),
  PISSummarySidemenu: (masterData) =>
    applicationTypeOps && masterData.show_logistics_express_options,
  managePISSidemenu: (masterData) =>
    applicationTypeOps && masterData.show_logistics_express_options,
  tmsDownloadRequestsSidemenu: (masterData) =>
    applicationTypeOps && masterData.viewType === 'tms',
  thirdPartyConsolidatedSidemenu: (masterData) =>
    applicationTypeOps && masterData.show_third_party_options,
  opsV1DeliveryTaskList: (masterData) =>
    applicationTypeOps &&
    (masterData.parts_for_ops_dashboard?.show_opsV1_delivery_task_list_in_v2 ||
      false),
  thirdPartyTrackingSidemenu: (masterData) =>
    applicationTypeOps &&
    (masterData.show_ltl_options || masterData.show_third_party_options),
  retailStoreViewSidemenu: (masterData) =>
    !HIDE_RETAIL_STORE_HUB_CHANGES &&
    applicationTypeOps &&
    masterData.show_retail_options,
  retailTrackingSidemenu: (masterData) =>
    applicationTypeOps &&
    (masterData.show_retail_options ||
      masterData.show_logistics_express_options),
  shelfSideMenu: (masterData) =>
    masterData.viewType === 'logistics' ||
    masterData.viewType === 'logistics_express',
  riderReconciliationSideMenu: (masterData) =>
    masterData.viewType === 'logistics',
  invoicesSideMenu: (masterData) => masterData.viewType === 'logistics',
  printLabelRequestsSideMenu: (masterData) =>
    applicationTypeOps &&
    masterData.show_retail_options &&
    masterData.viewType !== 'tms',
  downloadRequestsSideMenu: (masterData) =>
    applicationTypeCRM ||
    masterData.show_retail_options ||
    masterData.show_logistics_express_options ||
    masterData.show_ltl_options,
  summaryMetricsSideMenu: (masterData) =>
    applicationTypeCRM && masterData.show_summary_metrics,
  customerActivitySideMenu: (masterData) =>
    applicationTypeOps &&
    (masterData.show_ltl_options || masterData.show_retail_options),
  ltlAnalyticsSideMenu: (masterData) =>
    applicationTypeOps && masterData.show_ltl_options,
  retailOptimizationRequestsSideMenu: (masterData) =>
    applicationTypeOps &&
    (masterData.show_logistics_express_options ||
      masterData.show_retail_options) &&
    masterData.auto_allocation_asynchronous_request,
  retailOverviewAnalytics: (masterData) =>
    applicationTypeOps &&
    masterData.show_retail_options &&
    masterData.show_retail_overview_analytics,
  retailSupportPortal: (masterData) =>
    !HIDE_RETAIL_CUSTOMER_SUPPORT_PORTAL &&
    applicationTypeOps &&
    masterData.show_retail_options,
  erpSyncLogs: (masterData) =>
    applicationTypeOps &&
    (masterData.show_retail_options ||
      masterData.show_logistics_express_options),
  counterBookingSidemenu: (masterData) =>
    applicationTypeOps && checkPermission(masterData, 'counterBookingMenu'),
  transactionHistorySidemenu: (masterData) => applicationTypeCRM,
  bankDepositSidemenu: (masterData) =>
    applicationTypeCRM && checkPermission(masterData, 'bankDeposit'),
  bankTransactionHistorySidemenu: (masterData) =>
    applicationTypeCRM &&
    checkPermission(masterData, 'bankTransactionsHistory'),
  cashRemittanceSideMenu: (masterData) =>
    applicationTypeCRM && (
      masterData.use_remittance_module_v1 || masterData.use_remittance_module_v2
  ),
  opsCRM: (masterData) => checkPermission(masterData, 'crmViewCrm'),
  crmCallsOverviewSideMenu: (masterData) =>
    checkPermission(masterData, 'crmCallsOverview'),
  crmShowMyCallsSidemenu: (masterData) =>
    checkPermission(masterData, 'crmMyCalls'),
  expressAnalyticsSidemenu: (masterData) =>
    masterData.show_logistics_express_options,
  showSetupSidemenu: (masterData) => applicationTypeOps,
  showViewTypeSwitchSidemenu: (masterData) => masterData.show_view_type_switch,

  // SidemenuV2.tsx
  switchToV1: (masterData) => masterData.show_v1_hybrid_view,
  showActivityLogs: (masterData) => masterData.show_password_policy,
  showViewTypeSwitch: (masterData) =>
    checkPermission(masterData, 'showViewTypeSwitchSidemenu'),

  // MenuModal.tsx
  showRosterManagement: (masterData) =>
    masterData.show_roster_management_ondemand,
  showRiderPayout: (masterData) => masterData.rider_payout_read || false,
  showQueryBuilder: (masterData) => checkPermission(masterData, 'analytics'),
  showConfigurations: (masterData) =>
    checkPermission(masterData, 'configurations'),
  showHelpdeshNotifications: (masterData) =>
    masterData.ops_dashboard_config.parts_to_show.show_ondemand_helpdesk,
  showStoreConfigurations: (masterData) =>
    masterData.ondemand_store_configuration?.length &&
    (masterData.show_store_config || masterData.allow_update_store_config),
  showCODSettlement: (masterData) =>
    masterData.show_worker_credit_module || false,
  showBusinessIntelligence: (masterData) =>
    masterData.ops_dashboard_config.parts_to_show
      ?.show_cod_dump_overview_analytics &&
    masterData.ops_dashboard_config.parts_to_show
      ?.show_cod_dump_overview_analytics?.length,
  showOpsV1Hubs: (masterData) =>
    masterData.setup_to_show_in_opsv2?.hubs || false,
  showOpsV1Employees: (masterData) =>
    masterData.setup_to_show_in_opsv2?.employees || false,
  showOpsV1CarrierPartner: (masterData) =>
    masterData.setup_to_show_in_opsv2?.carrier_partner || false,
  allowTripUpdate: (masterData) =>
    masterData.ops_dashboard_config?.parts_to_show?.flm_trip_update || false,
  geocodingPlayground: (masterData) =>
    masterData.ops_dashboard_config?.parts_to_show?.show_geocoding_playground,
  riderManagement: (masterData) => true,
  addressRegister: (masterData) => masterData?.location_master_config?.show_in_setup,
  integrationMarketplace: (masterData) => true,
  apiKeyManagementConsole: masterData =>
      masterData.ops_dashboard_config?.parts_to_show?.setup_api_key_management,
  objectBuilder: (masterData) => masterData.ops_dashboard_config?.parts_to_show
    ?.setup_object_builder || false,
  viewBuilder: (masterData) => masterData.ops_dashboard_config?.parts_to_show?.setup_view_builder,
  pageBuilder: (masterData) => masterData.ops_dashboard_config?.parts_to_show?.setup_page_builder,
  workflowBuilder: (masterData) => masterData.ops_dashboard_config?.parts_to_show
    ?.setup_workflow_builder || false,
};

const navigationShortcutsList = [
  {
    route: '/consignments',
    prettyName: 'Consignments',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'consignments'),
    isAllowed: false,
  },
  {
    route: '/retail/ConsolidatedPlanning',
    prettyName: 'Consolidated Planning',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'retailConsolidatedPlanning'),
    isAllowed: false,
  },
  {
    route: getLiveDashboardRoute(),
    prettyName: 'Live Dashboard',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'retailLiveDashboard'),
    isAllowed: false,
  },
  {
    route: '/dashboard/setup',
    prettyName: 'Setup',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'opsV1Setup'),
    isAllowed: false,
  },
  // {
  //   route: '/downloads',
  //   prettyName: 'Downloads',
  //   checkRoutePermission: (masterData) => true,
  //   isAllowed: false,
  // },
  {
    route: '/retail/trip-manager',
    prettyName: 'Trip Manager',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'FLMSubmenuTripManager'),
    isAllowed: false,
  },
  {
    route: '/retail/tracking',
    prettyName: 'Tracking',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'FLMSubmenuTracking'),
    isAllowed: false,
  },
  // {
  //   route: '/retail/createroute',
  //   prettyName: 'Create Route',
  //   checkRoutePermission: (masterData) => true,
  //   isAllowed: false,
  // },
  // {
  //   route: '/domestic/track',
  //   prettyName: 'Domestic Tracking',
  //   checkRoutePermission: (masterData) => true,
  //   isAllowed: false,
  // },
  {
    route: '/analytics',
    prettyName: 'Analytics',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'analytics') &&
      masterData.viewType !== 'ondemand',
    isAllowed: false,
  },
  // {
  //   route: API_PLAYGROUND_BASE_PATH,
  //   prettyName: 'API Playground',
  //   checkRoutePermission: (masterData) => true,
  //   isAllowed: false,
  // },
  // {
  //   route: '/domestic/track/create',
  //   prettyName: 'Create Domestic Track',
  //   checkRoutePermission: (masterData) => true,
  //   isAllowed: false,
  // },
  // {
  //   route: '/domestic/track/download/fetch',
  //   prettyName: 'Domestic Track Downloads',
  //   checkRoutePermission: (masterData) => true,
  //   isAllowed: false,
  // },
  // {
  //   route: '/domestic/carrierManagement',
  //   prettyName: 'Domestic Carrier Management',
  //   checkRoutePermission: (masterData) => true,
  //   isAllowed: false,
  // },
  // {
  //   route: '/customerActivity/downloads',
  //   prettyName: 'Customer Activity Downloads',
  //   checkRoutePermission: (masterData) => true,
  //   isAllowed: false,
  // },
  // {
  //   route: '/customerActivity',
  //   prettyName: 'Customer Activity',
  //   checkRoutePermission: (masterData) => true,
  //   isAllowed: false,
  // },
  // {
  //   route: '/reconciliation/rider-list',
  //   prettyName: 'Reconciliation Rider List',
  //   checkRoutePermission: (masterData) => true,
  //   isAllowed: false,
  // },
  // {
  //   route: '/invoice',
  //   prettyName: 'Invoice',
  //   checkRoutePermission: (masterData) => true,
  //   isAllowed: false,
  // },
  {
    route: getFoodDashboardDispatchScreen(),
    prettyName: 'Dispatch Screen',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'odDispatchScreen'),
    isAllowed: false,
  },
  {
    route: getFoodLiveTrackingMap(),
    prettyName: 'Live Tracking',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'odDispatchScreen'),
    isAllowed: false,
  },
  {
    route: getFoodDashboardOrderManagement(),
    prettyName: 'Order Management Screen',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'odOrderManagement'),
    isAllowed: false,
  },
  {
    route: getFoodDashboardRiderManagement(),
    prettyName: 'Rider Management Screen',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'odRiderManagement'),
    isAllowed: false,
  },
  {
    route: getFoodDashboardRosterManagement(),
    prettyName: 'Roster Management Screen',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'odRosterManagement'),
    isAllowed: false,
  },
  {
    route: getFoodDashboardReporting(),
    prettyName: 'Reporting',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'odReporting'),
    isAllowed: false,
  },
  {
    route: getFoodDashboardCODCashSettlement(),
    prettyName: 'COD Settlement Screen',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'odCODSettlement'),
    isAllowed: false,
  },
  {
    route: getFoodDashboardNotifications(),
    prettyName: 'Notifications Screen',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'odNotifications'),
    isAllowed: false,
  },
  // {
  //   route: '/ltl/analytics',
  //   prettyName: 'LTL Analytics',
  //   checkRoutePermission: (masterData) => true,
  //   isAllowed: false,
  // },
  // {
  //   route: '/retail/analytics',
  //   prettyName: 'Analytics',
  //   checkRoutePermission: (masterData) => true,
  //   isAllowed: false,
  // },
  {
    route: getFoodDashboardAnalytics(),
    prettyName: 'Ondemand Analytics Screen',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'odAnalytics'),
    isAllowed: false,
  },
  {
    route: getFoodDashboardStoreConfiguration(),
    prettyName: 'Store Configuration Screen',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'odStoreConfiguration'),
    isAllowed: false,
  },
  // {
  //   route: '/genericConsignment',
  //   prettyName: 'Generic Consignment',
  //   checkRoutePermission: (masterData) => true,
  //   isAllowed: false,
  // },
  // {
  //   route: '/retail/requests',
  //   prettyName: 'Requests',
  //   checkRoutePermission: (masterData) => true,
  //   isAllowed: false,
  // },
  {
    route: '/summarymetrics',
    prettyName: 'Summary Metrics',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'summaryMetricsSideMenu'),
    isAllowed: false,
  },
  {
    route: '/retail/overview',
    prettyName: 'Overview Screen',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'retailOverview'),
    isAllowed: false,
  },
  // {
  //   route: '/retail/supportPortal',
  //   prettyName: 'Support Portal',
  //   checkRoutePermission: (masterData) => true,
  //   isAllowed: false,
  // },
  {
    route: getPISSummaryRoute(),
    prettyName: 'Pay In Slip Summary',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'payInSlipMainMenu'),
    isAllowed: false,
  },
  {
    route: getManagePISRoute(),
    prettyName: 'Pay In Slip Manage',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'payInSlipMainMenu'),
    isAllowed: false,
  },
  // {
  //   route: getOpsDashboardBaseRoute(),
  //   prettyName: 'Ops Dashboard',
  //   checkRoutePermission: (masterData) => true,
  //   isAllowed: false,
  // },
  // {
  //   route: '/retail/erp-sync-logs',
  //   prettyName: 'ERP Sync Logs',
  //   checkRoutePermission: (masterData) => true,
  //   isAllowed: false,
  // },
  // {
  //   route: '/transactionsHistory',
  //   prettyName: 'Transactions History',
  //   checkRoutePermission: (masterData) => true,
  //   isAllowed: false,
  // },
  {
    route: getBankDepositRoute(),
    prettyName: 'Bank Deposit Screen',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'bankDeposit'),
    isAllowed: false,
  },
  // {
  //   route: getCmsDepositRoute(),
  //   prettyName: 'CMS Deposit',
  //   checkRoutePermission: (masterData) => true,
  //   isAllowed: false,
  // },
  // {
  //   route: getCodSettlementRoute(),
  //   prettyName: 'COD Settlement',
  //   checkRoutePermission: (masterData) => true,
  //   isAllowed: false,
  // },
  // {
  //   route: getBranchLeadRoleRoute(),
  //   prettyName: 'Branch Lead Role',
  //   checkRoutePermission: (masterData) => true,
  //   isAllowed: false,
  // },
  {
    route: getBankTransactionsHistoryRoute(),
    prettyName: 'Bank Transactions History Screen',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'bankTransactionsHistory'),
    isAllowed: false,
  },
  // {
  //   route: getBranchManagerDashboardRoute(),
  //   prettyName: 'Branch Manager Dashboard',
  //   checkRoutePermission: (masterData) => true,
  //   isAllowed: false,
  // },
  {
    route: getCashRemittanceRoute(),
    prettyName: 'Cash Remittance',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'remittance'),
    isAllowed: false,
  },
  // {
  //   route: '/retail/store-consignments',
  //   prettyName: 'Store Consignments',
  //   checkRoutePermission: (masterData) => true,
  //   isAllowed: false,
  // },
  // {
  //   route: getShelfBaseRoute(),
  //   prettyName: 'Shelf Management',
  //   checkRoutePermission: (masterData) => true,
  //   isAllowed: false,
  // },
  {
    route: getCrateBaseRoute(),
    prettyName: 'Crates',
    checkRoutePermission: (masterData) => checkPermission(masterData, 'crates'),
    isAllowed: false,
  },
  {
    route: getThirdPartyViewRoute(),
    prettyName: 'Third Party',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'thirdPartyRoute'),
    isAllowed: false,
  },
  {
    route: getCRMInOpsRoute(),
    prettyName: 'CRM',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'crmViewCrm'),
    isAllowed: false,
  },
  // {
  //   route: getPrintLabelRequestsRoute(),
  //   prettyName: 'Print Label Requests',
  //   checkRoutePermission: (masterData) => true,
  //   isAllowed: false,
  // },
  {
    route: '/counter-booking',
    prettyName: 'Counter Booking',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'counterBookingV2'),
    isAllowed: false,
  },
  {
    route: getCustomTripRequestsRoute(),
    prettyName: 'Custom Trip Requests',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'retailCustomTripRequests'),
    isAllowed: false,
  },
  {
    route: getFoodDashboardConfigurations(),
    prettyName: 'Hyperlocal Configurations',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'odConfigurations'),
    isAllowed: false,
  },
  {
    route: getBulkRequestsPageRoute(),
    prettyName: 'Dispatch Screen Bulk Requests',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'odDispatchScreenBulkRequests'),
    isAllowed: false,
  },
  {
    route: getCarrierReconciliationRoute(),
    prettyName: 'Carrier Reconciliation Base',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'carrierReconciliation'),
    isAllowed: false,
  },
  {
    route: getCarrierTransactionsHistoryRoute(),
    prettyName: 'Carrier Transactions History',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'carrierReconciliationTransactions'),
    isAllowed: false,
  },
  {
    route: getCallsOverviewRoute(),
    prettyName: 'CRM Calls Overview',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'crmCallsOverview'),
    isAllowed: false,
  },
  {
    route: getMyCallsRoute(),
    prettyName: 'CRM My Calls',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'crmMyCalls'),
    isAllowed: false,
  },
  // {
  //   route: getLabelSetupRoute(),
  //   prettyName: 'Label Setup',
  //   checkRoutePermission: (masterData) => true,
  //   isAllowed: false,
  // },
  // {
  //   route: getLabelCreateRoute(),
  //   prettyName: 'Label Create',
  //   checkRoutePermission: (masterData) => true,
  //   isAllowed: false,
  // },
  {
    route: '/dev-tools',
    prettyName: 'Dev Tools',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'showDevTools'),
    isAllowed: false,
  },
  {
    route: getFoodDashboardPayout(),
    prettyName: 'Payout',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'odPayout'),
    isAllowed: false,
  },
  // {
  //   route: getConsignmentUploadRequestRoute(),
  //   prettyName: 'Consignment Upload Requests',
  //   checkRoutePermission: (masterData) => true,
  //   isAllowed: false,
  // },
  {
    route: getActivityLogRoute(),
    prettyName: 'Activity Log',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'activityLog'),
    isAllowed: false,
  },
  {
    route: '/dashboard/setup/hubs',
    prettyName: 'Hubs Setup',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'hubSetup'),
    isAllowed: false,
  },
  {
    route: '/dashboard/setup/employees',
    prettyName: 'Employees Setup',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'setupEmployees'),
    isAllowed: false,
  },
  {
    route: '/dashboard/setup/appUsers',
    prettyName: 'App Users Setup',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'appUsers'),
    isAllowed: false,
  },
  {
    route: '/dashboard/setup/customerRules',
    prettyName: 'Customer Allocation Rules Setup',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'customerAllocationRules'),
    isAllowed: false,
  },
  {
    route: '/dashboard/setup/customerManagement',
    prettyName: 'Customer Management Setup',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'customerManagement'),
    isAllowed: false,
  },
  {
    route: '/dashboard/setup/setupMyDTDCBanner',
    prettyName: 'MyDTDC Config Setup',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'myDTDCConfig'),
    isAllowed: false,
  },
  {
    route: '/dashboard/setup/appFlowConfig',
    prettyName: 'App Flow Config Setup',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'appFlowConfig'),
    isAllowed: false,
  },
  {
    route: '/dashboard/setup/admVehicles',
    prettyName: 'ADM Vehicles Setup',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'admVehicles'),
    isAllowed: false,
  },
  {
    route: '/dashboard/setup/headerMapping',
    prettyName: 'Header Mapping Setup',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'headerMapping'),
    isAllowed: false,
  },
  {
    route: '/dashboard/setup/thirdParty',
    prettyName: 'Third Party Setup',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'thirdParty'),
    isAllowed: false,
  },
  {
    route: '/dashboard/setup/rateMasters/contracts',
    prettyName: 'Rate Masters Setup',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'rateMasters'),
    isAllowed: false,
  },
  {
    route: '/dashboard/setup/carrierPartner',
    prettyName: 'Carrier Partner Setup',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'carrierPartner'),
    isAllowed: false,
  },
  {
    route: '/dashboard/setup/transport',
    prettyName: 'Transport Setup',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'transport'),
    isAllowed: false,
  },
  {
    route: '/dashboard/setup/shelf/management',
    prettyName: 'Shelf Management Setup',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'shelfManagement'),
    isAllowed: false,
  },
  {
    route: '/dashboard/setup/addressHierarchy',
    prettyName: 'Address Hierarchy Setup',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'addressHierarchy'),
    isAllowed: false,
  },
  {
    route: '/dashboard/setup/pincode/pincodeMasters',
    prettyName: 'Pincode Masters Setup',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'pincode'),
    isAllowed: false,
  },
  {
    route: '/dashboard/setup/password-policy',
    prettyName: 'Password Policy',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'passwordPolicy'),
    isAllowed: false,
  },
  {
    route: '/dashboard/setup/sms/management',
    prettyName: 'Communication Management',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'smsManagement'),
    isAllowed: false,
  },
  {
    route: '/dashboard/setup/validation/management',
    prettyName: 'Validation Management',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'validationBuilder'),
    isAllowed: false,
  },
  {
    route: '/dashboard/setup/label-setup',
    prettyName: 'Templates Setup',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'labelSetup'),
    isAllowed: true,
  },
  {
    route: getRoutingPlaygroundRoute(),
    prettyName: 'Routing Playground',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'routingPlayground'),
    isAllowed: false,
  },
  {
    route: getTerritoryOptimizationRoute(),
    prettyName: 'Territory Optimisation',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'territoryOptimization'),
    isAllowed: false,
  },
  {
    route: getGeocodingPlaygroundRoute(),
    prettyName: 'Geocoding Playground',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'geocodingPlayground'),
    isAllowed: false,
  },
  //NEW OBJECTS
  {
    route: '/dashboard/setup/appUsers/devices',
    prettyName: 'Users > App Users > Devices',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'appFlowConfig'),
    isAllowed: false,
  },
  {
    route: 'dashboard/setup/rider-management',
    prettyName: 'Users > Rider Management',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'riderManagement'),
    isAllowed: false,
  },
  {
    route: '/dashboard/setup/transport/vendor',
    prettyName: 'Asset > Transport > Vendor',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'transport'),
    isAllowed: false,
  },
  {
    route: '/dashboard/setup/transport/vehicleMake',
    prettyName: 'Asset > Transport > Vehicle Make',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'transport'),
    isAllowed: false,
  },
  {
    route: '/dashboard/setup/transport/vehicleDocuments',
    prettyName: 'Asset > Transport > Vehicle Documents',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'transport'),
    isAllowed: false,
  },
  {
    route: '/dashboard/setup/transport/vehicle',
    prettyName: 'Asset > Transport > Vehicle',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'transport'),
    isAllowed: false,
  },
  {
    route: '/dashboard/setup/transport/vehicleRestrictions',
    prettyName: 'Asset > Transport > Vehicle Restrictions',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'transport'),
    isAllowed: false,
  },
  {
    route: '/dashboard/setup/network/routes',
    prettyName: 'Middle Mile > Network > Route',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'transport'),
    isAllowed: false,
  },
  {
    route: '/dashboard/setup/network/connections',
    prettyName: 'Middle Mile > Network > Connections',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'transport'),
    isAllowed: false,
  },
  {
    route: '/dashboard/setup/network/exceptions',
    prettyName: 'Asset > Network > Exception',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'transport'),
    isAllowed: false,
  },
  {
    route: '/dashboard/setup/network/routing',
    prettyName: 'Middle Mile > Network > Routing',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'transport'),
    isAllowed: false,
  },
  {
    route: '/dashboard/setup/network/bagging',
    prettyName: 'Asset > Network > Bag Master',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'transport'),
    isAllowed: false,
  },
  {
    route: '/dashboard/setup/hubs/docks',
    prettyName: 'Assets > Hubs > Dock Master',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'transport'),
    isAllowed: false,
  },
  {
    route: '/dashboard/setup/hubs/gates',
    prettyName: 'Assets > Hubs > Gate Master',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'transport'),
    isAllowed: false,
  },
  {
    route: '/dashboard/setup/customerManagement/globalSeries',
    prettyName: 'Customer > Customer Management > Global Virtual Series',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'customerManagement'),
    isAllowed: false,
  },
  {
    route: '/dashboard/setup/customerManagement/customerConfiguration',
    prettyName: 'Customer > Customer Management > Customer Configuration',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'customerManagement'),
    isAllowed: false,
  },
  {
    route: '/dashboard/setup/expressBookingMasters/tat',
    prettyName: 'Express Booking Master > TAT Master',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'expressBookingMasters'),
    isAllowed: false,
  },
  {
    route: '/dashboard/setup/expressBookingMasters/serviceability',
    prettyName: 'Express Booking Master > Serviceability Master',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'expressBookingMasters'),
    isAllowed: false,
  },
  {
    route: '/dashboard/setup/expressBookingMasters/destinationType',
    prettyName: 'Express Booking Master > Destination Master',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'expressBookingMasters'),
    isAllowed: false,
  },
  {
    route: '/dashboard/setup/expressBookingMasters/holidayv2',
    prettyName: 'Express Booking Master > Holiday Master',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'expressBookingMasters'),
    isAllowed: false,
  },
  {
    route: '/dashboard/setup/expressBookingMasters/polygonMaster',
    prettyName: 'Middle Mile > Express Booking Master > Polygon Master',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'expressBookingMasters'),
    isAllowed: false,
  },
  {
    route: '/dashboard/setup/bookingMasters/masterData',
    prettyName: 'Booking Master',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'bookingMasters'),
    isAllowed: false,
  },
  {
    route: '/dashboard/setup/addressRegister',
    prettyName: 'Address > Address Register',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'addressRegister'),
    isAllowed: false,
  },
  {
    route: '/integrationMarketplace',
    prettyName: 'Integration > Integration Marketplace',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'integrationMarketplace'),
    isAllowed: false,
  },  //retail/erp-sync-logs
  {
    route: '/retail/erp-sync-logs',
    prettyName: 'Integration > Integration Logs',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'erpSyncLogs'),
    isAllowed: false,
  },
  {
    route: '/dashboard/setup/carrierPartner/advancedSettings',
    prettyName: 'Integration Setup > Carrier Partner > Advanced Settings',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'carrierPartner'),
    isAllowed: false,
  },
  {
    route: '/dashboard/setup/carrierPartner/courierTracking',
    prettyName: 'Integration Setup > Carrier Partner > Carrier Tracking',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'carrierPartner'),
    isAllowed: false,
  },
  {
    route: '/dashboard/setup/carrierPartner/courierFieldMaster',
    prettyName: 'Integration Setup > Carrier Partner > Carrier Field Master',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'carrierPartner'),
    isAllowed: false,
  },
  {
    route: '/dashboard/setup/carrierPartner/data',
    prettyName: 'Integration Setup > Carrier Partner > Carrier Capacity',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'carrierPartner'),
    isAllowed: false,
  },
  {
    route: '/dashboard/setup/carrierPartner/config',
    prettyName: 'Integration Setup > Carrier Partner > Allocation Config',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'carrierPartner'),
    isAllowed: false,
  },
  {
    route: '/dashboard/setup/carrierPartner/rules',
    prettyName: 'Integration Setup > Carrier Partner > Allocation Rules',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'carrierPartner'),
    isAllowed: false,
  },
  {
    route: '/dashboard/setup/carrierPartner/allocationEngine',
    prettyName: 'Integration Setup > Carrier Partner > Allocation Engine',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'carrierPartner'),
    isAllowed: false,
  },
  {
    route: '/dashboard/setup/carrierPartner/carrierAccountIntegration',
    prettyName: 'Integration Setup > Carrier Partner > Carrier Account Integrations',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'carrierPartner'),
    isAllowed: false,
  },
  {
    route: '/dashboard/setup/sms/management/email/template',
    prettyName: 'Communication Config > Email Setup',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'smsManagement'),
    isAllowed: false,
  },
  {
    route: '/dashboard/setup/validation/management/transformation',
    prettyName: 'Integration Setup > Validation Config > Transformation Builder',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'validationBuilder'),
    isAllowed: false,
  },
  {
    route: '/dashboard/setup/currencyConversion',
    prettyName: 'Admin > Currency Conversion',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'currencyConversion'),
    isAllowed: false,
  },
  {
    route: '/dashboard/setup/apiKeyManagementConsole',
    prettyName: 'Admin > API access key management',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'apiKeyManagementConsole'),
    isAllowed: false,
  },
  {
    route: '/dashboard/setup/appFlowConfig/configObject',
    prettyName:  'Admin > App Flow Config > Config Object',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'appFlowConfig'),
    isAllowed: false,
  },
  {
    route: '/dashboard/setup/appFlowConfig/hubOpsConfig',
    prettyName:  'Admin > App Flow Config > Hub Ops Config',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'appFlowConfig'),
    isAllowed: false,
  },
  {
    route: '/dashboard/setup/appFlowConfig/master',
    prettyName:  'Admin > App Flow Config > Master',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'appFlowConfig'),
    isAllowed: false,
  },
  {
    route: '/dashboard/setup/objectBuilder',
    prettyName:  'Admin > Object Builder',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'objectBuilder'),
    isAllowed: false,
  },
  {
    route: '/dashboard/setup/viewBuilder',
    prettyName:  'Admin > View Builder',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'viewBuilder'),
    isAllowed: false,
  },
  {
    route: '/dashboard/setup/pageBuilder',
    prettyName:  'Admin > Page Builder',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'pageBuilder'),
    isAllowed: false,
  },
  {
    route: '/dashboard/setup/workflowBuilder',
    prettyName:  'Admin > Workflow Builder',
    checkRoutePermission: (masterData) =>
      checkPermission(masterData, 'workFlowBuilder'),
    isAllowed: false,
  },
];

export const checkPermission = (masterData, permissionId) => {
  if (permissionIdToPermissionMap.hasOwnProperty(permissionId)) {
    if (!masterData) {
      return false;
    }

    return permissionIdToPermissionMap[permissionId](masterData);
  }

  return false;
};

export const checkPermissionsForNavigationShortcuts = (
  masterData,
  inputNavigationShortcutList = null,
) => {
  const navigationShortcutsListToCheck =
    inputNavigationShortcutList || navigationShortcutsList;
  for (const routeObject of navigationShortcutsListToCheck) {
    routeObject.isAllowed =
      routeObject.checkRoutePermission(masterData) || false;
  }

  return navigationShortcutsListToCheck;
};
